/**
 * 代理商信息变更
 *
 */

const agentName = {
	//获取代理商信息变更审批列表
	agentChangeGetList: {
		url: '/fortune/AgentChange/GetList',
		method: 'get'
	},
	//获取代理商信息变更详情
	agentChangeGetDetail: {
		url: '/fortune/AgentChange/GetDetail',
		method: 'get'
	},
	//代理商信息变更删除
	agentChangeDel: {
		url: '/fortune/AgentChange/Del',
		method: 'post'
	},
	//添加、修改代理商名称变更
	agentChangeHandleName: {
		url: '/fortune/AgentChange/HandleName',
		method: 'post'
	},
	//代理商信息变更审批
	agentChangeAudit: {
		url: '/fortune/AgentChange/Audit',
		method: 'post'
	},
	//添加、修改代理商合同变更
	agentChangeHandleContract: {
		url: '/fortune/AgentChange/HandleContract',
		method: 'post'
	},
}

export default agentName