/**
 * 模板管理
 * 
 */

const template = {
  //合同模板列表
  getFortuneContractTemptList: {
    url: '/fortune/Contract/TemptList',
    method: 'get'
  },
  //模板详情
  getFortuneContractTemptDetail: {
    url: '/fortune/Contract/TemptDetail',
    method: 'get'
  },
  //模板启用
  getFortuneContractEnable: {
    url: '/fortune/Contract/Enable',
    method: 'post'
  },
  //模板禁用
  getFortuneContractDisable: {
    url: '/fortune/Contract/Disable',
    method: 'post'
  },
  //模板编辑
  getFortuneContractEditTempt: {
    url: '/fortune/Contract/EditTempt',
    method: 'post'
  },
  //合同模板预览
  getFortuneContractTempPreview: {
    url: '/fortune/Contract/TempPreview',
    method: 'post'
  }
}

export default template