/**
 * 金蝶模块
 * 
 */

const kingdeeUrls = {
	//获取日志列表
	getKingdeeLogList: {
		url: '/fortune/Kingdee/GetLogList',
		method: 'get'
	},
	//获取日志列表
	getKingdeeQueueList: {
		url: '/fortune/Kingdee/GetQueueList',
		method: 'get'
	},
	//获取任务类型
	getKingdeeTaskTypeList: {
		url: '/fortune/Kingdee/GetTaskTypeList',
		method: 'get'
	},
	//任务重试
	kingdeeResetQueue: {
		url: '/fortune/Kingdee/ResetQueue',
		method: 'post'
	},
	//查映射列表
	kingdeeGetMapList: {
		url: '/fortune/Kingdee/GetMapList',
		method: 'get'
	},
	//查映射信息
	kingdeeGetMapInfo: {
		url: '/fortune/Kingdee/GetMapInfo',
		method: 'get'
	},
	//删除映射
	kingdeeDeleteMap: {
		url: '/fortune/Kingdee/DeleteMap',
		method: 'post'
	},
	//复制映射
	kingdeeCopyMap: {
		url: '/fortune/Kingdee/CopyMap',
		method: 'post'
	},
	//保存映射
	kingdeeSaveMap: {
		url: '/fortune/Kingdee/SaveMap',
		method: 'post'
	},
	//金蝶-获取厂商id收入明细列表
	kingdeeGetDetailList: {
		url: '/fortune/KingdeeVendorIdDetail/GetDetailList',
		method: 'get'
	},
	//厂商id收入明细表同步
	kingdeeSyncToKingdee: {
		url: '/fortune/kingdeeVendorIdDetail/SyncToKingdee',
		method: 'get'
	},
}

export default kingdeeUrls