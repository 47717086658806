import React, { useState, useEffect } from 'react'
import { 
  Table, 
  Input, 
  InputNumber, 
  DatePicker, 
  Popconfirm, 
  Form, 
  Button,
  message,
} from 'antd'
import moment from 'moment'
import { regExt } from '@/utils/constant'
import './style.scss'

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    const { inputType } = this.props
    let comp = null

    switch (inputType) {
      case 'number':
        comp = <InputNumber style={{ width: 130 }} />
        break
      case 'date':
        comp = <DatePicker placeholder="请选择时间" />
        break
      default:
        comp = <Input />
    }

    return comp
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      required,
      record,
      index,
      children,
      regExtType,
      ...restProps
    } = this.props
    const rules = [{ required, message: `${title}为必填项!` }]

    if (regExtType !== 'none') {
      rules.push({ pattern: regExt[regExtType], message: `${title}格式不正确!` })
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules,
              initialValue: inputType === 'date' && record[dataIndex] !== null ? moment(record[dataIndex]) : record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

const  EditableTable = ({ 
  form, 
  columns, 
  data, 
  setData,
  maxLength,
 }) => {
  const [editingIndex, setEditingIndex] = useState(-1)
  const [list, setList] = useState([])
  const operation = [
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      align: 'center',
      width: 100,
      editable: false,
      render(text, record, index) {
        return index === editingIndex ? (
          <>
            <EditableContext.Consumer>
              {form => (
                <a onClick={() => save(form, index)}>保存</a>
              )}
            </EditableContext.Consumer>
            <Popconfirm title="请确认是否取消？" cancelText="取消" okText="确认" onConfirm={() => cancel(record, index)}>
              <a style={{ marginLeft: 10 }}>取消</a>
            </Popconfirm>
          </>
        ) : (
          <>
            <a disabled={editingIndex !== -1} onClick={() => edit(index)}>编辑</a>
            {index !== -1 && (
              <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => remove(index)}>
                <a disabled={editingIndex !== -1} style={{ marginLeft: 10 }}>删除</a>
              </Popconfirm>
            )}
          </>
        )
      }
    },
  ]

  useEffect(() => {
    setList(data)
  }, [data])

  const cancel = (record, index) => {
    if (record.isNew) {
      let arr = list.filter((item, i) => i !== index )
      setList(arr)
      setData(arr)
    }
    setEditingIndex(-1)
  }

  const save = (form, index) => {
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const newData = [...list]
      
      for (let key in row) {
        if (row[key] && typeof row[key].format === 'function') {
          row[key] = row[key].format('YYYY-MM-DD')
        }
      }
      const item = newData[index]
      delete item.isNew
      newData.splice(index, 1, {
        ...item,
        ...row,
      })
      setList(newData)
      setData(newData)
      setEditingIndex(-1)
    })
  }

  const edit = index => setEditingIndex(index)

  const remove = index => {
    const newData = list.filter((item, i) => i !== index )
    setList(newData)
    setData(newData)
  }

  const add = () => {
    const n = list.length
    if (editingIndex > -1) return
    if (n >= maxLength) {
      message.error(`最多添加${maxLength}个!`)
      return
    }
    const row = {}
    columns.forEach(item => {
      if (item.inputType === 'date') {
        row[item.dataIndex] = null
      } else {
        row[item.dataIndex] = ''
      }
    })
    row.isNew = true
    list.push(row)
    setList(list)
    setEditingIndex(n)
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const editableColumns = columns.concat(operation).map(col => {
    if (col.editable === false) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        required: col.required === false ? false : true,
        title: col.title,
        editing: rowIndex === editingIndex,
        regExtType: col.regExtType || 'none'
      }),
    };
  });

  return (
    <>
      <EditableContext.Provider value={form}>
        <Table
          size="small"
          rowKey={record => JSON.stringify(record)}
          components={components}
          dataSource={list}
          columns={editableColumns}
          className="editable-table"
          pagination={false}
          scroll={{ x: '100%' }}
        />
      </EditableContext.Provider>
      <Button type="primary" icon="plus" block style={{ margin: '20px 0'}} onClick={add}>添加</Button>
    </>
  )
}

export default Form.create()(EditableTable)
