/**
 * 代理商转移
 *
 */

const agent = {
	//获取代理商列表
	agentGetList: {
		url: '/fortune/Agent/GetList',
		method: 'get'
	},
	//获取代理商列表
	agentGetContractList: {
		url: '/fortune/Agent/GetContractList',
		method: 'get'
	},
	//获取所在部门的员工
	agentGetDepartmentStaff: {
		url: '/fortune/Agent/GetDepartmentStaff',
		method: 'get'
	},
	//代理商分配负责人
	agentPrincipalAllotPrincipal: {
		url: '/fortune/AgentPrincipal/AllotPrincipal',
		method: 'post'
	},
	//添加、修改代理商认证
	agentVerifyHandle: {
		url: '/fortune/AgentVerify/Handle',
		method: 'post',
		headers: {
			route: '/pages/addNewAgent'
		}
	},
	//获取代理商认证列表
	agentVerifyGetList: {
		url: '/fortune/AgentVerify/GetList',
		method: 'get'
	},
	//获取合同详情
	agentGetContractDetail: {
		url: '/fortune/Agent/GetContractDetail',
		method: 'get'
	},
	//代理商认证删除
	agentVerifyDel: {
		url: '/fortune/AgentVerify/Del',
		method: 'post'
	},
	//代理商认证审批
	agentVerifyAudit: {
		url: '/fortune/AgentVerify/Audit',
		method: 'post'
	},
	//获取代理商认证详情
	agentVerifyGetDetail: {
		url: '/fortune/AgentVerify/GetDetail',
		method: 'get'
	},
	agentVerifyGetUserContractNo: {
		url: '/fortune/AgentVerify/GetUserContractNo',
		method: 'get'
	},
	agentGetDetail: {
		url: '/fortune/Agent/GetDetail',
		method: 'get'
	},
	//获取用户关联代理商的续签合同列表
	agentGetRenewContractList: {
		url: '/fortune/Agent/GetRenewContractList',
		method: 'get'
	},
	//添加、修改代理商合同变更
	agentChangeHandleContract: {
		url: '/fortune/AgentChange/HandleContract',
		method: 'post'
	},
	//获取代理商积分列表
	agentGetIntegralList: {
		url: '/fortune/Agent/GetIntegralList',
		method: 'get'
	},
	//代理商联系人列表
	agentContactGetList: {
		url: '/fortune/AgentContact/GetList',
		method: 'get'
	},
	//代理商联系人操作
	agentContactHandle: {
		url: '/fortune/AgentContact/Handle',
		method: 'post'
	},
	//获取代理商客户列表
	agentClientGetList: {
		url: '/fortune/AgentClient/GetList',
		method: 'get'
	},
	//新增代理商客户
	agentClientHandle: {
		url: '/fortune/AgentClient/Handle',
		method: 'post'
	},
	//获取代理商客户联系人列表
	agentClientGetContactList: {
		url: '/fortune/AgentClient/GetContactList',
		method: 'get'
	},
	//代理商客户联系人编辑
	agentClientHandleClientContact: {
		url: '/fortune/AgentClient/HandleClientContact',
		method: 'post'
	},
	//获取客户厂商信息列表
	agentClientGetFirmList: {
		url: '/fortune/AgentClient/GetFirmList',
		method: 'get'
	},
	//获取客户订单管理列表
	getAgentClientOrderList: {
		url: '/fortune/AgentClient/GetOrderList',
		method: 'get'
	},
	//检测厂商ID转移是否重复
	agentFirmTransferCheckRepeat: {
		url: '/fortune/AgentFirmTransfer/CheckRepeat',
		method: 'get'
	},
	//获取收支明细列表
	agentGetFundDetailList: {
		url: '/fortune/Agent/GetFundDetailList',
		method: 'get'
	},
	//获取资金明细筛选条件
	agentGetFundFilterData: {
		url: '/fortune/Agent/GetFundFilterData',
		method: 'get'
	},
	//获取代理商折扣列表
	agentGetAgentDiscountList: {
		url: '/fortune/Agent/GetAgentDiscountList',
		method: 'get'
	},
	//获取代理商获取负利收款列表
	getFortuneAgentRepayList: {
		url: '/fortune/Agent/GetRepayList',
		method: 'get'
	},
	//获取转入的客户/代理商信息
	getAgentFirmTransferGetCustom: {
		url: '/fortune/AgentFirmTransfer/GetCustom',
		method: 'get'
	},
	//代理商新增管理导出
	getFortuneagentverifyChangeTplItems: {
		url: '/fortune/agentVerify/GetExportFields',
		method: 'get'
	},
	getFortuneagentverifyChangeExportTpl: {
		url: '/fortune/agentVerify/GetExportTpls',
		method: 'get'
	},
	saveFortuneagentverifyChangeExportTpls: {
		url: '/fortune/agentVerify/SaveExportTpls',
		method: 'post'
	},
	exportFortuneagentverifyExport: {
		url: '/fortune/agentVerify/Export',
		method: 'post'
	},
	//代理商转出验证负责人是否为登录人
	getAgentVerifyAgentPrincipal: {
		url: '/fortune/AgentTransfer/verifyAgentPrincipal',
		method: 'get'
	}

}

export default agent