/*
 * @Author      : OBKoro1(James Wang)
 * @Date        : 2020-11-24 15: 06: 06
 * @Lastditors  : OBKoro1(your name)
 * @LastEditTime: 2020-12-02 10: 11: 47
 * @Description : file content
 */
/**
 * 信用帐户统计接口
 *
 */

const creditStatisitics = {
	//信用账户统计列表接口
	getCreditAccountStaList: {
		url: '/fortune/CreditAccount/CreditCountList',
		method: 'get'
	},
	//信用账户统计详情接口
	getCreditAccountStaDetaill: {
		url: '/fortune/creditAccount/CreCDetail',
		method: 'get'
	},
	//信用账户统计厂商id查询接口
	getCreditVenderList: {
		url: '/fortune/creditAccount/OrderList',
		method: 'get'
	},
}
export default creditStatisitics