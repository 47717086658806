/**
 * 所属公司过滤取
 *    支持单选多选 单选传radio
 *    多选默认选择全部 单选默认选中第一个
 *    接收onChange事件 以数组的格式返回所选公司的全部数据
 */

import React, { useState, useEffect, useRef } from 'react'
import { Tag, Icon } from 'antd'
import api from '@/api'

const { CheckableTag } = Tag

const CorpFilter = props => {
  const [list, setList] = useState([])
  const [checkedAll, setCheckedAll] = useState(true)
  const [showAll, setShowAll] = useState(false)
  const [showAllBtn, setShowAllBtn] = useState(false)

  const listRef = useRef(null)

  useEffect(() => {
    let notHeadOffice = props.notHeadOffice!=undefined ? props.notHeadOffice : 0
    api.getCorpFilterList({ limit: global.paramsLimit ,'notHeadOffice': notHeadOffice}).then(data => {
      setList(data.map((item, index) => ({ ...item, checked: props.radio && index === 0 ? true : false })))
      if (props.radio) {
        props.onChange(data[0])
      }
    })
  }, [])

  useEffect(() => {
    if (listRef.current.scrollHeight > 25) {
      setShowAllBtn(true)
    }
  })

  useEffect(() => {
    window.addEventListener('resize', onSetShowAllBtn, false)
    return () => {
      window.removeEventListener('resize', onSetShowAllBtn, false)
    }
  })

  const onSetShowAllBtn = () => {
    if (listRef.current.scrollHeight > 25) {
      setShowAllBtn(true)
    } else {
      setShowAllBtn(false)
    }
  }

  const onChange = (checked, id) => {
    // 单选
    if (props.radio) {
      const items = list.map(item => {
        if (item.id === id) {
          props.onChange(item)
          return { ...item, checked: true }
        }
        return { ...item, checked: false }
      })
      setList(items)
      return
    }

    // 多选
    const items = list.map(item => item.id === id ? { ...item, checked } : item)
    const checkedList = items.filter(item => item.checked)
    setList(items)
    if (checkedList.length === 0) {
      setCheckedAll(true)
    } else {
      setCheckedAll(false)
    }
    props.onChange(checkedList)
  }

  const onChangeAll = checked => {
    if (checked) {
      setList(list.map(item => ({ ...item, checked: false })))
      setCheckedAll(true)
      props.onChange(props.radio ? '' : [])
    }
  }

  return (
    <div className="corp-filter-wrap">
      <label htmlFor="">所属公司：</label>
      <div className={`corp-filter-list${showAll?' show-all':''}`} ref={listRef}>
        {props.radio ? null : <CheckableTag checked={checkedAll} onChange={checked => onChangeAll(checked)}>全部</CheckableTag>}
        {list.map(d => <CheckableTag key={d.id} checked={d.checked} onChange={checked => onChange(checked, d.id)}>{d.name}</CheckableTag>)}
      </div>
      {showAllBtn ? <div className="corp-filter-control-wrap">
        {showAll
          ? <span className="corp-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
          : <span className="corp-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
        }
      </div> : null}
    </div>
  )
}

export default CorpFilter