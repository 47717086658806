/**
 * 模块名称: 模块名称
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import { Icon, Layout, Avatar, Menu, Dropdown, Modal, Drawer, message, Transfer, Form, Button } from 'antd'
import avatar from './assets/avatar.png'
import Personal from './Personal'
import CenterPassword from './CenterPassword'
import api from '@/api'
// import { Link } from 'react-router-dom'
import BtnGroup from '../BtnGroup'
import { useDispatch } from 'react-redux'
import { reloadStart } from '@/global/reducer/reload'

// const FormItem = Form.Item

const Header = ({ userinfo, onToggle, collapsed, onLogout }) => {
  const [isShowPersonal, setShowPersonal] = useState(false)
  const [passVisible, setPassVisible] = useState(false)
  const [showHomeEdit, setShowHomeEdit] = useState(false)
  const [homeModules, setHomeModules] = useState([])
  const [targetKeys, setTargetKeys] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (/Android|webOS|iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      setIsMobile(true)
    }
  }, [])

  function showConfirm() {
    Modal.confirm({
      title: '请确认，是否退出本系统？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        onLogout()
      },
      onCancel() { },
    })
  }

  function showPersonal() {
    setShowPersonal(true)
  }

  // 修改个人中心
  function onConfirm(data) {
    data.staffId = userinfo.id
    api.updatePerDetail(data).then(() => {
      setShowPersonal(false)
      message.success('修改成功', 2)
      dispatch(reloadStart())
    })
  }

  function onEditHome() {
    setShowHomeEdit(true)
    api.getHomeModules().then(modules => {
      // modules = [{ "moduleId": 1, "modeName": "消息提醒", "current": 1 },
      // { "moduleId": 2, "modeName": "待办事项", "current": 1 },
      // { "moduleId": 3, "modeName": "系统公告", "current": 1 },
      // { "moduleId": 4, "modeName": "考勤记录", "current": 0 },
      // { "moduleId": 5, "modeName": "文档列表", "current": 0 }]
      setHomeModules(modules)
      setTargetKeys(modules.filter(item => item.current).map(item => item.moduleId))
    })
  }

  function onChangeTransfer(targetKeys) {
    const newModules = homeModules.map(item => {
      for (let i = 0; i < targetKeys.length; i++) {
        if (targetKeys[i] === item.moduleId) {
          return { ...item, current: 1 }
        }
      }
      return { ...item, current: 0 }
    })
    setHomeModules(newModules)
    setTargetKeys(newModules.filter(item => item.current).map(item => item.moduleId))
  }

  // 保存首页编辑
  function onConfirmEdit() {
    api.saveHomeModules(homeModules).then(() => {
      setShowHomeEdit(false)
      message.success('保存成功', 2)
      dispatch(reloadStart())
    })
  }

  // 修改密码
  const onResetPasswords = () => {
    setPassVisible(true)
  }

  const onPassSave = (data) => {
    // console.log("data=>", data)
    api.getLoginUpdatePwd(data).then(() => {
      setPassVisible(false)
      message.success('密码修改成功，再次登录时，请使用新登录密码', 3)
    }).catch(() => setPassVisible(true))
  }

  const onHuiyuan = () => {
    // window.open('https://vipm.372163.com/', "_blank")
    window.location.href = 'https://vipm.372163.com/'
  }

  /*const encode64 = (input) => {
    var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv" + "wxyz0123456789+/" + "="
    var output = ""
    var chr1, chr2, chr3 = ""
    var enc1, enc2, enc3, enc4 = ""
    var i = 0
    do {
      chr1 = input.charCodeAt(i++)
      chr2 = input.charCodeAt(i++)
      chr3 = input.charCodeAt(i++)
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
      enc4 = chr3 & 63
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64
      }
      output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4)
      chr1 = chr2 = chr3 = ""
      enc1 = enc2 = enc3 = enc4 = ""
    } while (i < input.length)
      return output
  }*/

  const onOpenoa = () => {
    // let deftoken = '372163'
    // let encodedString = encode64(deftoken)
    // let userId = JSON.stringify(userinfo.id)
    // let encodedUserId =  encode64(userId)
    // let userEmail = JSON.stringify(userinfo.workEmail)
    // let encodedEmail = encode64(userEmail)
    // // https://oa.372163.com
    // window.open('https://previewoa.372163.com/?m=switchs&f=login&deftoken='+encodedString+'&id='+encodedUserId+'&email='+encodedEmail+'', "_blank")
    
    api.getLoginOaUrl().then((res) => {
      // window.open(''+res+'?m=switchs&f=login&deftoken='+'newoa372163'+'&id='+userinfo.id+'&email='+userinfo.workEmail+'', "_blank")
      window.location.href = '' + res.url + '?m=switchs&f=login&deftoken=' + 'newoa372163' + '&id=' + userinfo.id + '&email=' + userinfo.workEmail + '&repeat=' + res.repeat
    }).catch(err => {
      console.log(err)
    })
  }

  const onOpenFeedback = () => {
    window.open(`/feedback?token=${localStorage.getItem('token')}`, "_blank")
  }

  const menu = (
    <Menu>
      {userinfo.isMainAccount !== 1 ? null : <Menu.Item onClick={showPersonal}>个人信息</Menu.Item>}
      <Menu.Item onClick={onResetPasswords}>修改密码</Menu.Item>
      {userinfo.isMainAccount !== 1 ? null : <Menu.Item onClick={onEditHome}>首页编辑</Menu.Item>}
      <Menu.Item onClick={showConfirm}>退出系统</Menu.Item>
    </Menu>
  )
  return (
    <Layout.Header>
      <Icon
        className="trigger"
        type={collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={onToggle}
      />
      <div className="system-btn">
        <Button onClick={onHuiyuan}>会员系统</Button>
        <Button onClick={onOpenoa}>OA系统</Button>
        <Button onClick={onOpenFeedback} style={{marginLeft: "10px"}}>问答区</Button>
        {isMobile ? <span className="open-with-pc">本系统不支持手机端提单，请登录PC端提单！</span> : null}
      </div>
      <div className="user-info">
        <Avatar src={userinfo.avatar || avatar} />
        <Dropdown overlay={menu}>
          <span className="user-name">
            {userinfo.staffName} <Icon type="down" />
          </span>
        </Dropdown>
      </div>
      <Drawer
        title="个人中心"
        width={600}
        onClose={() => setShowPersonal(false)}
        visible={isShowPersonal}
        destroyOnClose={true}
      >
        <Personal data={userinfo} onSubmit={onConfirm} onCancelEdit={() => setShowPersonal(false)} />
      </Drawer>
      <Drawer
        title="首页编辑"
        width={600}
        destroyOnClose={true}
        onClose={() => setShowHomeEdit(false)}
        visible={showHomeEdit}
      >
        <Transfer
          dataSource={homeModules}
          titles={['板块列表', '已选板块列表']}
          locale={{ itemUnit: '项', itemsUnit: '项' }}
          operations={['加入右侧', '加入左侧']}
          targetKeys={targetKeys}
          render={item => item.modeName}
          rowKey={record => record.moduleId}
          onChange={onChangeTransfer}
        />
        <BtnGroup style={{ marginTop: 20 }} onConfirm={onConfirmEdit} onCancel={() => setShowHomeEdit(false)} right />
      </Drawer>
      <Drawer
        title="个人中心"
        width={600}
        onClose={() => setShowPersonal(false)}
        visible={isShowPersonal}
        destroyOnClose={true}
      >
        <Personal data={userinfo} onSubmit={onConfirm} onCancelEdit={() => setShowPersonal(false)} />
      </Drawer>
      <Drawer
        title="修改密码"
        width={600}
        onClose={() => setPassVisible(false)}
        visible={passVisible}
        destroyOnClose={true}
      >
        <CenterPassword onCancelPassEdit={() => setPassVisible(false)} onPassSave={onPassSave} />
      </Drawer>

    </Layout.Header>
  )
}

export default Form.create()(Header)
