/**
 * 资产采购管理
 *
 */

const purchaseUrls = {
	//采购申请列表
	getAssetList: {
		url: '/assetPurchase/getList',
		method: 'get'
	},
	//获取公司资产类别、付款方式、业务状态
	getVariousSelectionList: {
		url: '/assetPurchase/getVariousSelectionList',
		method: 'get'
	},
	//采购申请删除
	setAssetDel: {
		url: '/assetPurchase/del',
		method: 'post'
	},
	//采购申请
	setAssetHandle: {
		url: '/assetPurchase/handle',
		method: 'post'
	},
	//采购明细添加
	setAssetDetailHandle: {
		url: '/assetDetail/handle',
		method: 'post'
	},
	//采购明细删除
	setAssetDetailDel: {
		url: '/assetDetail/del',
		method: 'post'
	},
	//采购申请详情
	getAssetRowDetail: {
		url: '/assetPurchase/getRow',
		method: 'get'
	},
	//采购申请审批
	setassetPurchaseAudit: {
		url: '/assetPurchase/audit',
		method: 'post'
	},
	//导入采购明细
	setAssetDetailImport: {
		url: '/assetDetail/import',
		method: 'post'
	},
	//采购订单详情-修改付款凭证
	setAssetPurchaseModifyPaymentVoucher: {
		url: '/assetPurchase/modifyPaymentVoucher',
		method: 'post'
	}
}

export default purchaseUrls