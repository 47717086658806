/**
 * 公司资产
 * 
 */

 const corpAssetsUrls = {
    //手机号列表
    getAssetPhoneNumberList: {
      url: '/assetPhoneNumber/getList',
      method: 'get'
    },
    //手机号获取状态
    getAssetPhoneNumberStatus: {
      url: '/assetPhoneNumber/getStatus',
      method: 'get'
    },
    //获取手机号详情
    getAssetPhoneNumberDetail: {
      url: '/assetPhoneNumber/detail',
      method: 'get'
    },
    //删除手机号
    setAssetPhoneNumberDelete: {
      url: '/assetPhoneNumber/delete',
      method: 'post'
    },
    //手机号导入
    setAssetPhoneNumberImport: {
      url: '/assetPhoneNumber/import',
      method: 'post'
    },
    //手机号品牌
    getAssetPhoneNumberBrandTypes: {
      url: '/assetPhoneNumber/getBrandTypes',
      method: 'get'
    },
    //添加手机号
    setAssetPhoneNumberAdd: {
      url: '/assetPhoneNumber/add',
      method: 'post'
    },
    //编辑手机号
    setAssetPhoneNumberEdit: {
      url: '/assetPhoneNumber/edit',
      method: 'post'
    },
    //手机号调拨
    setAssetPhoneNumberAllocate: {
      url: '/assetPhoneNumber/allocate',
      method: 'post'
    },
    //获取硬件设备列表
    getAssetHardwareList: {
      url: '/assetHardware/getList',
      method: 'get'
    },
    //删除硬件设备
    setAssetHardwareDelete: {
      url: '/assetHardware/delete',
      method: 'post'
    },
    //硬件设备导入
    setAssetHardwareImport: {
      url: '/assetHardware/import',
      method: 'post'
    },
    //获取硬件设备详情页
    getAssetHardwareDetail: {
      url: '/assetHardware/detail',
      method: 'get'
    },
    //获取硬件设备状态
    getAssetHardwareStatus: {
      url: '/assetHardware/getStatus',
      method: 'get'
    },
    //添加硬件设备
    setAssetHardwareAdd: {
      url: '/assetHardware/add',
      method: 'post'
    },
    //编辑硬件设备
    setAssetHardwareEdit: {
      url: '/assetHardware/edit',
      method: 'post'
    },
    //硬件设备调拨
    setAssetHardwareAllocate: {
      url: '/assetHardware/allocate',
      method: 'post'
    },
    //调拨列表
    getAssetAllocateList: {
      url: '/assetAllocate/getList',
      method: 'get'
    },
    //根据员工编号获取员工信息接口
    getStaffInfobyStaffNo: {
      url: '/staff/getStaffInfobyStaffNo',
      method: 'get'
    },
    // 系统账号列表
    getAssetSystemAccountList: {
      url: '/assetSystemAccount/getList',
      method: 'get'
    },
    // 添加系统账号
    setAssetSystemAccountAdd: {
      url: '/assetSystemAccount/add',
      method: 'post'
    },
    // 编辑系统账号
    setAssetSystemAccountEdit: {
      url: '/assetSystemAccount/edit',
      method: 'post'
    },
    // 系统账号删除
    getAssetSystemAccountDel: {
      url: '/assetSystemAccount/del',
      method: 'post'
    },
    // 系统账号导入
    getAssetSystemAccountImport: {
      url: '/assetSystemAccount/import',
      method: 'post'
    },
    // 系统账号编辑反显
    getAssetSystemAccountDetail: {
      url: '/assetSystemAccount/detail',
      method: 'get'
    },
    // 软件账号删除
    getAssetSoftAccountDelete: {
      url: '/assetSoftAccount/delete',
      method: 'post'
    },
    // 软件账号保存
    getAssetSoftAccountSave: {
      url: '/assetSoftAccount/save',
      method: 'post'
    },
    // 软件账号调拨
    getAssetSoftAccountchangeUser: {
      url: '/assetSoftAccount/changeUser',
      method: 'post'
    },
    // 软件账号详情
    getAssetSoftAccountDetail: {
      url: '/assetSoftAccount/getDetail',
      method: 'get'
    },
    // 软件账号调拨记录
    getAssetSoftAccountRecordList: {
      url: '/assetSoftAccount/getRecordList',
      method: 'get'
    },
    // 软件账号导入
    getAssetSoftAccountImport: {
      url: '/assetSoftAccount/multiSave',
      method: 'post'
    },
    // 软件账号列表
    getAssetSoftAccountList: {
      url: '/assetSoftAccount/getList',
      method: 'get'
    },
  }
  
  export default corpAssetsUrls