/**
 * 委托书管理
 * 
 */

const authorization = {
  getAuthorizationList: {
    url: '/fortune/authorization/list',
    method: 'get'
  },
  getAuthorizationDetail: {
    url: '/fortune/authorization/detail',
    method: 'get'
  },
  saveAuthorization: {
    url: '/fortune/authorization/save',
    method: 'post'
  },
  getAuthorizationExportTpl: {
    url: '/fortune/authorization/GetExportTpls',
    method: 'get'
  },
  exportAuthorization: {
    url: '/fortune/authorization/Export',
    method: 'post'
  },
  getAuthorizationTplItems: {
    url: '/fortune/authorization/GetExportFields',
    method: 'get'
  },
  saveAuthorizationTpl: {
    url: '/fortune/authorization/SaveExportTpls',
    method: 'post'
  },
  getAuthorizationDelete: {
    url: '/fortune/authorization/Delete',
    method: 'post'
  }
}

export default authorization