/**
 * 工资管理
 * 
 */

const wage = {
  getWageLevelList: {
    url: '/WageManagement/getLevelList',
    method: 'get'
  },
  getWageLevelExportTpl: {
    url: '/WageManagement/GetExportTpls',
    method: 'get'
  },
  exportWageLevel: {
    url: '/WageManagement/Export',
    method: 'post'
  },
  getWageLevelTplItems: {
    url: '/WageManagement/GetExportFields',
    method: 'get'
  },
  saveWageLevelTpl: {
    url: '/WageManagement/SaveExportTpls',
    method: 'post'
  },
  setwageLevelImport: {
    url: '/wageManagement/levelImport',
    method: 'post'
  },
  getSalaryList: {
    url: '/WageManagement/getWageList',
    method: 'get'
  },
  setWageSalaryImport: {
    url: '/wageManagement/WageImport',
    method: 'post'
  }
}

export default wage