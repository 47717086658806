/**
 * 公司账户管理
 * 
 */

 const corpAccountUrls = {
    //内部往来款列表接口
    getInnerFundTransferList: {
      url: '/InnerFundTransfer/list',
      method: 'get'
    },
    //内部往来款删除
    getInnerFundTransferDelete: {
        url: '/InnerFundTransfer/delete',
        method: 'get'
    },
    //转款下拉账户列表
    getInnerFundTransferAccountList: {
        url: '/InnerFundTransfer/accountList',
        method: 'get'
    },
    //申请、编辑接口，此接口用于申请和驳回重提编辑使用，财务编辑有单独接口，业务方便区分
    setInnerFundTransferApply: {
        url: '/InnerFundTransfer/apply',
        method: 'post'
    },
    //内部往来款详情
    getInnerFundTransferDetail: {
        url: '/InnerFundTransfer/detail',
        method: 'get'
    },
    //财务充值审批接口
    setInnerFundTransferAudit: {
        url: '/InnerFundTransfer/audit',
        method: 'post'
    },
    //财务编辑附件接口
    setInnerFundTransferEditProof: {
        url: '/InnerFundTransfer/editProof',
        method: 'post'
    },
    // 公司账户列表
    getCorporateAccountlist: {
        url: '/corporateAccount/getList',
        method: 'get'
    },
    // 公司账户添加/编辑
    getCorporateAccountHandle: {
        url: '/corporateAccount/handle',
        method: 'post'
    },
    // 公司账户详情
    getCorporateAccountDetail: {
        url: '/corporateAccount/getDetail',
        method: 'get'
    },
    // 公司账户删除
    getCorporateAccountDel: {
        url: '/corporateAccount/del',
        method: 'post'
    }
  }
  
  export default corpAccountUrls