/**
 * 岗位级别
 * 
 */

const JobLevelUrls = {
  getJobLevel: {
    url: '/jobStationLevel/getJobStationLevelList',
    method: 'get'
  },
  saveJobStationLevelInfo: {
    url: '/jobStationLevel/saveJobStationLevelInfo',
    method: 'post'
  },
  saveJobStationLevelUpdateIsDel: {
    url: '/jobStationLevel/updateIsDel',
    method: 'post'
  },
  saveJobStationLevelUpdateSort: {
    url: '/jobStationLevel/updateSort',
    method: 'post'
  }
}

export default JobLevelUrls