/**
 * 模块名称: 导航
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'


const MenuComp = ({
  data,
  openKeys,
  selectedKeys,
  onOpenMenu,
  onSelectMenu
}) => {

  function renderMenu(data) {
    return data.map(item => item.children.length ?
      <Menu.SubMenu
        key={item.url !== '' ? item.url : item.id}
        title={
          <>
            <Icon type={item.icon || 'heart'} />
            <span title={item.title}>{item.title}</span>
          </>
        }>
        {renderMenu(item.children)}
      </Menu.SubMenu>
      :
      <Menu.Item key={item.url !== '' ? item.url : item.id}>
        {item.icon ? <Icon type={item.icon} /> : null}
        <Link className="nav-link" to={item.url} title={item.title}>{item.title}</Link>
      </Menu.Item>
    )
  }

  function getSelectedKeys(selectedKeys) {
    const keys = selectedKeys.split('/').filter(item => item)
    const keyList = keys.map((item, i) => `/${keys.slice(0, i + 1).join('/')}`)
    if (keyList.length === 0) {
      keyList.push(selectedKeys)
    }
    return keyList
  }

  return (
    <Menu
      theme="dark"
      mode="inline"
      openKeys={openKeys}
      selectedKeys={getSelectedKeys(selectedKeys[0] || '')}
      onOpenChange={onOpenMenu}
      onSelect={onSelectMenu}
    >
      {renderMenu(data)}
    </Menu>
  )
}

export default MenuComp