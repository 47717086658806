/**
 * 模块名称: 个人中心
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import Upload from '@/components/Upload'
import { Icon, Drawer, Input, Form, message, Modal, Button } from 'antd'
import BtnGroup from '../BtnGroup'
import api from '@/api'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js'

const { confirm } = Modal

let imageKey = ''

const Personal = ({ data, onSubmit, onCancelEdit, form, history }) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form
  const [visible, setVisible] = useState(false)
  const [avatar, setAvatar] = useState('')
  const [imgCode, setImgCode] = useState('')
  const [phone, setPhone] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const [countDown, setCountDown] = useState(60)
  const [parameters, setParameters] = useState({})
  const [imgVisible, setimgVisible] = useState(false)
  const [weixinFlag,  setWeixinFlag] = useState(false)
  const [disabledCaptcha, setDisabledCaptcha] = useState(true)
  const [mobileVisble, setMobileVisble] = useState(false)

  useEffect(() => {
    setPhone(data.workMobile)
    setAvatar(data.avatar)
    setWeixinFlag(data.bindList.weixin)
    // getWxBindConfig()
  }, [])

  useEffect(() => {
    if (countDown > 1 && showTimer) {
      setTimeout(() => setCountDown(countDown - 1), 1000)
    }
    if (countDown === 1 && showTimer) {
      setTimeout(() => {
        setCountDown(60)
        setShowTimer(false)
      }, 1000)
    }
  }, [countDown])

  const onConfirm = () => {
    onSubmit({
      enterpriseQQ: qqNum.current.state.value,
      stutel: telNum.current.state.value,
      photo: avatar
    })
  }

  // 保存手机号
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        vals.imageKey = imageKey
        api.updatePhone(vals).then(res => {
          setPhone(vals.phone)
          setVisible(false)
          message.success('手机号码保存成功', 2)
        }).catch(err => {
          getImgCode()
        })
      }
    })
  }

  // 编辑手机号
  const onEditMobile = () => {
    setVisible(true)
    getImgCode()
  }

  const onGetPhoneCode = () => {
    if (disabledCaptcha) return
    validateFields(['phone'], (err, vals) => {
      if (!err) {
        api.getPhoneCode({ phone: vals.phone, type: 1 }).then(res => {
          setShowTimer(true)
          setTimeout(() => setCountDown(countDown - 1), 1000)
        })
      }
    })
  }

  const getImgCode = () => {
    api.getCaptcha().then(res => {
      setImgCode(res.imageCode)
      imageKey = res.imageKey
    })
  }

  const qqNum = useRef(null)
  const telNum = useRef(null)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  // 获取微信绑定参数
  // const getWxBindConfig = () => {
  //   api.getWxBindConfig({
  //     staffId: data.id
  //   })
  //   .then(data => {
  //     console.log("data===>",data)
  //     setParameters(data)
  //   }).catch(() => {
  //     // message.error('失败', 3)
  //   })
  // }


  /*const getUserInfo = () => {
    api.getUser({}).then(data => { })
  }*/

  // 解除绑定
  const saveWxCompanyUnbind = () => {
    api.saveWxCompanyUnbind({ staffId: data.id }).then(() => setWeixinFlag(false))
  }

  const onWeChatBinding = () => {
    if (weixinFlag === false) {
      setimgVisible(true)
      api.getWxCompanyBind({
        staffId: data.id
      })
      .then(data => {
        setParameters(data)
      }).catch(() => {
        // message.error('失败', 3)
      })
    } else {
      confirm({
        title: '请确认是否解绑微信？',
        onOk() {
          saveWxCompanyUnbind()
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
    // window.WwLogin({
    //   "id": "wx_reg",
    //   "appid": parameters.appid,
    //   "agentid": parameters.agent_id,
    //   "redirect_uri": parameters.redirect_uri,
    //   "state": parameters.state,
    // })
  }

  const HandleCancel = () => {
    setimgVisible(false)
    api.getUser().then(data => {
      setWeixinFlag(data.bindList.weixin)
      if (data.bindList.weixin) {
        message.success('绑定成功')
      }
    })
  }

  // 查看更多
  const onStaffDetail = () => {
    onCancelEdit()
  }

  const onCaptchaBlur = e => {
    if (!e.target.value) return
    api.checkImgCode({ code: e.target.value, imageKey }).then(() => {
      if (mobileVisble) {
        setDisabledCaptcha(false)
      } else {
        setDisabledCaptcha(true)
        message.error('手机号码有误，无法获取短信验证码')
      }
    }).catch(() => setDisabledCaptcha(true))
  }

  const checkMobile = () => {
    setDisabledCaptcha(true)
    setFieldsValue({
      imageCode: undefined
    })
    validateFields(['phone'], (err, vals) => {
      if (!err) {
        api.checkPhone({ mobile: vals.phone }).then(res => {
          // if(res.code > 0){
          //   message.success(res.msg)
          // }
          setMobileVisble(true)
        }).catch((err) => {
          setMobileVisble(false)
        })
      }
    })
  }

  return (
    <div className="personal-wrap">
      <Link to={`/hr/employee/details?id=${data.id}&time=${+new Date()}&sign=${CryptoJS.MD5(data.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>
        <Button type="primary" className="personal-more-btn" onClick={onStaffDetail}>查看更多</Button>
      </Link>
      <div className="u-item">
        <div>
          <label>员工姓名：</label>
          <span>{data.staffName}</span>
        </div>
        <div>
          <label>职位：</label>
          <span>{data.jobPositionCn}</span>
        </div>
      </div>

      <div className="u-item">
        <div>
          <label>员工编号：</label>
          <span>{data.staffNo}</span>
        </div>
        <div>
          <label>入职时间：</label>
          <span>{data.entryDate.split(' ')[0]}</span>
        </div>
      </div>

      <div className="u-item">
        <div>
          <label>直属上级：</label>
          <span>{data.leaderName}</span>
        </div>
        <div>
          <label>所属公司：</label>
          <span>{data.companyName}</span>
        </div>
      </div>

      <div className="u-item">
        <div>
          <label>所属部门：</label>
          <span>{data.departmentName}</span>
        </div>
        <div>
          <label>企业QQ：</label>
          <span><Input ref={qqNum} placeholder="" style={{width: 150}} defaultValue={data.enterpriseQQ} /></span>
        </div>
      </div>

      <div className="u-item">
        <div>
          <label>手机号码：</label>
          <span>{phone} <Icon type="edit" onClick={onEditMobile} /></span>
        </div>
        <div>
          <label>座机：</label>
          <span><Input ref={telNum} placeholder="" style={{width: 150}} defaultValue={data.stuTel} /></span>
        </div>
      </div>

      <div className="u-item">
        <div className="binding-con">
          <label>企业微信绑定：</label>
          <span className="binding-text">
            <span>{weixinFlag ? '已绑定':'未绑定'}</span>
            <span onClick={onWeChatBinding} style={{cursor: 'pointer', color: '#1890ff'}}>{weixinFlag ? '解绑': '绑定'}</span>
          </span>
          <Modal
            visible={imgVisible}
            onCancel={HandleCancel}
            footer={[]}
            width={300}
            className="code-con"
            >
            <img src={parameters} width={200} alt="" />
            <div className="code-con-text">扫码绑定企业微信</div>
            <div>打开企业微信扫一扫进行绑定</div>
          </Modal>

        </div>
      </div>

      <div className="u-item">
        <div className="avatar-wrap">
          <label>头像：</label>
          <Upload onUpload={url => setAvatar(url)} value={avatar} />
        </div>
      </div>

      {/* 修改手机号抽屉 */}
      <Drawer
        title="修改手机号"
        width={380}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <Form.Item label="手机号码">
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: '请输入手机号码' }]
            })(<Input placeholder="请输入手机号码" onBlur={checkMobile} />)}
          </Form.Item>
          <Form.Item label="验证码">
            {getFieldDecorator('imageCode', {
              rules: [{ required: true, message: '请输入验证码' }]
            })(<Input style={{width: 100}} onBlur={onCaptchaBlur} placeholder="验证码" />)}
            <img width="100" className="code-img" alt="图形验证码" src={imgCode} onClick={getImgCode} />
          </Form.Item>
          <Form.Item label="短信验证码">
            {getFieldDecorator('phoneCode', {
              rules: [{ required: true, message: '请输入短信验证码' }]
            })(<Input style={{width: 80}} placeholder="短信验证码" />)}
            {showTimer
            ? <span className="get-code-wrap disabled">获取验证码({countDown})</span>
            : <span className={`get-code-wrap ${disabledCaptcha ? 'disabled' : ''}`} onClick={onGetPhoneCode}>获取验证码</span>}
          </Form.Item>
          <BtnGroup onCancel={() => setVisible(false)} />
        </Form>
      </Drawer>
      <BtnGroup right onConfirm={onConfirm} onCancel={() => onCancelEdit()} />
    </div>
  )
}

export default Form.create()(Personal)
