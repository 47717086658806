/**
 * 招聘管理
 * 
 */

const recruit = {
  getStaffRecruitmentList: {
    url: '/staffRecruitment/getStaffRecruitmentList',
    method: 'get'
  },
  getStaffRecruitmentInfo: {
    url: '/staffRecruitment/getStaffRecruitmentInfo',
    method: 'get'
  },
  delRecruitmentupdateIsDel: {
    url: '/staffRecruitment/updateIsDel',
    method: 'post'
  },
  saveStaffRecruitmentInfo: {
    url: '/staffRecruitment/saveStaffRecruitmentInfo',
    method: 'post'
  },
  staffRecruitmentPass: {
    url: '/staffRecruitment/pass',
    method: 'post'
  },
  staffRecruitmentReject: {
    url: '/staffRecruitment/reject',
    method: 'post'
  },
  staffRecruitmentTurnUp: {
    url: '/staffRecruitment/turnUp',
    method: 'post'
  },
  staffRecruitmentReplenish: {
    url: '/staffRecruitment/replenish',
    method: 'post'
  },
  //招聘反馈
  staffRecruitmentFeedBack: {
    url: '/staffRecruitment/feedBack',
    method: 'post'
  },
  //分配保存接口
  saveStaffRecruitmentDistribution: {
    url: '/StaffRecruitment/Distribution',
    method: 'post'
  },
  //获取分配人
  getStaffRecruitmentDistStaffList: {
    url: '/StaffRecruitment/GetDistStaffList',
    method: 'get'
  }
}

export default recruit