/**
 * 我的资产
 * 
 */

const myAssets = {
  // 获取我的硬件设备列表
  getAssetHardwareOwnerList: {
    url: '/assetHardware/getOwnerList',
    method: 'get'
  },
  //获取手机号列表
  getAssetPhoneNumberOwnerList: {
    url: '/assetPhoneNumber/getOwnerList',
    method: 'get'
  },
  // 系统账号列表
  getSystemOwnerList: {
    url: '/assetSystemAccount/getOwnerList',
    method: 'get'
  },
  // 软件账号列表
  getSoftOwnerList: {
    url: '/assetSoftAccount/getOwnerList',
    method: 'get'
  },
}

export default myAssets
