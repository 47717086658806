/**
 * 离职管理
 * 
 */

const dimission = {
  // 姓名
  getStaffDirectStaffList: {
    url: '/staff/getDirectStaffList',
    method: 'get'
  },
  getStaffLeavejobList: {
    url: '/staffLeavejob/getStaffLeavejobList',
    method: 'get'
  },
  delLeavejobUpdateIsDel: {
    url: '/staffLeavejob/updateIsDel',
    method: 'post'
  },
  getStaffLeavejobInfo: {
    url: '/staffLeavejob/getStaffLeavejobInfo',
    method: 'get'
  },
  setStaffLeavejobReplenish: {
    url: '/staffLeavejob/replenish',
    method: 'post'
  },
  setStaffLeavejobTurnUp: {
    url: '/staffLeavejob/turnUp',
    method: 'post'
  },
  setStaffLeavejobPass: {
    url: '/staffLeavejob/pass',
    method: 'post'
  },
  setStaffLeavejobReject: {
    url: '/staffLeavejob/reject',
    method: 'post'
  },
  saveStaffLeavejobInfo: {
    url: '/staffLeavejob/saveStaffLeavejobInfo',
    method: 'post'
  },
  // 交接员工
  getDepartmentStaffList: {
    url: '/staff/getDepartmentStaffList',
    method: 'get'
  },
  getTransferInUserData: {
    url: '/fortune/AgentTransfer/GetTransferInUserData',
    method: 'get'
  },
  // 判断员工是否可离职
  getStaffCanLeaveJob: {
    url: '/staffLeavejob/staffCanLeaveJob',
    method: 'get'
  },
  //导出
  getStaffLeavejobExportTpl: {
    url: '/staffLeavejob/GetExportTpls',
    method: 'get'
  },
  getStaffLeavejobTplItems: {
    url: '/staffLeavejob/GetExportFields',
    method: 'get'
  },
  setStaffLeavejobSaveExportTpls: {
    url: '/staffLeavejob/SaveExportTpls',
    method: 'post'
  },
  exportStaffLeavejob: {
    url: '/staffLeavejob/Export',
    method: 'post'
  },
  //取当前登录员工以及其直接下属未离职员工接口 =>多返回一个字段isNewProcedure是否启用新审批流
  getStaffLeavejobDirectStaffList: {
    url: '/staffLeavejob/getDirectStaffList',
    method: 'get'
  },
  //离职审批上传资料接口（权签1.0.0）
  setstaffLeavejobUploadInfo: {
    url: '/staffLeavejob/saveUploadInfo',
    method: 'post'
  },
  //更新工资结算日期接口（权签1.0.1）
  updateWagesSettleDate: {
    url: '/staffLeavejob/updateWagesSettleDate',
    method: 'post'
  },
  //更新工资结算时间接口（需求2686）
  updateWagesSettleTime: {
    url: '/staffLeavejob/updateWagesSettleTime',
    method: 'post'
  },
  //更新办理离职时间接口（权签1.0.1）
  updateTureLeaveDate: {
    url: '/staffLeavejob/updateTureLeaveDate',
    method: 'post'
  },
  //更新劳动合同解除协议接口（权签1.0.1）
  updateTerminationAgreement: {
    url: '/staffLeavejob/updateTerminationAgreement',
    method: 'post'
  },
  //更新辞职信接口（权签1.0.1）
  updateLetterResignation: {
    url: '/staffLeavejob/updateLetterResignation',
    method: 'post'
  },
  //获取离职资料接口
  getStaffLeavejobData: {
    url: '/staffLeavejob/getStaffLeavejobData',
    method: 'get'
  },
  //修改离职资料接口
  setModifyLeavejobData: {
    url: '/staffLeavejob/modifyLeavejobData',
    method: 'post'
  },
}

export default dimission