/**
 * 权限管理
 * 
 */
const authManageUrls = {
  //获取角色列表
  getRoleList: {
    url: '/Role/search',
    method: 'get'
  },
  //添加角色
  onRoleAdd: {
    url: '/Role/Add',
    method: 'post'
  },
  //编辑角色
  onRoleEdit: {
    url: '/Role/edit',
    method: 'post'
  },
  //根据角色ID查询角色信息
  onGetRoleById: {
    url: '/Role/GetRoleById',
    method: 'get'
  },
  //启用或者禁用角色接口
  setRoleEnabled: {
    url: '/Role/SetEnabled',
    method: 'post'
  },
  //删除角色
  getRoleDelete: {
    url: '/Role/Delete',
    method: 'get'
  },
  //根据角色ID获取关联的员工数量
  getRoleGetStaffListCountByRoleId: {
    url: '/Role/GetStaffListCountByRoleId',
    method: 'get'
  },
  //添加角色获取列表 树形结构
  getButtonAuthDefaultSettingTree: {
    url: '/Role/GetButtonAuthDefaultSettingTree',
    method: 'get'
  },
  //编辑角色获取携带用户id的列表信息
  getAuthByRoleId: {
    url: '/Role/GetAuthByRoleId',
    method: 'get'
  },
  //获取角色类型列表
  getRoleTypeList: {
    url: '/Role/GetRoleTypeList',
    method: 'get'
  },
  //获取所有父级角色列表
  getParentRoleList: {
    url: '/Role/GetParentRoleList',
    method: 'get'
  },
  //添加子角色
  onRoleAddChildRole: {
    url: '/Role/AddChildRole',
    method: 'post'
  },
  //修改子角色
  onRoleEditChildRole: {
    url: '/Role/EditChildRole',
    method: 'post'
  },
  //个人授权-获取员工列表(附带角色信息)
  getStaffRoleList: {
    url: '/Role/GetStaffRoleList',
    method: 'get'
  },
  //根据员工id获取分配的角色列表
  getRolesByStaffId: {
    url: '/Role/GetRolesByStaffId',
    method: 'get'
  },
  //个人授权提交
  onBindingRolesForStaff: {
    url: '/Role/BindingRolesForStaff',
    method: 'post'
  },
  //职位授权获取列表
  getAllDepartmentJobRoles: {
    url: '/Role/GetAllDepartmentJobRoles',
    method: 'get'
  },
  //职位授权
  onBindingRolesForJob: {
    url: '/Role/BindingRolesForJob',
    method: 'post'
  },
  //根据部门id和职位id获取分配的角色
  getRolesByDepartmentIdAndJobId: {
    url: '/Role/GetRolesByDepartmentIdAndJobId',
    method: 'get'
  },
  //自动创建审批角色的子角色接口
  onAutoCreateApprovalChilds: {
    url: '/Role/AutoCreateApprovalChilds',
    method: 'get'
  },
  //获取角色创建人列表（支持模糊搜索）
  getCreatorList: {
    url: '/Role/GetCreatorList',
    method: 'get'
  },
  //根据提单范围获取公司的接口
  getRoleModuleCompanyList: {
    url: '/role/GetRoleModuleCompanyList',
    method: 'get'
  },
  //根据提单范围获取公司的接口新加
  getCompanyListByApprovalRoleId: {
    url: '/role/GetCompanyListByApprovalRoleId',
    method: 'get'
  },
  //根据提单范围获取公司的接口再加
  getCompanyListByParentRoleId: {
    url: '/role/GetCompanyListByParentRoleId',
    method: 'get'
  },
  //获取登录授权列表
  getRepeatLoginList: {
    url: '/fortune/RepeatLogin/GetList',
    method: 'get'
  },
  //登录授权添加、修改
  setRepeatLoginHandle: {
    url: '/fortune/RepeatLogin/Handle',
    method: 'post'
  }
}

export default authManageUrls