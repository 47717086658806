/**
 * 模块名称: 问答详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import { Button, Icon } from 'antd'
import './style.scss'

const AskDetails = ({ data, dataTwo, onClose, height = 200, url}) => {
  const [showAll, setShowAll] = useState(false)
  const [showAllTwo, setShowAllTwo] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  const [showBtnTwo, setShowBtnTwo] = useState(false)
  const [contentHeight, setContentHeight] = useState(height)
  const [contentHeightTwo, setContentHeightTwo] = useState(height)
  const contentRef = useRef(null)
  const contentRefTwo = useRef()
  const [aData, setAData] = useState({})
  const [aDataTwo, setADatatwo] = useState({})

  useEffect(() => {
    if (data.content) {
      let nodes = data.content
      let reg = new RegExp('<table border="0"', 'g')
      nodes = nodes.replace(reg, '<table style="border:1px solid #666" ')
      let reg2 = new RegExp('<td', 'g')
      nodes = nodes.replace(reg2, '<td style="border:1px solid #666" ')
      let reg3 = new RegExp('<th', 'g')
      nodes = nodes.replace(reg3, '<th style="border:1px solid #666" ')
      data.content = nodes
    }
    setAData(data)
    if (contentRef.current.scrollHeight > height && !showAll) {
      setShowBtn(true)
    }

    if (dataTwo.content) {
      // console.log("contentRefTwo",contentRefTwo)
      let nodes = dataTwo.content
      let reg = new RegExp('<table border="0"', 'g')
      nodes = nodes.replace(reg, '<table style="border:1px solid #666" ')
      let reg2 = new RegExp('<td', 'g')
      nodes = nodes.replace(reg2, '<td style="border:1px solid #666" ')
      let reg3 = new RegExp('<th', 'g')
      nodes = nodes.replace(reg3, '<th style="border:1px solid #666" ')
      dataTwo.content = nodes
    }
    setADatatwo(dataTwo)
    if (contentRefTwo.current !== undefined) {
      if (contentRefTwo.current.scrollHeight > height && !showAllTwo) {
        setShowBtnTwo(true)
      }
    }
  })

  const onShowAll = () => {
    setShowBtn(false)
    setShowAll(true)
    setContentHeight('auto')
  }

  const onShowAllTwo = () => {
    setShowBtnTwo(false)
    setShowAllTwo(true)
    setContentHeightTwo('auto')
  }

  return (
    <div className="details-wrap">
      <h3 className="details-wrap-title">{aData.title}</h3>
      <div
        className={`details-content ${showAll ? 'show-all' : ''}`}
        style={{ height: contentHeight }}
        ref={contentRef}>
          <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: aData.content}} />
      </div>
      {showBtn && <div className="read-more"><Icon onClick={onShowAll} type="down" /></div>}
      回复:
      {aDataTwo.content ? 
        <div
          className={`details-content ${showAllTwo ? 'show-all-two' : ''}`}
          style={{ height: contentHeightTwo }}
          ref={contentRefTwo}>
            <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: aDataTwo.content}} />
        </div> : <div className="details-hint">未回复</div>}
     
      <div className="details-footer">
        {showBtnTwo && <div className="read-more"><Icon onClick={onShowAllTwo} type="down" /></div>}
        <div className="download-btn-wrap">
          <Button onClick={onClose}>关闭</Button>
          {url && <a target="_blank" href={url} className="ant-btn ant-btn-primary" rel="noopener noreferrer">下载附件</a>}
        </div>
      </div>
    </div>
  )
}

export default AskDetails