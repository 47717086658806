/*
 * @Author      : OBKoro1(James Wang)
 * @Date        : 2020-09-24 09: 11: 56
 * @Lastditors  : OBKoro1(your name)
 * @LastEditTime: 2020-12-31 08: 54: 26
 * @Description : file content
 */
/**
 * 忘记密码模块
 * 
 */
const forget = {
  checkAccount: {
    url   : '/Login/CheckAccount',
    method: 'get'
  },
  checkPhone: {
    url   : '/Login/CheckPhone',
    method: 'get'
  },
  checkImageCode: {
    url   : '/Login/CheckImageCode',
    method: 'get'
  },
  checkCode: {
    url   : '/Login/CheckCode',
    method: 'get'
  },
  findPassword: {
    url   : '/Login/findPassword',
    method: 'post'
  },
  setPassword: {
    url   : '/Login/setPassword',
    method: 'post'
  },
  setLoginCheckType: {
    url   : '/Login/CheckType',
    method: 'get'
  },
    forgetCheckPhone: {
    url   : '/Login/ForgetCheckPhone',
    method: 'get'
  },
}

export default forget