/**
 * 日常办公 => 费用管理
 *
 */

const costManagementUrls = {
	//费用列表
	getOfficeCostManagementList: {
		url: '/OfficeCostManagement/GetList',
		method: 'get'
	},
	//费用列表-已结算
	getOfficeCostManagementForPayFinishedList: {
		url: '/OfficeCostManagement/GetListForPayFinished',
		method: 'get'
	},
	//导出接口
  getOfficeCostManagementTplItems: {
    url: '/OfficeCostManagement/GetExportFields',
    method: 'get',
  },
  getOfficeCostManagementExportTpl: {
    url: '/OfficeCostManagement/GetExportTpls',
    method: 'get',
  },
  saveOfficeCostManagementExportTpls: {
    url: '/OfficeCostManagement/SaveExportTpls',
    method: 'post',
  },
  exportOfficeCostManagement: {
    url: '/OfficeCostManagement/Export',
    method: 'post',
  },
	//删除
	setOfficeCostManagementDel: {
		url: '/OfficeCostManagement/Del',
		method: 'get'
	},
	//申请
	setOfficeCostManagementApply: {
		url: '/OfficeCostManagement/apply',
		method: 'post'
	},
	//获取费用类别
	getOfficeCostManagementCostClass: {
		url: '/OfficeCostManagement/GetCostClass',
		method: 'get'
	},
	//获取付款方式
	getOfficeCostManagementPayWay: {
		url: '/OfficeCostManagement/GetPayWay',
		method: 'get'
	},
	//获取业务状态
	getOfficeCostManagementBusStatus: {
		url: '/OfficeCostManagement/GetBusStatus',
		method: 'get'
	},
	//费用审批
	setOfficeCostManagementAudit: {
		url: '/OfficeCostManagement/Audit',
		method: 'post'
	},
	//费用详情
	getOfficeCostManagementDetail: {
		url: '/OfficeCostManagement/detail',
		method: 'get'
	},
	//收款方信息修改
	setOfficeCostManagementEditPayee: {
		url: '/OfficeCostManagement/EditPayee',
		method: 'post'
	},
	//导出
	getOfficeCostManagementChangeTplItems: {
		url: '/OfficeCostManagement/GetExportFields',
		method: 'get'
	},
	getOfficeCostManagementChangeExportTpl: {
		url: '/OfficeCostManagement/GetExportTpls',
		method: 'get'
	},
	saveOfficeCostManagementChangeExportTpls: {
		url: '/OfficeCostManagement/SaveExportTpls',
		method: 'post'
	},
	exportOfficeCostManagementExport: {
		url: '/OfficeCostManagement/Export',
		method: 'post'
	}
}

export default costManagementUrls