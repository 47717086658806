/**
 * 业绩管理/消耗业绩时间节点
 * 
 */

const nodeManage = {
	//获取员工，项目，产品对应的供应商接口
	getPerformanceTimeStaffProSupplierList: {
		url: '/fortune/PerformanceTime/GetStaffProSupplierList',
		method: 'get'
	},
	//获取消耗业绩时间节点列表接口
	getPerformanceTimeList: {
		url: '/fortune/PerformanceTime/GetList',
		method: 'get'
	},
	//获取消耗业绩时间节点信息接口
	getPerformanceTimeInfo: {
		url: '/fortune/PerformanceTime/GetInfo',
		method: 'get'
	},
	//保存消耗业绩时间节点信息接口
	setPerformanceTimeSave: {
		url: '/fortune/PerformanceTime/save',
		method: 'post'
	},
	//开启消耗业绩时间节点接口
	setPerformanceTimeOpen: {
		url: '/fortune/PerformanceTime/open',
		method: 'post'
	},
	//关闭消耗业绩时间节点接口
	setPerformanceTimeClose: {
		url: '/fortune/PerformanceTime/close',
		method: 'post'
	},
	//获取公司员工
	getPerformanceAllStaffList: {
		url: '/staff/GetAllStaffList',
		method: 'get'
	}
}
export default nodeManage