/**
 * 晋升管理
 * 
 */

const promotion = {
  getPromotionList: {
    url: '/staffPromotion/getStaffPromotionList',
    method: 'get'
  },
  getSubordinateStaffList: {
    url: '/staff/getSubordinateStaffList',
    method: 'get'
  },
  getJobStationLevelList: {
    url: '/jobStationLevel/getJobStationLevelList',
    method: 'get'
  },
  delStaffUpdateIsDel: {
    url: '/staffPromotion/updateIsDel',
    method: 'post'
  },
  saveStaffPromotionInfo: {
    url: '/staffPromotion/saveStaffPromotionInfo',
    method: 'post'
  },
  getStaffPromotionInfo: {
    url: '/staffPromotion/getStaffPromotionInfo',
    method: 'get'
  },
  getstaffPromotionPass: {
    url: '/staffPromotion/pass',
    method: 'post'
  },
  getstaffPromotionReject: {
    url: '/staffPromotion/reject',
    method: 'post'
  },
  setStaffPromotionTurnUp: {
    url: '/staffPromotion/turnUp',
    method: 'post'
  },
  setStaffPromotionReplenish: {
    url: '/staffPromotion/replenish',
    method: 'post'
  }
}
export default promotion