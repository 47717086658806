/**
 * 公司信息管理
 * 
 */

const corpUrls = {
  getCorp: {
    url: '/company/getCompanyList',
    method: 'get'
  },
  getDepartment: {
    url: '/department/getDepartmentList',
    method: 'get'
  },
  getDepartmentinfo: {
    url: '/department/getDepartmentinfo',
    method: 'get'
  },
  getGroup: {
    url: '/group/getGroupList',
    method: 'get'
  },
  saveCompany: {
    url: '/company/saveCompanyinfo',
    method: 'post'
  },
  getColtype: {
    url: '/company/getColtype',
    method: 'post'
  },
  setColtype: {
    url: '/company/setColtype',
    method: 'post'
  },
  saveDepartment: {
    url: '/department/saveDepartmentinfo',
    method: 'post'
  },
  saveGroup: {
    url: '/group/saveGroupinfo',
    method: 'post'
  },
  delCompany: {
    url: '/company/updateIsDel',
    method: 'post'
  },
  delDepartment: {
    url: '/department/updateIsDel',
    method: 'post'
  },
  delGourp: {
    url: '/group/updateIsDel',
    method: 'post'
  },
  updateIsValid: {
    url: '/company/updateIsValid',
    method: 'post'
  },
  updateCompanySort: {
    url: '/company/updateSort',
    method: 'post'
  },
  updateDepartmentSort: {
    url: '/department/updateSort',
    method: 'post'
  },
  updateGroupSort: {
    url: '/group/updateSort',
    method: 'post'
  },
  getTopProjectList:{
    url: '/fortune/sysProject/getTopProjectList',
    method: 'get'
  },
  getCompanyinfo:{
    url: '/company/getCompanyinfo',
    method: 'get'
  },
  setMainProjects:{
    url: '/department/setMainProjects',
    method: 'post'
  },
  setProjectArea:{
    url: '/department/setProjectArea',
    method: 'post'
  },
  getSysProjectSettingList:{
    url: '/fortune/companyProjectSetting/getSysProjectSettingList',
    method: 'get'
  },

  getSysSupplierList:{
    url: '/fortune/sysSupplier/getSysSupplierList',
    method: 'get'
  }

}

export default corpUrls