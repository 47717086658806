/**
 * 社保公积金
 * 
 */

const socialfund = {
  getSocialsecGetList: {
    url: '/Socialsec/GetList',
    method: 'get'
  },
  getSocialsecImportData: {
    url: '/Socialsec/ImportData',
    method: 'get'
  },
  getSocialsecExportTpl: {
    url: '/Socialsec/GetExportTpls',
    method: 'get'
  },
  getSocialsecTplItems: {
    url: '/Socialsec/GetExportFields',
    method: 'get'
  },
  getSocialSaveExportTpls: {
    url: '/Socialsec/SaveExportTpls',
    method: 'post'
  },
  getSocialsecExport: {
    url: '/Socialsec/Export',
    method: 'post'
  },
  getSocialAccumulateGetList: {
    url: '/SocialAccumulate/GetList',
    method: 'get'
  },
  getAccumulateImportExcel: {
    url: '/SocialAccumulate/ImportExcel',
    method: 'get'
  },
  getSocialAccumulateExportTpl: {
    url: '/SocialAccumulate/GetExportTpls',
    method: 'get'
  },
  getSocialAccumulateTpl: {
    url: '/SocialAccumulate/GetExportFields',
    method: 'get'
  },
  getAccumulateSaveTpls: {
    url: '/SocialAccumulate/SaveExportTpls',
    method: 'post'
  },
  getSocialAccumulateExport: {
    url: '/SocialAccumulate/Export',
    method: 'post'
  }
}

export default socialfund