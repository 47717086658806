/**
 * 接口文件
 * url的第一个反斜杠/一定要加，否则请求会带上相对路径
 */

import common from './common'
import corpManage from './module/corpManage'
import importDown from './module/importDown'
import authManage from './module/authManage'
import index from './module/index'
import dailyWork from './module/dailyWork'
import setSystem from './module/setSystem'
import manpower from './module/manpower'
import forget from './module/forget'
import message from './module/message'
import schedule from './module/schedule'
import promotion from './module/promotion'
import procedure from './module/procedure'
import jobLevel from './module/jobLevel'
import jobTransfer from './module/jobTransfer'
import recruit from './module/recruit'
import dimission from './module/dimission'
import attendance from './module/attendance'
import agentTransfer from './module/agentTransfer'
import wage from './module/wage'
import socialfund from './module/socialfund'
import management from './module/management'
import train from './module/train'
import menuControl from './module/menuControl'
import configMsg from './module/configMsg'
import protocol from './module/protocol'
import invoice from './module/invoice'
import contract from './module/contract'
import account from './module/account'
import authorization from './module/authorization'
import refund from './module/refund'
import fundMoneyBack from './module/fundMoneyBack'
import fundMoneyClaim from "./module/fundMoneyClaim"
import fundMoneyTransmit from "./module/fundMoneyTransmit"
import customer from "./module/customer"
import orders from './module/orders'
import agent from './module/agent'
import agentIntegral from "./module/agentIntegral"
import agentName from "./module/agentName"
import agentManufacturer from "./module/agentManufacturer"
import template from "./module/template"
import quittance from './module/quittance'
import clientTransfer from './module/clientTransfer'
import performanceAdjustment from './module/performanceAdjustment'
import productSet from './module/productSet'
import nodeManage from './module/nodeManage'
import creditStatistics from './module/creditStatistics'
import kingdeeUrls from './module/kingdee'
import fund from './module/fund'
import costManagement from './module/costManagement'
import purchase from './module/purchase'
import toBeSettled from './module/toBeSettled'
import supplie from './module/supplie'
import settlement from './module/settlement'
import achievement from './module/achievement'
import corpAssets from './module/corpAssets'
import myAssets from './module/myAssets'
import analysis from './module/analysis'
import corpAccount from './module/corpAccount'
import supplierContract from './module/supplierContract'
import supplierContractSeal from './module/supplierContractSeal'
import device from './module/device'
const prefix = '/api'
const urls   = {
  login: {
    url   : '/Login/Login',
    method: 'post'
  },
  logout: {
    url   : '/Login/logout',
    method: 'get'
  },
  checkUserByAccount: {
    url   : '/Login/CheckUserByAccount',
    method: 'get'
  },
  getMenu: {
    url   : '/SysMenu/GetMenuListTree',
    method: 'get'
  },
  getIndex: {
    url   : '/Home/index',
    method: 'get'
  },
  getUser: {
    url   : '/staff/getStaffInfo',
    method: 'get'
  },
  getEmailCode: {
    url   : '/Login/GetEmailCode',
    method: 'get'
  },
  getPhoneCode: {
    url   : '/Login/GetPhoneCode',
    method: 'get'
  },
  getLoginUpdatePwd: {
    url   : '/Login/UpdatePwd',
    method: 'post'
  },
  getWxBindConfig: {
    url   : '/WxCompany/GetBindConfig',
    method: 'get'
  },
  getWxCompanyBind: {
    url   : '/WxCompany/Bind',
    method: 'get'
  },
  saveWxCompanyUnbind: {
    url   : '/WxCompany/Unbind',
    method: 'post'
  },
  setBindCallBack: {
    url   : '/WxCompany/BindCallBack',
    method: 'get'
  },
  getCommonWxTab: {
    url   : '/common/GetWxTab',
    method: 'get'
  },
  getSelectCompanyList: {
    url   : '/company/getSelectCompanyList',
    method: 'get'
  },

  getCustomerGetInfo: {
    url   : '/fortune/Customer/GetInfo',
    method: 'get'
  },

  getFundAccountList: {
    url   : '/fortune/Fund/FundAccountList',
    method: 'get'
  },

  //日志管理
  ...management,

  // 忘记密码模块
  ...forget,

  // 导入下载管理
  ...importDown,

  // 系统配置管理
  ...setSystem,

  // 日常办公
  ...dailyWork,

  // 公司信息管理
  ...corpManage,

  //员工信息管理
  ...manpower,

  //岗位级别
  ...jobLevel,

  // 转岗管理
  ...jobTransfer,

  // 晋升管理
  ...promotion,

  // 招聘管理
  ...recruit,

  // 离职管理
  ...dimission,

  // 权限管理
  ...authManage,

  // 首页
  ...index,

  // 系统消息
  ...message,

  // 待办事项
  ...schedule,

  // 审批流
  ...procedure,

  // 考勤管理
  ...attendance,

  //代理商转移
  ...agentTransfer,

  // 工资管理
  ...wage,

  // 社保公积金
  ...socialfund,

  // 培训管理
  ...train,

  // 委托书管理接口
  ...authorization,

  // 退款接口
  ...refund,

  // 菜单管理
  ...menuControl,

  //合同票据以及协议管理
  ...protocol,

  //发票管理
  ...invoice,

  // 公用数据接口
  ...common,

  // 业务配置管理
  ...configMsg,

  //合同管理
  ...contract,

  //到款接口
  ...fundMoneyBack,

  //认款接口
  ...fundMoneyClaim,

  //转款接口
  ...fundMoneyTransmit,

  //资金账号接口
  ...account,

  // 客户管理
  ...customer,

  //订单（直销订单、代理商订单）
  ...orders,

  //代理商管理
  ...agent,

  //代理商积分接口
  ...agentIntegral,

  //代理商信息变更
  ...agentName,

  //代理商厂商id转移管理
  ...agentManufacturer,

  //模板管理
  ...template,

  //收据管理
  ...quittance,
 
  //转户
  ...clientTransfer,

  //业绩调整
  ...performanceAdjustment,

  //业绩调整/消耗业绩产品配置
  ...productSet,

  //业绩调整/消耗业绩时间节点
  ...nodeManage,

  //信用帐户统计
  ...creditStatistics,

  //金蝶
  ...kingdeeUrls,

  // 老oa客户对应表
  ...fund,

  //日常办公 => 费用管理
  ...costManagement,

  //资产采购管理
  ...purchase,

  //待结算列表
  ...toBeSettled,
  // 系统充值管理
  ...supplie,
  //已结算结算列表
  ...settlement,
  // 绩效管理
  ...achievement,
  //公司资产
  ...corpAssets,
  //我的资产
  ...myAssets,
  //数据分析中心
  ...analysis,
  //公司账户管理
  ...corpAccount,
  //供应商管理模块 => 合同内容确认
  ...supplierContract,
  //供应商管理模块 => 合同盖章申请
  ...supplierContractSeal,
  // vr设备管理
  ...device
}

for (let k in urls) {//eslint-disable-line
  urls[k].url = prefix + urls[k].url
}

export default urls