/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-04-19 17:40:32
 */
import React, { useEffect } from 'react'
import Routes from './routes'
import api from './api'
import { updateConfig } from './global/reducer/config'
import { useDispatch } from 'react-redux'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    api.getSysConfigInfo().then(data => {
      dispatch(updateConfig(data))
    })
  }, [])
  
  return (
    <>
      <Routes />
    </>
  )
}

export default App
