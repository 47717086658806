/**
 * 模块名称: 上传图片组件 可删除，可预览
 * @author yangzhihang@372163.com
 * 
 * 脱离form使用时，是个受控组件，需要在父组件指定value的值，才能正常显示上传后的图片
 * 组件接收的常用参数(选填)：
 *      1. url 上传路径
 *      2. onUpload 上传成功后的回调函数
 *      3. imageUrl (修改)
 *      4. width, height, cover, data等
 *      5. vlaue 默认值 //getFieldDecorator高阶组件提供的参数 
 *      6. onChange 事件 //getFieldDecorator高阶组件提供的参数
 */

import React, { Component, Fragment } from 'react'
import { Icon, Upload, message, Modal } from 'antd'
import './style.scss'
import urls from '@/api/urls'

const { Dragger } = Upload

class UploadImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      imageUrl: props.value || '',
      previewVisible: false,
      previewImage: '',
      showIcon: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.imageUrl) {
      return {
        imageUrl: nextProps.value
      }
    }
    return null
  }

  onChangeUpload = (info) => {
    const { status, response } = info.file
    if (info.file.status === 'uploading') {
      this.setState({
        loading: true,
        imageUrl: ''
      })
      return
    }
    if (status === 'done') {
      if (response.code === 0) { 
        this.setState({
          imageUrl: response.data.full,
          loading: false
        }, () => {
          const { onChange, onUpload } = this.props
          // onChange 是getFieldDecorator高阶组件提供的函数
          if (onChange) {
            onChange(response.data.full)
          }
          // onUpload 是自定义的事件 方便脱离antd组件使用
          if (onUpload) {
            onUpload(response.data.full)
          }
        })
      } else {
        this.setState({
          loading: false
        })
        message.error(response.msg || '服务器发生错误')
      }
    }
    else if (status === 'error') {
      this.setState({
        loading: false
      })
      message.error(`服务器发生错误`)
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = evt => {
    evt.stopPropagation()
    const { imageUrl } = this.state

    this.setState({
      previewImage: imageUrl,
      previewVisible: true,
    })
  }

  handleRemove = evt => {
    evt.stopPropagation()
    this.setState({
      imageUrl: '',
    }, () => {
      const { onChange, onUpload } = this.props
      // onChange 是getFieldDecorator高阶组件提供的函数
      if (onChange) {
        onChange('')
      }
      // onUpload 是自定义的事件 方便脱离antd组件使用
      if (onUpload) {
        onUpload('')
      }
    })
  }

  handleEnter = () => {
    this.setState({
      showIcon: true
    })
  }

  handleLeave = () => {
    this.setState({
      showIcon: false
    })
  }

  render() {
    const { loading, imageUrl, previewVisible, previewImage, showIcon } = this.state
    const { url, name, cover, data, placeholder } = this.props

    return (
      <>
        <Dragger
          name={name || 'file'}
          action={url || urls.fileUpload.url}
          accept={data && data.allowExts ? data.allowExts.split(',').map(item => `.${item}`).join(',') : '.jpg,.jpeg,.png,.gif'}
          onChange={this.onChangeUpload}
          withCredentials={true}
          showUploadList={false}
          headers={{ token: localStorage.getItem('token') }}
          className={`image-upload ${cover ? 'cover-image' : ''}`}
          data={data ? {...data, allowSizeType: 1} : { allowSizeType: 1 }}
        >
          <div className="upload-wrap" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
            {imageUrl
              ? (
                <div className="upload-image-super">
                  {showIcon && (
                    <div className="upload-icon-super">
                      <Icon 
                        type='eye' 
                        style={{ fontSize: 30, color: '#ffffff', marginTop: '60px', marginRight: 10 }} 
                        theme='filled'
                        onClick={this.handlePreview} 
                      />
                      <Icon 
                        type='delete' 
                        style={{ fontSize: 30, marginTop: '60px', color: '#ffffff', marginLeft: '30px' }} 
                        theme='filled'
                        onClick={this.handleRemove} 
                      />
                    </div>
                  )}
                  <img className="upload-image" src={imageUrl} alt="iamge" />
                </div>
              )
              : <Fragment>
                <Icon type={loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{placeholder ? placeholder : '上传图片'}</div>
              </Fragment>}
          </div>
        </Dragger>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    )
  }
}

export default UploadImage
