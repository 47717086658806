import { createSlice } from "redux-starter-kit"

const userSlice = createSlice({
  slice: 'userInfo',
  initialState: {
    id: '',
    staffName: '',
    staffNo: '',
    workEmail: '',
    workMobile: '',
    companyId: '',
    groupId: '',
    departmentId: '',
    departmentName: '',
    isMainAccount: 1
  },
  reducers: {
    setUserInfo(state, action) {
      return action.payload
    }
  }
})

const { actions, reducer } = userSlice
export const { setUserInfo } = actions
export default reducer
   