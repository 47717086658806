/**
 * 模块名称: 导出
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Modal, Input, Select, Button, Form, Row, Col, Icon, Tag, message } from 'antd'
import './style.scss'
import api from '@/api'
import { useHistory } from 'react-router-dom'

const { CheckableTag } = Tag
const FormItem = Form.Item
const Option = Select.Option

const Export = props => {
  // console.log('props', props)
  const { show, onConfirm, onCancel, title = '导出', form, tplUrl, fieldsUrl, saveUrl, exportUrl, method, ExportType, parame = '', type, cRef, screen } = props
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue } = form
  const [tpls, setTpls] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [showSetting, setShowSetting] = useState(false)
  const tplNameRef = useRef(null)
  const [helpText, setHelpText] = useState('')
  const [corpFilter, setCorpFilter] = useState([])
  const [corpFilterList, setCorpFilterList] = useState([])

  useEffect(() => {
    if (method !== '') {
      api[tplUrl]({ method }).then(tpls => setTpls(tpls))
    }
  }, [method])

  useImperativeHandle(cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeVal: () => {
      setShowSetting(false)
      setCorpFilter([])
    }
  }))

  // 导出
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if (type == 1 && corpFilter.length > 0 && !(corpFilter.some((v, i) => v.checked == true))) {
        return message.warning('请设置要导出的主体')
      }
      if (!err) {

        if (vals.dataRange === 2 && (!parame.id || parame.id === '' || parame.id.length <= 0)) {
          message.error('未勾选任何记录')
          return
        }
        if (method === 'administrationExport') {
          parame.expType = 2
        }
        setLoading(true)
        api[exportUrl]({ ...vals, parame }).then(() => {
          onCancel()
          setLoading(false)
          setShowSetting(false)
          Modal.confirm({
            title: '导出成功',
            content: '请前往下载管理页进行下载',
            okText: '前往下载',
            cancelText: '关闭',
            icon: <Icon type="check-circle" />,
            onOk() {
              history.push('/importDown/tolead')
            }
          })
        })
          .catch(err => setLoading(false))
      }
    })
  }

  const onStting = () => {
    // console.log('parame=>', parame)
    // console.log('type=>', type)
    if (type == 1 && !(corpFilter.length > 0)) {
      api.getCorpFilterList({limit: 9999999, notHeadOffice: 0}).then(res => {
        setCorpFilterList(res)
        let arr
        if (parame.branch) {
          arr = parame.branch
        } 
        if (parame.companyId) {
          arr = parame.companyId
        }
        let list
        if (arr.length == 1) {
          list = res.filter(v => v.id == arr[0])
        } else {
          list = res
        }
        const lists = list.map(item => ({ ...item, checked: true }))
        // console.log('lists', lists)
        setCorpFilter(lists)
      })
    }
    setShowSetting(true)
    let obj = {method}
    let newobj = {}
    if (ExportType) obj.ExportType = ExportType
    if (screen) { // screen根据筛选项获取设置要导出的字段
      newobj = {...obj, ...screen}
    }
    api[fieldsUrl](screen ? newobj : obj).then(list => {
      const tplId = getFieldValue('tplId')
      // console.log('tplId', tplId)
      // 默认模版
      if (tplId === 0) {
        setTags(list.map(item => ({ ...item, checked: true })))
      } else {
        const fields = tpls.find(item => item.id === tplId).fields.split(',')
        setTags(getCurrented(list, fields))
      }
    })
  }

  const getCurrented = (list, fields) => {
    const currented = []
    for (let i = 0; i < list.length; i++) {
      let isFound = false
      for (let j = 0; j < fields.length; j++) {
        if (fields[j] === list[i].id) {
          isFound = true
          currented.push({ ...list[i], checked: true })
          break
        }
      }
      if (!isFound) {
        currented.push({ ...list[i], checked: false })
      }
    }
    return currented
  }

  //处理模板中主体的函数
  const getCurrenteds = (corpFilterList, companyFields) => {
    let arr = corpFilterList.map(item => ({ ...item, checked: false }))
    arr.map(item => {
      companyFields.map(v => {
        if ( item.id == v) {
          item.checked = true
        }
      })
    })
    return arr
  }

  //导出主体
  const onChangeTags = (checked, id) => {
    let arr = corpFilter.map(item => item.id === id ? { ...item, checked } : item)
    let arrNew = []
    // console.log('parame=>55555', parame)
    // console.log(arr)
    arr.map(v => {
      if (v.checked == true) {
        arrNew.push(v.id)
      }
    })
    // console.log(arrNew)
    if (parame.companyId) parame.companyId = arrNew
    if (parame.branch) parame.branch = arrNew
    // console.log('parame=>88888', parame)
    setCorpFilter(arr)
  }

  //导出字段
  const onChangeTag = (checked, id) => {
    setTags(tags.map(item => item.id === id ? { ...item, checked } : item))
  }

  const onDelTpl = () => {
    const tplId = getFieldValue('tplId')
    if (tplId === 0) {
      message.error('默认模版无法删除')
      return
    }
    api.delExportTpl({ id: tplId }).then(() => {
      message.success('删除成功')
      setShowSetting(false)
      setTpls(tpls.filter(item => item.id !== tplId))
      setFieldsValue({ tplId: 0 })
    })
  }

  const onSaveTpl = () => {
    const tplName = tplNameRef.current.state.value
    const tplId = getFieldValue('tplId')
    if (tplId === 0 && tplName === '') {
      setHelpText('请输入模版名称')
      return
    }
    if (!type == 1 && tplId === 0 && tplName && tags.every(item => item.checked)) {
      message.error('该模版导出字段和默认模版重复')
      return
    }
    setHelpText('')
    if (tags.filter(item => item.checked).length > 0) {
      if (type == 1) {
        api[saveUrl]({
          tplName: tplName ? tplName : tpls.find(item => item.id === tplId).tplName,
          fields: tags.filter(item => item.checked).map(item => item.id).join(','),
          companyFields: corpFilter.filter(v => v.checked == true).map(v => v.id).join(','),
          method
        }).then(res => {
          setShowSetting(false)
          api[tplUrl]({ method }).then(tpls => {
            setTpls(tpls)
            setFieldsValue({ tplId: res.id })
          })
        })
      } else {
        if (!tags.every(item => item.checked)) {
          api[saveUrl]({
            tplName: tplName ? tplName : tpls.find(item => item.id === tplId).tplName,
            fields: tags.filter(item => item.checked).map(item => item.id).join(','),
            method
          }).then(res => {
            setShowSetting(false)
            api[tplUrl]({ method }).then(tpls => {
              setTpls(tpls)
              setFieldsValue({ tplId: res.id })
            })
          })
        } else {
          message.error('该模版导出字段和默认模版重复')
        }
      }
    } else {
      message.error('导出的字段不能为空')
    }
  }

  const onChangeTplSelect = tplId => {
    setHelpText('')
    if (tplId === 0) {
      setTags(tags.map(item => ({ ...item, checked: true })))
      setCorpFilter(corpFilterList.map(item => ({ ...item, checked: true })))
    } else {
      const fields = tpls.find(item => item.id === tplId).fields.split(',')
      setTags(getCurrented(tags, fields))
      if (tpls.find(item => item.id === tplId).companyFields) {
        const companyFields = tpls.find(item => item.id === tplId).companyFields.split(',')
        setCorpFilter(getCurrenteds(corpFilterList, companyFields))
      }
    }
  }

  return (
    <Modal
      title={title}
      visible={show}
      onOk={onConfirm || (() => {})}
      onCancel={onCancel}
      width={800}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}
    >
      <div className="export-wrap">
        <div className="export-content">
          <Form onSubmit={formSubmit}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="文件名">
                  {getFieldDecorator('fileName', {
                    initialValue: '',
                    rules: [{ required: true, message: '文件名不能为空' }]
                  })(<Input placeholder="请输入文件名" maxLength={30} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="数据范围">
                  {getFieldDecorator('dataRange', { initialValue: 1 })(
                    <Select placeholder="请选择">
                      {/* <Option value={0}>全部记录</Option> */}
                      <Option value={1}>全部记录</Option>
                      <Option value={2}>选中记录</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="导出模版">
                  {getFieldDecorator('tplId', { initialValue: 0 })(
                    <Select placeholder="请选择" onChange={onChangeTplSelect}>
                      <Option value={0}>默认模版</Option>
                      {tpls.map(item => <Option key={item.id} value={item.id}>{item.tplName}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <div className="export-btns">
              <Button htmlType="submit" type="primary" loading={loading}>导出</Button>
              <Button onClick={onStting}>设置</Button>
            </div>
          </Form>
        </div>
        {showSetting ? 
          <div className="level-setting">
            {
              type == 1 && <>
                <div className="setting-title">
                  <h4>设置要导出的主体</h4>
                  {/* <Icon type="close" onClick={() => setShowSetting(false)} /> */}
                </div>
                <div>
                  {corpFilter.map(d => <CheckableTag key={d.id} checked={d.checked} onChange={checked => onChangeTags(checked, d.id)}>{d.name}</CheckableTag>)}
                </div>
              </>
            }
            <div className="setting-title">
              <h4>设置要导出的字段</h4>
              <Icon type="close" onClick={() => {
                setShowSetting(false)
                setCorpFilter([])
              }} />
            </div>
            <div>
              {tags.map(d => <CheckableTag key={d.id} checked={d.checked} onChange={checked => onChangeTag(checked, d.id)}>{d.name}</CheckableTag>)}
            </div>
            <div className="setting-control">
              <FormItem label="模版名称"
                validateStatus={helpText ? 'error' : 'success'}
                help={helpText}
              >
                <Input placeholder="请输入模版名称" size="small" ref={tplNameRef} defaultValue="" />
              </FormItem>
              <div className="save-tpl">
                <Button type="primary" onClick={onSaveTpl} size="small">保存</Button>
                <Button onClick={onDelTpl} size="small">删除</Button>
              </div>
            </div>
          </div> : null}
      </div>
    </Modal>
  )
}

export default Form.create()(Export)
