/**
 * 员工信息管理
 * 
 */

const manpowerUrls = {
  getManpower: {
    url: '/staff/getStaffList',
    method: 'get'
  },
  getStaffDetail: {
    url: '/staff/getStaffInfo',
    method: 'get'
  },
  getStaffAlteration: {
    url: '/staffChange/getStaffChangeList',
    method: 'get'
  },
  getLeaderList: {
    url: '/staff/getLeaderList',
    method: 'get'
  },
  saveUserInfo: {
    url: '/staff/saveStaffinfo',
    method: 'post'
  },
  saveUpdateAttendanceTimezone: {
    url: '/staff/updateAttendanceTimezone',
    method: 'post'
  },
  saveUpdateJobStation: {
    url: '/staff/updateJobStation',
    method: 'post'
  },
  saveUpdateDepartment: {
    url: '/staff/updateDepartment',
    method: 'post'
  },
  saveSearchStorage: {
    url: '/searchStorage/save',
    method: 'post'
  },
  getSearchStorage: {
    url: '/searchStorage/get',
    method: 'get'
  },
  saveExport: {
    url: '/staff/Export',
    method: 'post'
  },
  getImportExcel: {
    url: '/staff/ImportExcel',
    method: 'get'
  },
  saveStaffChangeExport: {
    url: '/staffChange/Export',
    method: 'post'
  },
  saveSendResetPassEmail: {
    url: '/staff/sendResetPassEmail',
    method: 'post'
  },
  getstaffTplItems: {
    url: '/staff/GetExportFields',
    method: 'get'
  },
  getstaffExportTpl: {
    url: '/staff/GetExportTpls',
    method: 'get'
  },
  savestaffExportTpls: {
    url: '/staff/SaveExportTpls',
    method: 'post'
  },
  exportStaff: {
    url: '/staff/Export',
    method: 'post'
  },
  getstaffChangeTplItems: {
    url: '/staffChange/GetExportFields',
    method: 'get'
  },
  getstaffChangeExportTpl: {
    url: '/staffChange/GetExportTpls',
    method: 'get'
  },
  savestaffChangeExportTpls: {
    url: '/staffChange/SaveExportTpls',
    method: 'post'
  },
  exportStaffChange: {
    url: '/staffChange/Export',
    method: 'post'
  },

  getInvoiceTplItems: {
    url: '/fortune/Invoice/GetExportFields',
    method: 'get'
  },
  getInvoiceExportTpl: {
    url: '/fortune/Invoice/GetExportTpls',
    method: 'get'
  },
  saveInvoiceExportTpls: {
    url: '/fortune/Invoice/SaveExportTpls',
    method: 'post'
  },
  exportInvoice: {
    url: '/fortune/Invoice/Export',
    method: 'post'
  },

  getStructure: {
    url: '/organization/getStructure',
    method: 'get'
  },
  //获取身份证存在情况接口
  getCheckIdentityCardNo: {
    url: '/staff/checkIdentityCardNo',
    method: 'get'
  },
  //修改员工密码接口
  setStaffPassword: {
    url: '/login/setStaffPassword',
    method: 'post'
  },
  //修改员工在职/离职状态
  setStaffStaffOnJob: {
    url: '/staff/SetStaffOnJob',
    method: 'post'
  },
  //入职图片下载
  downStfPic: {
    url: '/staff/DownStfPic',
    method: 'post'
  },
  //获取社会工龄接口
  setSocWorkAge: {
    url: '/staff/getSocWorkAge',
    method: 'post'
  },
  //删除待入职员工
  getDelStaff: {
    url: '/staff/delStaff',
    method: 'get'
  },
  //导入工龄
  importStaffSociologyWorkAge: {
    url: '/staff/importSociologyWorkAge',
    method: 'post'
  },
  //年假请假详情
  setStaffAnnualDetail: {
    url: '/staff/getStaffAnnualDetail',
    method: 'post'
  },
  //获取主账号信息
  setStaffGetMainAccount: {
    url: '/staff/getMainAccount',
    method: 'post'
  },
  //主副账号切换
  setStaffChangeMainAccount: {
    url: '/staff/changeMainAccount',
    method: 'post'
  },
}

export default manpowerUrls