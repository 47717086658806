import { createSlice } from "redux-starter-kit"

const loadingSlice = createSlice({
  initialState: false,
  reducers: {
    showLoading() {
      return true
    },
    hideLoading() {
      return false
    }
  }
})

const { actions, reducer } = loadingSlice

export const { showLoading, hideLoading } = actions
export default reducer