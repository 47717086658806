/**
 * 收据管理
 * 
 */

const quittanceUrls = {
	//收据列表
	getFortuneReceiptList: {
		url: '/fortune/Receipt/GetList',
		method: 'get'
	},
	//收据申请
	setFortuneReceiptApply: {
		url: '/fortune/Receipt/Apply',
		method: 'post'
	},
	//收据审批
	setFortuneReceiptAudit: {
		url: '/fortune/Receipt/Audit',
		method: 'post'
	},
	//删除收据
	setFortuneReceiptCtlDel: {
		url: '/fortune/Receipt/Del',
		method: 'post'
	},
	//收据列表-筛选状态
	getFortuneReceiptStatus: {
		url: '/fortune/Receipt/GetStatus',
		method: 'get'
	},
	//收据详情
	getFortuneReceiptDetail: {
		url: '/fortune/Receipt/GetDetail',
		method: 'get'
	},
	//收据待领取 - 收据号填写
	setFortuneReceiptRecNumber: {
		url: '/fortune/Receipt/RecNumber',
		method: 'post'
	},
	//收据核销
	setFortuneReceiptWriteOff: {
		url: '/fortune/Receipt/WriteOff',
		method: 'post'
	},
	//退收据申请
	setFortuneReceiptReturn: {
		url: '/fortune/Receipt/Return',
		method: 'post'
	},
	//导出接口
	getFortuneReceiptChangeTplItems: {
		url: '/fortune/Receipt/GetExportFields',
		method: 'get'
	},
	getFortuneReceiptChangeExportTpl: {
		url: '/fortune/Receipt/GetExportTpls',
		method: 'get'
	},
	saveFortuneReceiptChangeExportTpls: {
		url: '/fortune/Receipt/SaveExportTpls',
		method: 'post'
	},
	exportFortuneReceiptChange: {
		url: '/fortune/Receipt/Export',
		method: 'post'
	},
	//已回
	setFortuneReceiptWriteReturn: {
		url: '/fortune/Receipt/WriteReturn',
		method: 'post'
	},
}

export default quittanceUrls