/*
 * @Author: zhuyan 
 * @Date: 2021-08-06 12:08:58 
 * @Last Modified by: zhuyan
 * @Last Modified time: 2021-08-06 16:23:59
 */

const fund = {
  getMapNewOldClientList: {
    url: '/fortune/MapNewOldClient/GetList',
    method: 'get'
  },
  // 获取导出模版
  getFonsExportTpl: {
    url: '/fortune/MapNewOldClient/GetExportTpls',
    method: 'get'
  },
  // 获取导出字段
  getFonsTplItems: {
    url: '/fortune/MapNewOldClient/GetExportFields',
    method: 'get'
  },
  // 设置导出模板
  saveFonsExportTpls: {
    url: '/fortune/MapNewOldClient/SaveExportTpls',
    method: 'post'
  },
  exportFons: {
    url: '/fortune/MapNewOldClient/Export',
    method: 'post'
  },
}

export default fund