/**
 * 转款管理
 * 
 */

const fundMoneyTransmit = {
  // 转款列表
  getFundMoneyTransmitList: {
    url: '/fortune/FundMoneyTransmit/list',
    method: 'get'
  },
  fundMoneyTransmitDetail: {
    url: '/fortune/FundMoneyTransmit/detail',
    method: 'get'
  },
  apply: {
    url: '/fortune/FundMoneyTransmit/apply',
    method: 'post'
  },
  reApply: {
    url: '/fortune/FundMoneyTransmit/reApply',
    method: 'post'
  },
  audit: {
    url: '/fortune/FundMoneyTransmit/audit',
    method: 'post'
  },
  fundMoneyTransmitDelete: {
    url: '/fortune/FundMoneyTransmit/delete',
    method: 'post'
  },
  fundMoneyTransmitGetInvoiceDetailByDetailId: {
    url: '/fortune/FundMoneyTransmit/getInvoiceDetailByDetailId',
    method: 'get'
},
  fundMoneyTransmitExport:{
    url: '/fortune/FundMoneyTransmit/Export',
    method: 'post'
  },
  getFundMoneyTransmitTplItems: {
      url: '/fortune/FundMoneyTransmit/GetExportFields',
      method: 'get'
  },
  getFundMoneyTransmitExportTpl: {
      url: '/fortune/FundMoneyTransmit/GetExportTpls',
      method: 'get'
  },
  saveFundMoneyTransmitExportTpls: {
      url: '/fortune/FundMoneyTransmit/SaveExportTpls',
      method: 'post'
  },

  getTransmitInvoiceList: {
    url: '/fortune/fundMoneyTransmit/getInvoiceList',
    method: 'get'
  },
  getTransmitAccountDetailList: {
    url: '/fortune/fundMoneyTransmit/getAccountDetailList',
    method: 'get'
  },
  getClientPrincipal: {
    url: '/fortune/fundMoneyTransmit/getClientPrincipal',
    method: 'get'
  },
  // 特殊转出人
  getSpecialFromPeople: {
    url: '/fortune/fundMoneyTransmit/SpecialFromPeople',
    method: 'get'
  },
  // 特殊转入人
  getSpecialToPeople: {
    url: '/fortune/fundMoneyTransmit/SpecialToPeople',
    method: 'get'
  },
  specialApply: {
    url: '/fortune/FundMoneyTransmit/specialApply',
    method: 'post'
  },
  specialReApply: {
    url: '/fortune/FundMoneyTransmit/specialReApply',
    method: 'post'
  }
}
  
export default fundMoneyTransmit