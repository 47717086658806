/**
 * 代理商厂商id转移管理
 *
 */

const agentManufacturer = {
	//添加、修改代理商厂商ID转移
	agentManufacturerHandle: {
		url: '/fortune/AgentFirmTransfer/Handle',
		method: 'post'
	},
	//获取代理商厂商ID转移审批详情
	agentManufacturerGetDetail: {
		url: '/fortune/AgentFirmTransfer/GetDetail',
		method: 'get'
	},
	//代理商厂商ID转移审批
	agentManufacturerAudit: {
		url: '/fortune/AgentFirmTransfer/Audit',
		method: 'post'
	},
	//获取代理商厂商ID转移审批列表
	agentManufacturerGetList: {
		url: '/fortune/AgentFirmTransfer/GetList',
		method: 'get'
	},
	//获取厂商ID转移基础信息
	agentManufacturerGetBaseData: {
		url: '/fortune/AgentFirmTransfer/GetBaseData',
		method: 'get'
	},
	//获取转入代理商信息
	agentManufacturerGetFilterAgent: {
		url: '/fortune/AgentFirmTransfer/GetFilterAgent',
		method: 'get'
	},
	//获取转入用户信息
	agentManufacturerGetTransferInUser: {
		url: '/fortune/AgentFirmTransfer/GetTransferInUser',
		method: 'get'
	},
	//获取转入主体列表
	agentManufacturerGetTransferInAccount: {
		url: '/fortune/AgentFirmTransfer/GetTransferInAccount',
		method: 'get'
	},
	//获取代理商厂商ID转移筛选条件
	agentManufacturerGetFilterData: {
		url: '/fortune/AgentFirmTransfer/GetFilterData',
		method: 'get'
	},
	//获取转移客户厂商信息列表
	agentManufacturerGetTransferFirmList: {
		url: '/fortune/AgentClient/GetTransferFirmList',
		method: 'get'
	},
	//检测厂商ID转移是否重复
	agentManufacturerCheckRepeat: {
		url: '/fortune/AgentFirmTransfer/CheckRepeat',
		method: 'get'
	},
	//代理商厂商ID转移删除
	agentManufacturerDel: {
		url: '/fortune/AgentFirmTransfer/Del',
		method: 'post'
	},
	getManufacturerExportTpl: {
		url: '/fortune/AgentFirmTransfer/GetExportTpls',
		method: 'get'
	},
	exportManufacturer: {
		url: '/fortune/AgentFirmTransfer/Export',
		method: 'post'
	},
	getManufacturerTplItems: {
		url: '/fortune/AgentFirmTransfer/GetExportFields',
		method: 'get'
	},
	saveManufacturerTpl: {
		url: '/fortune/AgentFirmTransfer/SaveExportTpls',
		method: 'post'
	},
}

export default agentManufacturer