/**
 * 退款管理
 * 
 */

const refund = {
  getRefundList: {
    url: '/fortune/fundMoneyRefund/list',
    method: 'get'
  },
  getRefundDetail: {
    url: '/fortune/fundMoneyRefund/info',
    method: 'get'
  },
  getCusTomDetail: {
    url: '/fortune/fundMoneyRefund/GetCusTomInfo',
    method: 'get'
  },
  getRefundInvoiceList: {
    url: '/fortune/fundMoneyRefund/getInvoiceList',
    method: 'get'
  },
  getRefundAccountDetailList: {
    url: '/fortune/fundMoneyRefund/getAccountDetailList',
    method: 'get'
  },
  saveRefund: {
    url: '/fortune/fundMoneyRefund/save',
    method: 'post'
  },
  auditRefund: {
    url: '/fortune/fundMoneyRefund/audit',
    method: 'post'
  },
  getRefundDelete: {
    url: '/fortune/fundMoneyRefund/delete',
    method: 'post'
  },
  getRefundExportTpl: {
    url: '/fortune/fundMoneyRefund/GetExportTpls',
    method: 'get'
  },
  exportRefund: {
    url: '/fortune/fundMoneyRefund/Export',
    method: 'post'
  },
  getRefundTplItems: {
    url: '/fortune/fundMoneyRefund/GetExportFields',
    method: 'get'
  },
  saveRefundTpl: {
    url: '/fortune/fundMoneyRefund/SaveExportTpls',
    method: 'post'
  },
  getUnRefundOrderList: {
    url: '/fortune/fundMoneyRefund/getUnRefundOrderList',
    method: 'get'
  },
  // 退款上传附件补充资料
  getUnRefundSaveAttachment: {
    url: '/fortune/fundMoneyRefund/saveAttachment',
    method: 'post'
  },
  // 退款编辑凭证
  getUnRefundEditCertificate: {
    url: '/fortune/refundOrder/editCertificate',
    method: 'post'
  },
  // 分配
  setRefundNextPerson: {
    url: '/fortune/fundMoneyRefund/setNextPerson',
    method: 'post'
  },
  // 获取老oa未收款的发票
  getOaUnReceiveInvoice: {
    url: '/fortune/fundMoneyRefund/GetOaUnReceiveInvoice',
    method: 'get'
  },
  // 获取老oa认款记录
  getOaRecharge: {
    url: '/fortune/fundMoneyRefund/GetOaRecharge',
    method: 'get'
  },
  // 检测是否可以正常申请退款
  setRefundCheckApply: {
    url: '/fortune/fundMoneyRefund/checkApply',
    method: 'post'
  },
}

export default refund