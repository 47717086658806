/**
 * 考勤管理
 * 
 */

const attendance = {
  getWorkDays: {
    url: '/WorkDay/GetMonthList',
    method: 'get'
  },
  getMonthWorkDays: {
    url: '/WorkDay/GetYearList',
    method: 'get'
  },
  setWorkday: {
    url: '/WorkDay/Handle',
    method: 'post'
  },
  setAllWorkDay: {
    url: '/WorkDay/SetAll',
    method: 'post'
  },
  //请假列表
  getLeaveOutList: {
    url: '/LeaveOut/GetLeaveOutList',
    method: 'get'
  },
  //请假申请
  onLeaveOutHandle: {
    url: '/LeaveOut/Handle',
    method: 'post'
  },
  //检测忘打卡
  getCheckForget: {
    url: '/LeaveOut/CheckForget',
    method: 'get'
  },
  //产假修改时间
  onLeaveOutEditTime: {
    url: '/LeaveOut/LeaveOutEditTime',
    method: 'get'
  },
  //最近考勤列表
  getLeaveOutRecentSign: {
    url: '/LeaveOut/GetRecentSign',
    method: 'get'
  },
  //请假类型列表
  getLeaveOutAskType: {
    url: '/LeaveOut/GetAskType',
    method: 'get'
  },
  //获取请假结束日期
  getLeaveOutEndDate: {
    url: '/LeaveOut/GetEndDate',
    method: 'get'
  },
  //获取请假申请基础数据
  getLeaveOutApplyBaseData: {
    url: '/LeaveOut/GetApplyBaseData',
    method: 'get'
  },
  //请假详情
  getLeaveOutRow: {
    url: '/LeaveOut/GetRow',
    method: 'get'
  },
  //获取请假申请状态
  getLeaveOutStatus: {
    url: '/LeaveOut/GetLeaveOutStatus',
    method: 'get'
  },
  //获取请假开始时间
  getLeaveOutTimes: {
    url: '/LeaveOut/GetTimes',
    method: 'get'
  },
  //忘打卡 - 获取上一个工作日
  getWorkDayLastWorkDay: {
    url: '/WorkDay/GetLastWorkDay',
    method: 'get'
  },
  //年假 - 获取可选开始日期
  getAnualLeaveSelectDate: {
    url: '/LeaveOut/AnualLeaveSelectDate',
    method: 'get'
  },
  //请假外出审批
  onLeaveOutAudit: {
    url: '/LeaveOut/Audit',
    method: 'post'
  },
  //判断年假是否清零（用于审批驳回）
  getLeaveOutCheckAnnualIsClean: {
    url: '/LeaveOut/CheckAnnualIsClean',
    method: 'get'
  },
  //获取假期剩余天数
  getLeaveOutAskRemainNum: {
    url: '/LeaveOut/GetAskRemainNum',
    method: 'get'
  },
  //请假外出删除
  onLeaveOutDel: {
    url: '/LeaveOut/Del',
    method: 'post'
  },
  //请假导出
  getLeaveOutChangeTplItems: {
    url: '/LeaveOut/GetExportFields', 
    method: 'get',
  },
  getLeaveOutChangeExportTpl: {
    url: '/LeaveOut/GetExportTpls',
    method: 'get',
  },
  saveLeaveOutChangeExportTpls: {
    url: '/LeaveOut/SaveExportTpls',
    method: 'post',
  },
  exportLeaveOutChange: {
    url: '/LeaveOut/Export',
    method: 'post',
  },
  //全员考勤列表
  getCheckInGetAllList: {
    url: '/CheckIn/GetAllList',
    method: 'get'
  },
  getCheckInTplItems: {
    url: '/CheckIn/GetExportFields',
    method: 'get'
  },
  getCheckInExportTpl: {
    url: '/CheckIn/GetExportTpls',
    method: 'get'
  },
  getCheckInSaveExportTpls: {
    url: '/CheckIn/SaveExportTpls',
    method: 'post'
  },
  exportCheckIn: {
    url: '/CheckIn/Export',
    method: 'post'
  },
  //全员考勤列表-外出打卡
  getCheckInWxGetGoOutList: {
    url: '/CheckInWx/GetGoOutList',
    method: 'get'
  },
  getCheckInWxTplItems: {
    url: '/CheckInWx/GetExportFields',
    method: 'get'
  },
  getCheckInWxExportTpl: {
    url: '/CheckInWx/GetExportTpls',
    method: 'get'
  },
  getCheckInWxSaveExportTpls: {
    url: '/CheckInWx/SaveExportTpls',
    method: 'post'
  },
  exportCheckInWx: {
    url: '/CheckInWx/Export',
    method: 'post'
  },
  //请假类型修改
  setCheckInUpdateLeaveOut: {
    url: '/CheckIn/UpdateLeaveOut',
    method: 'post'
  },
  //修改考勤时间
  setCheckInUpdateCheckInRecord: {
    url: '/CheckIn/UpdateCheckInRecord',
    method: 'post'
  },
  //员工微信打卡数据
  getCheckInGetWxCheckInData: {
    url: '/CheckIn/GetWxCheckInData',
    method: 'get'
  },
  //考勤导入接口
  setCheckInHandle: {
    url: '/CheckIn/Handle',
    method: 'post'
  },
  //考勤统计
  getCheckInStatGetList: {
    url: '/CheckInStat/GetList',
    method: 'get'
  },
  getCheckInStatTplItems: {
    url: '/CheckInStat/GetExportFields',
    method: 'get'
  },
  getCheckInStatExportTpl: {
    url: '/CheckInStat/GetExportTpls',
    method: 'get'
  },
  getCheckInStatSaveExportTpls: {
    url: '/CheckInStat/SaveExportTpls',
    method: 'post'
  },
  exportCheckInStat: {
    url: '/CheckInStat/Export',
    method: 'post'
  },
  //汇总当月考勤
  getCheckInStatStatCurrentMonthAttendance: {
    url: '/CheckInStat/StatCurrentMonthAttendance',
    method: 'get'
  },
  //获取最新已经导入的考勤日期
  getCheckInGetLastCheckInImportDate: {
    url: '/CheckIn/GetLastCheckInImportDate',
    method: 'get'
  },
  //考勤申诉
  getAppealGetList: {
    url: '/Appeal/GetList',
    method: 'get'
  },
  //考勤申诉删除
  saveAppealDelAppeal: {
    url: '/Appeal/DelAppeal',
    method: 'post'
  },
  //考勤申诉审批
  saveAppealAudit: {
    url: '/Appeal/Audit',
    method: 'post'
  },
  // 个人考勤
  getCheckInPersonList: {
    url: '/CheckIn/GetPersonList',
    method: 'get'
  },
  setCheckInHandleNotice: {
    url: '/CheckIn/HandleNotice',
    method: 'post'
  },
  getAppealGetRow: {
    url: '/Appeal/GetRow',
    method: 'get'
  },
  getAppealBaseData: {
    url: '/Appeal/GetAppealBaseData',
    method: 'get'
  },
  saveAppealHandle: {
    url: '/Appeal/Handle',
    method: 'post'
  },
  //育儿假资格申请，育儿假计算接口
  getLeaveoutYuerscalc: {
    url: '/LeaveOut/YuerScalc',
    method: 'get'
  },
  //育儿假资格申请接口
  setLeaveoutYuerApply: {
    url: '/LeaveOut/YuerApply',
    method: 'post'
  },
  //育儿假资格申请详情接口
  getLeaveoutYuerDetail: {
    url: '/LeaveOut/YuerDetail',
    method: 'get'
  },
  //育儿假资格审批接口
  setLeaveoutYuerAudit: {
    url: '/LeaveOut/YuerAudit',
    method: 'post'
  },
}

export default attendance