/**
 * 菜单管理
 * 
 */

const menuControl = {
  //菜单排序修改
  onSysMenuEditSort: {
    url: '/SysMenu/EditSort',
    method: 'post'
  },
  onSysMenuEdit: {
    url: '/SysMenu/Edit',
    method: 'post'
  }
}

export default menuControl