/**
 * 直销订单 和 代理商订单
 * 
 */
const orderUrls = {
  //已完成订单列表-直销(已废弃)
  getOrderDirectFinishedList: {
    url   : '/fortune/OrderDirect/FinishedList',
    method: 'get'
  },
  //已完成订单列表-直销
  getOrderDirectCreditOrder: {
    url   : '/fortune/orderDirect/getCreditOrder',
    method: 'get'
  },
  //已完成订单列表-代理商
  getOrderAgentFinishedList: {
    url   : '/fortune/OrderAgent/FinishedList',
    method: 'get'
  },
  //公共-可退订单列表
  getFortuneOrderRefundList: {
    url   : '/fortune/Order/RefundList',
    method: 'get'
  },
  //直销-订单详情
  getFortuneOrderDirectDetail: {
    url   : '/fortune/OrderDirect/Detail',
    method: 'get'
  },
  //公共 根据产品/项目ID获取下一级信息
  getFortuneOrderProjectGetChildInfo: {
    url   : '/fortune/Order/ProjectGetChildInfo',
    method: 'get'
  },
  //审批中订单列表
  getOrderDirectAuditList: {
    url   : '/fortune/OrderDirect/AuditList',
    method: 'get'
  },
  //审批中订单列表-代理商
  getOrderAgentAuditList: {
    url   : '/fortune/OrderAgent/AuditList',
    method: 'get'
  },
  //负利还款前置
  beforePayBackApplie: {
    url   : '/fortune/Order/BeforePayBackApplie',
    method: 'post'
  },
  //审批订单(常规)
  onFortuneOrderFlowAduit: {
    url   : '/fortune/OrderFlow/Audit',
    method: 'post'
  },
  //审批订单【公共】提交已受理
  onFortuneOrderFlowAccept: {
    url   : '/fortune/OrderFlow/accept',
    method: 'post'
  },
  //审批订单【公共】提交提交
  onFortuneOrderFlowSubmit: {
    url   : '/fortune/OrderFlow/Submit',
    method: 'post'
  },
  //审批订单【公共】提交已生效
  onFortuneOrderFlowTakeEffect: {
    url   : '/fortune/OrderFlow/TakeEffect',
    method: 'post'
  },
  //审批订单【公共】提交分配
  onFortuneOrderFlowDistribution: {
    url   : '/fortune/OrderFlow/Distribution',
    method: 'post'
  },
  //审批订单【公共】订单转出
  onFortuneOrderFlowTurnOut: {
    url   : '/fortune/OrderFlow/turnOut',
    method: 'post'
  },
  //【直销】申请订单前资料初始化
  getFortuneOrderDirectBeforeApplie: {
    url   : '/fortune/OrderDirect/BeforeApplie',
    method: 'get'
  },
  //【公共】获取合同信息
  getFortuneOrderContract: {
    url   : '/fortune/Order/getContract',
    method: 'get'
  },
  //【公共】获取客户联系人信息
  getFortuneOrderContact: {
    url   : '/fortune/Order/getContact',
    method: 'get'
  },
  //直销提单
  onFortuneOrderDirectApplie: {
    url   : '/fortune/OrderDirect/Applie',
    method: 'post'
  },
  //【公共】获取供应商
  getFortuneOrderSupplier: {
    url   : '/fortune/Order/getSupplier',
    method: 'get'
  },
  //【公共】获取自定义资料
  getFortuneOrderAttchData: {
    url   : '/fortune/Order/GetAttchData',
    method: 'get'
  },
  //检测还款申请
  checkPayBackApplie: {
    url   : '/fortune/Order/CheckPayBackApplie',
    method: 'get'
  },
  //直销-还款申请
  onPayBackApplie: {
    url   : '/fortune/Order/payBackApplie',
    method: 'post'
  },
  //还款申请前置
  onFortuneOrderBeforePayBackApplie: {
    url   : '/fortune/Order/BeforePayBackApplie',
    method: 'get'
  },
  //【代理商】申请订单前资料初始化
  getFortuneOrderAgentBeforeApplie: {
    url   : '/fortune/OrderAgent/BeforeApplie',
    method: 'get'
  },
  //代理商提单
  onFortuneOrderAgentApplie: {
    url   : '/fortune/OrderAgent/Applie',
    method: 'post'
  },
  //代理商-订单详情
  getFortuneOrderAgentDetail: {
    url   : '/fortune/OrderAgent/Detail',
    method: 'get'
  },
  //订单删除
  onFortuneOrderDel: {
    url   : '/fortune/Order/Del',
    method: 'get'
  },
  //退单申请前置
  getFortuneOrderBeforeRefundApplie: {
    url   : '/fortune/Order/BeforeRefundApplie',
    method: 'get'
  },
  //退单申请
  getFortuneOrderRefundApplie: {
    url   : '/fortune/Order/RefundApplie',
    method: 'post'
  },
  //读取退单信息
  getFortuneOrderRefundOrderDetail: {
    url   : '/fortune/Order/GetRefundOrderDetail',
    method: 'get'
  },
  //
  onFortuneOrderSaveVendor: {
    url   : '/fortune/Order/SaveVendor',
    method: 'post'
  },
  //根据供应商，项目获取关联一级行业接口
  getIndustrysBySupplier: {
    url   : '/fortune/sysSupplier/getTopSysSupplierProjectIndustrys',
    method: 'get'
  },
  //保存厂商信息
  onSaveVendorByModify: {
    url   : '/fortune/Order/SaveVendorByModify',
    method: 'post'
  },
  //读取首页退单信息
  getOrderRefundApplieInfo: {
    url   : '/fortune/Order/GetRefundApplieInfo',
    method: 'get'
  },
  //订单申请刷新获取余额
  getOrderRefreshBalance: {
    url   : '/fortune/Order/RefreshBalance',
    method: 'get'
  },
  //直销所有订单
  getFortuneOrderAllList: {
    url   : '/fortune/OrderAll/list',
    method: 'get'
  },
  //直销所有订单导出
  getFortuneOrderAllChangeTplItems: {
    url   : '/fortune/OrderAll/GetExportFields',
    method: 'get'
  },
  getFortuneOrderAllChangeExportTpl: {
    url   : '/fortune/OrderAll/GetExportTpls',
    method: 'get'
  },
  saveFortuneOrderAllChangeExportTpls: {
    url   : '/fortune/OrderAll/SaveExportTpls',
    method: 'post'
  },
  exportFortuneOrderAllExport: {
    url   : '/fortune/OrderAll/Export',
    method: 'post'
  },
  //代理商所有订单
  getFortuneOrderChannelAllList: {
    url   : '/fortune/OrderChannelAll/list',
    method: 'get'
  },
  //直代理商所有订单导出
  getFortuneOrderChannelAllChangeTplItems: {
    url   : '/fortune/OrderChannelAll/GetExportFields',
    method: 'get'
  },
  getFortuneOrderChannelAllChangeExportTpl: {
    url   : '/fortune/OrderChannelAll/GetExportTpls',
    method: 'get'
  },
  saveFortuneOrderChannelAllChangeExportTpls: {
    url   : '/fortune/OrderChannelAll/SaveExportTpls',
    method: 'post'
  },
  exportFortuneOrderChannelAllExport: {
    url   : '/fortune/OrderChannelAll/Export',
    method: 'post'
  },
  //获取分配人列表
  getOrderDistributionList: {
    url   : '/fortune/Order/getDistribution',
    method: 'get'
  },
  //【公共】续费检查厂商ID关联客服状态
  onCheckVendorRelServicer: {
    url   : '/fortune/Order/CheckVendorRelServicer',
    method: 'get'
  },
  //订单申请页请求获取分成人列表
  getPerformanceProfitForStaff: {
    url   : '/fortune/performance/GetProfitForStaff',
    method: 'get'
  },
  // 已完成订单续费
  getVendorXudan: {
    url   : '/fortune/vendor/GetVendorXudan',
    method: 'get'
  },
  // 消耗列表-直销
  getConsumptionDirectList: {
    url   : '/fortune/OrderDirect/GetConsumptionList',
    method: 'get'
  },
  // 消耗列表-代理商
  getConsumptionAgentList: {
    url   : '/fortune/OrderAgent/GetConsumptionList',
    method: 'get'
  }
}

export default orderUrls