/**
 * 模块名称: 筛选组件（全员管理）
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useImperativeHandle } from 'react'
import api from '@/api'
import {
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  Icon,
  Modal
} from 'antd'
import BtnGroup from '../BtnGroup'
import moment from 'moment'
import './style.scss'

const FormItem = Form.Item
const { Option } = Select
const { RangePicker } = DatePicker

const WholeFilter = (props) => {
  const { formFilter, saveFilterTerm, handleOk, handleCancel, visible, locationPathname, onFilterReset, disabledAsk, cRef, section, corpId } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [department, setDepartment] = useState([])
  const [leaveOutAskType, setLeaveOutAskType] = useState([])
  const [expand, setExpand] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [filterContent, setFilterContent] = useState([])
  const [unfoldPack, setUnfoldPack] = useState(true)
  const [textUnfoldPack, setTextUnfoldPack] = useState(true)
  const [statusVal, setStatusVal] = useState('请选择')
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)


  //获取公共接口
  useEffect(() => {
    // console.log(section, corpId)
    getSelectDepartmentList() //部门
    api.getLeaveOutAskType({ limit: global.paramsLimit }).then(data => setLeaveOutAskType(data.list)) //请假类型
  }, [])

  useEffect(() => {
    // console.log(section, corpId)
    getSelectDepartmentList()
    setFieldsValue({'section': undefined})
  }, [section])
  //部门
  const getSelectDepartmentList = () => {
    api.getSelectDepartmentList(corpId.length ? { limit: global.paramsLimit, companyId: corpId } : { limit: global.paramsLimit }).then(data => {
      setDepartment(data.list)
    })
  }

  useImperativeHandle(cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeVal: () => {
      setFieldsValue({
        name: '',
        no: '',
        section: '',
        lead: '',
        ask: '',
        checkNum: '',
        time: ''
      })
    }
  }))

  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        formFilter(vals) //点击筛选提交表单的回调函数
      }
    })
  }

  //重置
  const onReset = (e) => {
    e.preventDefault()
    resetFields()
    onFilterReset()
    setStatusVal('请选择')
    setStatusValName(undefined)
  }

  const toggle = () => {
    setExpand(!expand)
    setUnfoldPack(!unfoldPack)
    setTextUnfoldPack(!textUnfoldPack)

  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        saveFilterTerm(vals) //点击保存筛选条件的回调函数
      }
    })

  }

  const HandleOk = () => {
    handleOk(filterInfoValue) //点击保存筛选弹出框内确定的回调函数
  }

  const HandleCancel = () => {
    handleCancel() //点击保存筛选弹出框内取消的回调函数
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //获取保存筛选弹框中input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: locationPathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    //选择保存筛选下拉框中的内容
    setFieldsValue({
      name: filterCont.name || filterCont.name == 0 ? filterCont.name : undefined,//eslint-disable-line
      no: filterCont.no || filterCont.no == 0 ? filterCont.no : undefined,//eslint-disable-line
      section: filterCont.section || filterCont.section == 0 ? filterCont.section : undefined,//eslint-disable-line
      lead: filterCont.lead || filterCont.lead == 0 ? filterCont.lead : undefined,//eslint-disable-line
      ask: filterCont.ask || filterCont.ask == 0 ? filterCont.ask : undefined,//eslint-disable-line
      checkNum: filterCont.checkNum || filterCont.checkNum == 0 ? filterCont.checkNum : undefined,//eslint-disable-line
      time: filterCont.time || filterCont.time == 0 ? [moment(filterCont.time[0]), moment(filterCont.time[1])] : undefined,//eslint-disable-line
    })

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: locationPathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  return (
    <>
      <div className="wholefilter-wrap">
        <div className="wholefilter-title" style={{ marginRight: '30px' }}>其他选项：</div>
        <span className="wholefilter-btn-a" onClick={toggle}>
          {textUnfoldPack ? '展开' : '收起'} <Icon type={expand ? 'up' : 'down'} />
        </span>
        <Form onSubmit={formSubmit} >
          <FormItem label="员工编号">
            {getFieldDecorator('no')(<Input placeholder="请输入员工编号" style={{ width: 200 }} />)}
          </FormItem>
          <FormItem label="公司用名">
            {getFieldDecorator('name')(<Input placeholder="请输入员工姓名" style={{ width: 200 }} />)}
          </FormItem>
          <FormItem label="部门">
            {getFieldDecorator('section')(
              <Select placeholder="全部" style={{ width: 200 }} dropdownMatchSelectWidth={false} showSearch={true} optionFilterProp="children">
                {department.map((v, i) => {
                  return section == 0 ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                })}
                
              </Select>)}
          </FormItem>
          <FormItem label="直接主管" className={unfoldPack ? "wholefilter-unfold" : ""}>
            {getFieldDecorator('lead')(<Input placeholder="请输入直接主管" style={{ width: 200 }} />)}
          </FormItem>
          <FormItem label="请假类型" className={unfoldPack ? "wholefilter-unfold" : ""} >
            {getFieldDecorator('ask')(
              <Select placeholder="全部" style={{ width: 200 }} dropdownMatchSelectWidth={false} disabled={disabledAsk}>
                {leaveOutAskType.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
              </Select>)}
          </FormItem>
          <FormItem label="考勤号" className={unfoldPack ? "wholefilter-unfold" : ""}>
            {getFieldDecorator('checkNum')(<Input placeholder="请输入考勤号" style={{ width: 200 }} />)}
          </FormItem>
          <FormItem label="日期" className={unfoldPack ? "wholefilter-date wholefilter-unfold" : "wholefilter-date"}>
            {getFieldDecorator('time')(<RangePicker allowClear={false} style={{ width: 200 }} />)}
          </FormItem>
          <div className="wholefilter-btn">
            <div className="wholefilter-dashed"></div>
            <h4 className={unfoldPack ? "wholefilter-unfold" : ""}>已保存筛选方案：</h4>
            <div className={unfoldPack ? "wholefilter-unfold" : ""}>
              <Select
                placeholder="请选择"
                value={statusVal}
                style={{ width: 160 }}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                 </Option>
                )}
              </Select>
            </div>
            <Button className={unfoldPack ? "wholefilter-unfold" : ""} onClick={onSaveFilterTerm} style={{ width: 130 }}>保存筛选条件</Button>
            {/* <div className={textUnfoldPack ? "wholefilter-group" : ""}> */}
              <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
            {/* </div> */}
            <Modal
              title="保存筛选条件"
              visible={visible}
              onOk={HandleOk}
              onCancel={HandleCancel}
            >
              <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
            </Modal>
          </div>
        </Form>

      </div>
    </>
  )
}

export default Form.create()(WholeFilter)