/**
 * 培训管理
 * 
 */

const train = {
  //培训档案列表接口
  getTrainrecordList: {
    url: '/trainRecord/GetList',
    method: 'get'
  },
  getTrainrecordCategoryList: {
    url: '/trainRecord/CategoryList',
    method: 'get'
  },
  getTrainRecordOrganizaData: {
    url: '/TrainRecord/GetOrganizaData',
    method: 'get'
  },
  TrainRecord: {
    url: '/TrainRecord/ImportExcel',
    method: 'get'
  },
  trainRecordExportTpl: {
    url: '/TrainRecord/GetExportTpls',
    method: 'get'
  },
  getTrainTplItems: {
    url: '/TrainRecord/GetExportFields',
    method: 'get'
  },
  saveTrainExportTpls: {
    url: '/TrainRecord/SaveExportTpls',
    method: 'post'
  },
  exportTrain: {
    url: '/TrainRecord/Export',
    method: 'post'
  },
  getTrainRecordImportExcel: {
    url: '/TrainRecord/ImportExcel',
    method: 'get'
  },
  getTrainEvaluationImportExcel: {
    url: '/TrainEvaluation/ImportExcel',
    method: 'get'
  },
  onTrainRecordUpdate: {
    url: '/TrainRecord/Update',
    method: 'post'
  },
  getTrainRecordCourseList: {
    url: '/TrainRecord/CourseList',
    method: 'get'
  },
  getTrainRecordDelete: {
    url: '/TrainRecord/Delete',
    method: 'get'
  },
  onTrainRecordCategoryDelete: {
    url: '/TrainRecord/CategoryDelete',
    method: 'get'
  },
  onTrainRecordCategoryOperate: {
    url: '/TrainRecord/CategoryOperate',
    method: 'post'
  },
  onTrainRecordCourseOperate: {
    url: '/TrainRecord/CourseOperate',
    method: 'post'
  },
  onTrainRecordCourseDelete: {
    url: '/TrainRecord/CourseDelete',
  },
  //讲师培训列表接口
  getTrainEvaluationList: {
    url: '/TrainEvaluation/GetList',
    method: 'get'
  },
  getTrainEvaluationTplItems: {
    url: '/TrainEvaluation/GetExportFields',
    method: 'get'
  },
  getTrainEvaluationExportTpl: {
    url: '/TrainEvaluation/GetExportTpls',
    method: 'get'
  },
  getTrainEvaluationSaveExportTpls: {
    url: '/TrainEvaluation/SaveExportTpls',
    method: 'post'
  },
  exportTrainEvaluation: {
    url: '/TrainEvaluation/Export',
    method: 'post'
  },
  //讲师评价删除
  getTrainEvaluationDelete: {
    url: '/TrainEvaluation/Delete',
    method: 'get'
  },
  //讲师评价编辑
  onTrainEvaluationUpdate: {
    url: '/TrainEvaluation/update',
    method: 'post'
  }
}

export default train