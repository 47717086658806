// 客户管理模块

const customerUrls = {
  // 获取客户列表
  getFortuneClientList: {
    url: '/fortune/Client/GetList',
    method: 'get'
  },
  // 获取客户列表筛选条件
  getClientFilterData: {
    url: '/fortune/Client/GetFilterData',
    method: 'get'
  },
  // 获取认证号码类型
  getClientVerifyTypes: {
    url: '/fortune/Client/GetVerifyTypes',
    method: 'get'
  },
  // 获取行业数据
  getClientIndustryData: {
    url: '/fortune/ClientIndustry/GetIndustryData',
    method: 'get'
  },
  // 新获取行业接口
  getSysIndustryList: {
    url: '/fortune/sysIndustry/getIndustryList',
    method: 'get'
  },
  // 获取地区数据
  getClientGetAreaData: {
    url: '/fortune/Client/GetAreaData',
    method: 'get'
  },
  // 获取认证模式数据
  getClientVerifyMode: {
    url: '/fortune/Client/GetVerifyMode',
    method: 'get'
  },
  // 添加、修改客户
  getClientVerifyHandle: {
    url: '/fortune/ClientVerify/Handle',
    method: 'post'
  },
  // 添加、修改客户名称变更
  getClientChangeHandle: {
    url: '/fortune/ClientChange/Handle',
    method: 'post'
  },
  // 获取客户名称变更详情
  getClientChangeDetail: {
    url: '/fortune/ClientChange/GetDetail',
    method: 'get'
  },
  // 获取客户详情
  getClientGetDetail: {
    url: '/fortune/Client/GetDetail',
    method: 'get'
  },
  // 客户联系人列表
  getClientContactList: {
    url: '/fortune/ClientContact/GetList',
    method: 'get'
  },
  // 删除客户联系人
  getClientContactDel: {
    url: '/fortune/ClientContact/Del',
    method: 'post'
  },
  // 获取联系人详情
  getClientContactDetail: {
    url: '/fortune/ClientContact/GetDetail',
    method: 'get'
  },
  // 添加、编辑客户联系人
  getClientContactHandle: {
    url: '/fortune/ClientContact/Handle',
    method: 'post'
  },
  // 获取客户名称变更列表
  getClientChangeGetList: {
    url: '/fortune/ClientChange/GetList',
    method: 'get'
  },
   // 客户名称变更删除
   delClientChangeDel: {
    url: '/fortune/ClientChange/Del',
    method: 'post'
  },
  // 获取客户名称变更筛选条件
  getClientChangeFilterData: {
    url: '/fortune/ClientChange/GetFilterData',
    method: 'get'
  },
  // 获取客户认证审批列表
  getClientVerifyList: {
    url: '/fortune/ClientVerify/GetList',
    method: 'get'
  },
  // 获取客户认证审批筛选条件
  getClientVerifyFilterData: {
    url: '/fortune/ClientVerify/GetFilterData',
    method: 'get'
  },
  // 获取客户认证详情
  getClientVerifyDetail: {
    url: '/fortune/ClientVerify/GetDetail',
    method: 'get'
  },
  // 客户认证审批删除
  delClientVerifyDel: {
    url: '/fortune/ClientVerify/Del',
    method: 'post'
  },
  //转户列表
  delTransferAccountDel: {
    url: '/fortune/TransferAccount/Del',
    method: 'post'
  },
  // 客户认证审批
  getClientVerifyAudit: {
    url: '/fortune/ClientVerify/Audit',
    method: 'post'
  },
  // 获取同名客户列表
  getClientVerifySameNameList: {
    url: '/fortune/ClientVerify/GetSameNameList',
    method: 'get'
  },
  // 获取我的厂商ID列表
  getMyFirmList: {
    url: '/fortune/Vendor/GetFirmIdList',
    method: 'get'
  },
  //导出接口
	getFortuneVendortTplItems: {
		url: '/fortune/Vendor/GetExportFields',
		method: 'get'
	},
	getFortuneVendortExportTpl: {
		url: '/fortune/Vendor/GetExportTpls',
		method: 'get'
	},
	saveFortuneVendorExportTpls: {
		url: '/fortune/Vendor/SaveExportTpls',
		method: 'post'
	},
	exportFortuneVendorExport: {
		url: '/fortune/Vendor/Export',
		method: 'post'
	},
  // 客户名称变更审批
  getClientChangeAudit: {
    url: '/fortune/ClientChange/Audit',
    method: 'post'
  },
  // 客户变更导出
  getClientChangeExportTpl: {
    url: '/fortune/ClientChange/GetExportTpls',
    method: 'get'
  },
  exportClientChange: {
    url: '/fortune/ClientChange/Export',
    method: 'post'
  },
  getClientChangeTplItems: {
    url: '/fortune/ClientChange/GetExportFields',
    method: 'get'
  },
  saveClientChangeLevelTpl: {
    url: '/fortune/ClientChange/SaveExportTpls',
    method: 'post'
  },
  // 厂商ID查询
  getVendorList: {
    url: '/fortune/vendor/vendorList',
    method: 'get'
  },
  // 客户名称查询
  getFindClientName: {
    url: '/fortune/Client/FindClientName',
    method: 'get'
  },
  // 分配列表
  getDirectVendorList: {
    url: '/fortune/DirectVendorDistribution/List',
    method: 'get'
  },
  // 分配列表导入
  setDirectVendorImport: {
    url: '/fortune/DirectVendorDistribution/Import',
    method: 'post'
  },
  // 分配客服筛选
  getDirectVendorFilterData: {
    url: '/fortune/DirectVendorDistribution/filterData',
    method: 'get'
  },
   // 分配获取员工列表
  getDirectVendorStaffList: {
    url: '/fortune/DirectVendorDistribution/GetStaffList',
    method: 'get'
  },
  // 分配销售
  setDirectVendorSaler: {
    url: '/fortune/DirectVendorDistribution/Saler',
    method: 'post'
  },
  // 分配客服
  setDirectVendorServicer: {
    url: '/fortune/DirectVendorDistribution/Servicer',
    method: 'post'
  },
  // 厂商信息列表
  getVendorInfoList: {
    url: '/fortune/client/ManufacturerList',
    method: 'get'
  },
  // 折扣列表
  getDiscountList: {
    url: '/fortune/Discount/DiscountList',
    method: 'get'
  },
  // 新厂商ID转移接口
  // 厂商列表接口
  getTransferVendors: {
    url: '/fortune/TransferDirectVendor/getVendors',
    method: 'get'
  },
  // 获取客服、销售、所属渠道开发接口
  getTransferStaffList: {
    url: '/fortune/TransferDirectVendor/getStaffList',
    method: 'get'
  },
  // 客户模糊搜索接口
  getTransferClientList: {
    url: '/fortune/TransferDirectVendor/getClient',
    method: 'get'
  },
  // 代理商模糊搜索接口
  getTransferDirectAgentList: {
    url: '/fortune/TransferDirectVendor/getAgent',
    method: 'get'
  },
  // 厂商id转移主体
  getTransferDirectBranchList: {
    url: '/fortune/TransferDirectVendor/GetBranchList',
    method: 'get'
  },
   // 厂商ID转移提交
   getTransferDirectVendorsubmit2: {
    url: '/fortune/TransferDirectVendor/submit2',
    method: 'post'
  }, 
  // 厂商id转移驳回重提
  getTransferDirectRepeatData: {
    url: '/fortune/TransferDirectVendor/repeatData2',
    method: 'get'
  },

  // 厂商ID转移 申请前初始化
  getBeforeApplie: {
    url: '/fortune/TransferDirectVendor/BeforeApplie',
    method: 'get'
  },
  // 获取转入信息 
  getTransferIntoTypeInfo: {
    url: '/fortune/TransferDirectVendor/GetIntoTypeInfo',
    method: 'get'
  },
  // 获取转入信息
  getTransferSelectInfo: {
    url: '/fortune/TransferDirectVendor/GetSelectInfo',
    method: 'get'
  },
  // 提交厂商ID转移
  saveTransferDirectVendor: {
    url: '/fortune/TransferDirectVendor/Submit',
    method: 'post'
  },
  // 厂商ID转移审批
  getTransferDirectVendorList: {
    url: '/fortune/TransferDirectVendor/List2',
    method: 'get'
  },
  // 厂商id审批页面详情
  getTransferDirectVendorDetail: {
    url: '/fortune/TransferDirectVendor/Detail',
    method: 'get'
  },
  // 厂商id审批页面详情
  getTransferDirectVendorDetail2: {
    url: '/fortune/TransferDirectVendor/detail2',
    method: 'get'
  },
  // 厂商ID转移审批
  transferVendorAudit: {
    url: '/fortune/TransferDirectVendor/Audit',
    method: 'post'
  },
  // 厂商ID转移审批删除
  delTransferVendorAudit: {
    url: '/fortune/TransferDirectVendor/del',
    method: 'post'
  },
  // 厂商ID修补 - 获取厂商ID信息
  getInfoByVendor: {
    url: '/fortune/vendor/GetInfoByVendor',
    method: 'get'
  },
  // 厂商ID修复 - 按类型获取员工
  getDepartmentStaff: {
    url: '/fortune/vendor/GetDepartmentStaff',
    method: 'get'
  },
  // 厂商ID修复 - 修改厂商信息
  setEditInfoByVendor: {
    url: '/fortune/vendor/EditInfoByVendor',
    method: 'post'
  },
  //根据厂商id获取合同信息
  getContractWithVendor: {
    url: '/fortune/Order/GetContractWithVendor',
    method: 'get'
  },
  // 客户申请、审批唯一码验证
  checkVerifynNumber: {
    url: '/fortune/ClientVerify/checkVerifyNumber',
    method: 'post'
  },
}

export default customerUrls
