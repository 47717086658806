/**
 * 日常办公
 * 
 */
const dailyWork = {
  //页面文档类型
  getDocTypePageTypeData: {
    url: '/DocType/PageTypeData',
    method: 'get'
  },
  //获取文档管理列表
  getDocManageDocList: {
    url: '/DocManage/DocList',
    method: 'get'
  },
  //文档置顶
  onDocManageStick: {
    url: '/DocManage/Stick',
    method: 'post'
  },
  //文档删除
  onDocManageDel: {
    url: '/DocManage/Del',
    method: 'post'
  },
  //添加、修改文档类型
  onDocTypeTypeHandle: {
    url: '/DocType/TypeHandle',
    method: 'post'
  },
  //添加、修改文档  =>  上传文档按钮
  onDocManageDocHandle: {
    url: '/DocManage/DocHandle',
    method: 'post'
  },
  //文档类型列表  分类管理列表
  getDocTypeTypeList: {
    url: '/DocType/TypeList',
    method: 'get'
  },
  //文档管理编辑详情  编辑上传文档
  onDocManageEditDocRow: {
    url: '/DocManage/EditDocRow',
    method: 'get'
  },
  //获取制度列表
  getCorpRuleRuleList: {
    url: '/CorpRule/RuleList',
    method: 'get'
  },
  //获取制度列表
  onCorpRuleDel: {
    url: '/CorpRule/Del',
    method: 'post'
  },
  //添加、修改公司制度
  onCorpRuleruleHandle: {
    url: '/CorpRule/ruleHandle',
    method: 'post'
  },
  //公司制度详情
  getCorpRuleGetEditRow: {
    url: '/CorpRule/GetEditRow',
    method: 'get'
  },
  //获取公司列表  =>  推送范围
  getCorpRuleCompanyList: {
    url: '/CorpRule/GetCompanyList',
    method: 'get'
  },

  //日常办公审批列表
  getOfficeWorkGetList: {
    url: '/OfficeWork/GetList',
    method: 'get'
  },
  //日常办公模板列表
  getOfficeTemplateGetList: {
    url: '/OfficeTemplate/GetList',
    method: 'get'
  },
  //日常办公模板删除
  onOfficeTemplateDel: {
    url: '/OfficeTemplate/Del',
    method: 'post'
  },
  //日常办公添加、修改
  onOfficeWorkHandle: {
    url: '/OfficeWork/Handle',
    method: 'post'
  },
  //模糊搜索员工信息
  onOfficeWorkGetStaffByName: {
    url: '/OfficeWork/GetStaffByName',
    method: 'get'
  },
  //日常办公审批取消申请
  onOfficeWorkCancel: {
    url: '/OfficeWork/Cancel',
    method: 'post'
  },
  //日常办公审批详情
  getOfficeWorkGetRow: {
    url: '/OfficeWork/GetRow',
    method: 'get'
  },
  //日常办公审批操作
  onOfficeWorkAudit: {
    url: '/OfficeWork/Audit',
    method: 'post'
  },
  //获取页面日常办公审批状态
  getOfficeWorkGetPageStatus: {
    url: '/OfficeWork/GetPageStatus',
    method: 'get'
  },
  //添加、修改日常办公模板
  onOfficeTemplateHandle: {
    url: '/OfficeTemplate/Handle',
    method: 'post'
  },
  //日常办公模板详情
  getOfficeTemplateGetRow: {
    url: '/OfficeTemplate/GetRow',
    method: 'get'
  },
}

export default dailyWork