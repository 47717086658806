/**
 * 审批流
 * 
 */

const procedure = {
  getProcedureItems: {
    url: '/procedure/GetWorksIdListInTmpl',
    method: 'get'
  },
  getProcedureList: {
    url: '/procedure/getTmpls',
    method: 'get'
  },
  getProcedureTemp: {
    url: '/procedure/getTmpl',
    method: 'get'
  },
  getProcedureDetails: {
    url: '/procedure/getWork',
    method: 'get'
  },
  createProcedure: {
    url: '/procedure/createWorkLink',
    method: 'post'
  },
  onResetProcedure: {
    url: '/procedure/resetWorkLink',
    method: 'post'
  },
  onDeleteProcedure: {
    url: '/procedure/delLink',
    method: 'post'
  },
  onSetNodeConfig: {
    url: '/procedure/nodeSetConfig',
    method: 'post'
  },
  onSetUnitConfig: {
    url: '/procedure/linkSetConfig',
    method: 'post'
  },
  onSavaCorpRange: {
    url: '/procedure/setCompanies',
    method: 'post'
  },
  onSetNodeStatus: {
    url: '/procedure/nodeSetSkip',
    method: 'post'
  }
}

export default procedure