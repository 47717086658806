/**
 * 业绩调整
 *
 */

const performanceAdjustment = {
  //已完成订单列表-直销
  perfAdjList: {
    url: '/fortune/Performance/GetList',
    method: 'get'
  },
  // 个人业绩列表统计
  getPerformanceListStat: {
    url: '/fortune/Performance/ListStat',
    method: 'get'
  },
  //个人业绩列表
  perfProsonList: {
    url: '/fortune/Performance/List',
    method: 'get'
  },
  //
  perfAdjListFilter: {
    url: '/fortune/Performance/GetAllotFilterData',
    method: 'get'
  },
  perfPersonListFilter: {
    url: '/fortune/Performance/filterFields',
    method: 'get'
  },
  //调整申请
  perfAdjApplie: {
    url: '/fortune/Performance/Handle',
    method: 'post'
  },
  //申请基础信息
  perfBaseData: {
    url: '/fortune/Performance/GetModifyBaseData',
    method: 'get'
  },
  //审批
  perfAdjAudit: {
    url: '/fortune/Performance/Audit',
    method: 'post'
  },
  //业绩调整详情
  perfAdjDetail: {
    url: '/fortune/Performance/GetDetail',
    method: 'get'
  },
  //历史记录
  perfAdjHistory: {
    url: '/fortune/Performance/GetAllotRecord',
    method: 'get'
  },
  //删除
  perfAdjDel: {
    url: '/fortune/Performance/Del',
    method: 'post'
  },
  //导出接口
  getPerfAdjTplItems: {
    url: '/fortune/Performance/GetExportFields',
    method: 'get'
  },
  getPerfAdjExportTpl: {
    url: '/fortune/Performance/GetExportTpls',
    method: 'get'
  },
  savePerfAdjExportTpls: {
    url: '/fortune/Performance/SaveExportTpls',
    method: 'post'
  },
  exportPerfAdj: {
    url: '/fortune/Performance/Export',
    method: 'post'
  },
  //个人业绩列表
  getPerformanceConsumList: {
    url: '/fortune/PerformanceConsum/list',
    method: 'get'
  },
  //导出接口(消耗)
  getPerfConsumTplItems: {
    url: '/fortune/PerformanceConsum/GetExportFields',
    method: 'get'
  },
  getPerfConsumExportTpl: {
    url: '/fortune/PerformanceConsum/GetExportTpls',
    method: 'get'
  },
  savePerfConsumExportTpls: {
    url: '/fortune/PerformanceConsum/SaveExportTpls',
    method: 'post'
  },
  exportPerfConsumAdj: {
    url: '/fortune/PerformanceConsum/Export',
    method: 'post'
  },
  // 消耗导入列表搜索
  getConsumptionFields: {
    url: '/fortune/Consumption/FilterFields',
    method: 'get'
  },
  // 消耗导入列表
  getConsumptionGetList: {
    url: '/fortune/Consumption/GetList',
    method: 'get'
  },
  // 消耗导入列表列表导出接口
  getConsumptionChangeTplItems: {
    url: '/fortune/Consumption/GetExportFields',
    method: 'get',
  },
  getConsumptionChangeExportTpl: {
    url: '/fortune/Consumption/GetExportTpls',
    method: 'get',
  },
  saveConsumptionChangeExportTpls: {
    url: '/fortune/Consumption/SaveExportTpls',
    method: 'post',
  },
  exportConsumptionChange: {
    url: '/fortune/Consumption/Export',
    method: 'post',
  },
  // 消耗导入列表里跳转
  getConsumptionDetailList: {
    url: '/fortune/Consumption/DetailList',
    method: 'get'
  },
}

export default performanceAdjustment