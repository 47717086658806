/**
 * 到款管理
 * 
 */

const fundMoneyBack = {
  fundMoneyBackList: {
    url: '/fortune/fundMoneyBack/list',
    method: 'get'
  },
  fundMoneyGetMoneyBackListForClaim: {
    url: '/fortune/fundMoneyBack/GetMoneyBackListForClaim',
    method: 'get'
  },
  fundMoneyBackAdd: {
    url: '/fortune/fundMoneyBack/add',
    method: 'post'
  },
  fundMoneyBackEdit: {
    url: '/fortune/fundMoneyBack/edit',
    method: 'post'
  },
  fundMoneyBackImport: {
    url: '/fortune/fundMoneyBack/import',
    method: 'post'
  },
  fundMoneyBackDelete: {
    url: '/fortune/fundMoneyBack/delete',
    method: 'post'
  },
  fundMoneyBackUnbindMoneyBack: {
    url: '/fortune/fundMoneyBack/UnbindToMoneyBack',
    method: 'post'
  },
  fundMoneyBackFinancialRecognition: {
    url: '/fortune/fundMoneyBack/financialRecognition',
    method: 'post'
  },
  fundMoneyBackDetail: {
    url: '/fortune/fundMoneyBack/detail',
    method: 'get'
  },
  fundMoneyBackGetAllType: {
    url: '/fortune/fundMoneyBack/GetAllType',
    method: 'post'
  },
  fundMoneyBackGetClaimItemsOfMoneyBack: {
    url: '/fortune/fundMoneyBack/GetClaimItemsOfMoneyBack',
    method: 'get'
  },
  fundMoneyBackExport: {
    url: '/fortune/fundMoneyBack/Export',
    method: 'post'
  },
  getFundMoneyBackTplItems: {
    url: '/fortune/fundMoneyBack/GetExportFields',
    method: 'get'
  },
  getFundMoneyBackExportTpl: {
    url: '/fortune/fundMoneyBack/GetExportTpls',
    method: 'get'
  },
  saveFundMoneyBackExportTpls: {
    url: '/fortune/fundMoneyBack/SaveExportTpls',
    method: 'post'
  },
  fundMoneyBackGetPayType: {
    url: '/fortune/fundMoneyBack/getPayType',
    method: 'get'
  },
  fundMoneyBackGetAllowCompanyList: {
    url: '/fortune/fundMoneyBack/GetAllowCompanyList',
    method: 'get'
  },
}

export default fundMoneyBack