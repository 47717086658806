/**
 * 模块名称: 按钮组
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Button } from 'antd'
import './style.scss'

const BtnGroup = ({ onCancel, onConfirm,disabled, cancelName = '取消', confirmName = '确认', left, right, loading, cancelDisabled, style }) => (
  <div className={`btn-group ${left ? 'to-left' : right ? 'to-right' : ''}`} style={style}>
    <Button onClick={onCancel} disabled={cancelDisabled}>{cancelName}</Button>
    <Button
      onClick={onConfirm}
      htmlType="submit"
      type="primary"
      loading={loading}
      disabled={disabled}
    >
      {confirmName}
    </Button>
  </div>
)

export default BtnGroup