/*
 * @Author: zhuyan 
 * @Date: 2025-02-08
 */

const device = {
  // 保存vr设备
  saveVrDevice: {
    url: '/fortune/vrDevice/saveDevice',
    method: 'post'
  },
  // 所有员工列表
  getAllStaffSimpleList: {
    url: '/fortune/staff/getAllStaffSimpleList',
    method: 'get'
  },
  // 库存列表
  getDdevicePageList: {
    url: '/fortune/vrDevice/devicePageList',
    method: 'get'
  },
  // 库存列表筛选项
  getDeviceQueryData: {
    url: '/fortune/vrDevice/getDeviceQueryData',
    method: 'get'
  },
  // 获取所有部门
  getAllDepartmentList: {
    url: '/department/getAllDepartmentList',
    method: 'get'
  },
  // 获取指定部门的员工列表
  getStaffListByDepartmentId: {
    url: '/fortune/staff/getStaffListByDepartmentId',
    method: 'get'
  },
  // 获全选所用id
  getAllDeviceSimpleList: {
    url: '/fortune/vrDevice/getAllDeviceSimpleList',
    method: 'get'
  },
  // 导入
  getImportDeviceImportExcel: {
    url: '/fortune/vrDevice/importDevice',
    method: 'get'
  },
  // 设备详情
  getDeviceDetail: {
    url: '/fortune/vrDevice/deviceDetail',
    method: 'get'
  },
  // 根据设备ID获取sn码
  getSnCodeByDeviceId: {
    url: '/fortune/vrDevice/getSnCodeByDeviceId',
    method: 'get'
  },
  // 借出申请
  deviceBorrowApply: {
    url: '/fortune/vrDeviceBorrowApplication/borrowApply',
    method: 'post'
  },
  // 归还申请
  deviceReturnApply: {
    url: '/fortune/vrDeviceReturnApplication/returnApply',
    method: 'post'
  },
  // 维修申请
  deviceRepairApply: {
    url: '/fortune/vrDeviceRepairApplication/repairApply',
    method: 'post'
  },
  // 报废申请
  deviceDisposalApply: {
    url: '/fortune/vrDeviceDisposalApplication/disposalApply',
    method: 'post'
  },
  // 设备历程
  getVrDeviceProcessList: {
    url: '/fortune/VrDeviceProcess/List',
    method: 'get'
  },
  // 维修完成申请
  deviceRepairFinishApply: {
    url: '/fortune/vrDeviceRepairApplication/repairFinish',
    method: 'post'
  },
  // 维修详情
  getRepairDetail: {
    url: '/fortune/vrDeviceRepairApplication/repairDetail',
    method: 'get'
  },
  // 借出详情
  getBorrowDetail: {
    url: '/fortune/vrDeviceBorrowApplication/borrowDetail',
    method: 'get'
  },
  // 归还详情
  getReturnDetail: {
    url: '/fortune/vrDeviceReturnApplication/returnDetail',
    method: 'get'
  },
  // 报废详情
  getDisposalDetail: {
    url: '/fortune/vrDeviceDisposalApplication/disposalDetail',
    method: 'get'
  },
  // 设备统计
  getVrDeviceStatisticsList: {
    url: '/fortune/VrDeviceStatistics/List',
    method: 'get'
  },
  // 设备审核
  getVrDeviceProcedureList: {
    url: '/fortune/VrDeviceProcedure/List',
    method: 'get'
  },
  // 审核页面搜索选项
  getVrDeviceProcedureQueryData: {
    url: '/fortune/VrDeviceProcedure/QueryData',
    method: 'get'
  },
  // 审核页面审批
  postVrDeviceProcedureAudit: {
    url: '/fortune/VrDeviceProcedure/Audit',
    method: 'post'
  },
  // 导出
  getVrDeviceExportTpl: {
    url: '/fortune/vrDevice/GetExportTpls',
    method: 'get'
  },
  getVrDeviceTplItems: {
    url: '/fortune/vrDevice/GetExportFields',
    method: 'get'
  },
  getVrDeviceSaveExportTpls: {
    url: '/fortune/vrDevice/SaveExportTpls',
    method: 'post'
  },
  getVrDeviceExport: {
    url: '/fortune/vrDevice/Export',
    method: 'post'
  },
}

export default device