
/**
 * 模块名称: 个人中心修改密码
 * @author zhuyan@372163.com
 */
import React from 'react'
import { Icon, Form, Input } from 'antd'
import BtnGroup from '../BtnGroup'

const FormItem = Form.Item
const centerPassword = ({onCancelPassEdit, form, onPassSave}) => {
  const { getFieldDecorator, validateFields } = form

  const onSetPass = () => {

  }

  const onPassConfirm = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if(!err) {
        onPassSave({
          oldPwd: vals.oldPwd,
          passWord: vals.password,
          rePassWord: vals.repassword
        })
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }
  return (
    <div>
       <Form onSubmit={onSetPass}  {...formItemLayout}>
          <FormItem label="原登录密码">
            {getFieldDecorator('oldPwd', {
              rules: [{ required: true, message: '请输入原登录密码' }]
            })(
              <Input.Password
                // value={pasVal}
                // onChange={this.inputpasValChange}
                prefix={<Icon type="shopping"
                style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                placeholder='登录密码'
              />
            )}
          </FormItem>
          <FormItem label="新登录密码">
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: '密码不能为空'
              }, {
                pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])^[0-9A-Za-z\S]{7,20}$/, message: '7-20位字符，不能为纯数字，不能含有空格，必须同时包含大小写字母、数字'
              }],
            })(
            <span>
              <Input.Password
                className="own-Input-one"
                // value={pasVal}
                // onChange={this.inputpasValChange}
                prefix={<Icon type="shopping"
                style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                placeholder='登录密码'
              />
            </span>
            )}
          </FormItem>
          <FormItem label="登录密码">
            <span className="one_gap">
              {getFieldDecorator('repassword', {
                rules: [{
                  required: true, message: '密码不能为空'
                }, {
                  pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])^[0-9A-Za-z\S]{7,20}$/, message: '7-20位字符，不能为纯数字，不能含有空格，必须同时包含大小写字母、数字'
                }],
              })(
              <span>
                <Input.Password
                  className="own-Input-one"
                  // value={repasVal}
                  // onChange={this.inputrepasValChange}
                  prefix={<Icon type="shopping" style={{ color: 'rgba(0,0,0,.25)', display: 'inline-block' }} />}
                  placeholder='再次输入登录密码'
                />
              </span>
              )}
            </span>
          </FormItem>
          <BtnGroup right onConfirm={onPassConfirm} onCancel={() => onCancelPassEdit()} />
        </Form>
    </div>
  )
}

export default Form.create()(centerPassword)
