
/*
 * @Description: 头部公共筛选封装
 * @Date: 2025-02-06 09:01:38
 */
import React, { Component } from 'react'
import { Form, Button, Row, Col } from 'antd'
import './index.scss'

const pageIndex = 1

class HeaderSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAll: true
    }
  }

  componentDidMount() {
    const { datas, form } = this.props
    if (datas) {
      form.setFieldsValue(datas)
    }
  }

  submit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.page = pageIndex
        Object.entries(values).forEach(([key, value]) => {
          if (Array.isArray(value) && value.length === 1 && value[0] === 'select-all') {
            delete values[key]
          }
          // 如果数组中包含 'select-all'，只过滤掉数组中的 'select-all'
          else if (Array.isArray(value) && value.includes('select-all')) {
            values[key] = value.filter(item => item !== 'select-all')
            // 如果过滤后数组为空，删除整个键值对
            if (values[key].length === 0) {
              delete values[key]
            }
          }
          // 过滤掉 undefined、null 或单独的 'select-all'
          else if ([undefined, null, 'select-all'].includes(value)) {
            delete values[key]
          } else if (key.startsWith('range:')) {
            const suffix = key.split(':')[1]
            values[`${suffix}Min`] = value[0].format('YYYY-MM-DD')
            values[`${suffix}Max`] = value[1].format('YYYY-MM-DD')
            delete values[key]
          } else if (key.startsWith('rangeSE:')) { // Start-End
            const suffix = key.split(':')[1]
            values[`${suffix}Start`] = value[0].format('YYYY-MM-DD')
            values[`${suffix}End`] = value[1].format('YYYY-MM-DD')
            delete values[key]
          }
        })
        this.props.getList(values)
      }
    })
  }

  setFieldsValue = (name, value) => {
    this.props.form.setFieldsValue({ [name]: value })
  }

  onReset = () => {
    const { form, resetParams, getList } = this.props
    form.resetFields()
    resetParams()
    getList()
  }

  render() {
    const { search = [], form, inOnLine = false, hasShowAll = true } = this.props
    const { getFieldDecorator } = form
    const { showAll } = this.state

    return (
      <div className="header-search-container">
        <Form onSubmit={this.submit}>
          <div style={{ overflow: 'hidden', height: showAll ? 'auto' : '48px' }}>
            <Row gutter={16} style={{ minHeight: '48px', display: 'flex', flexWrap: 'wrap' }}>
              {search.map(({ name, initialValue, label, component }) => {
                const isRangePicker = name.includes('range')
                return (
                  <Col
                    xs={24} sm={12} md={8} lg={isRangePicker ? 8 : 6} xl={isRangePicker ? 8 : 6}
                    key={name}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Form.Item
                      label={label}
                      labelCol={{ span: isRangePicker ? 6 : 8 }}
                      wrapperCol={{ span: isRangePicker ? 18 : 16 }}
                      style={{ marginBottom: 8, width: '100%' }}
                    >
                      {getFieldDecorator(name, { initialValue })(component)}
                    </Form.Item>
                  </Col>
                )
              })}
              {inOnLine && (
                <Col xs={24} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <Button onClick={this.onReset}>重置</Button>
                  <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>筛选</Button>
                </Col>
              )}
            </Row>
          </div>
          {!inOnLine && (
            <div className="search-btn-super">
              <Button onClick={this.onReset}>重置</Button>
              <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>筛选</Button>
              {hasShowAll && (
                <span className="show-all-control" onClick={() => this.setState(prev => ({ showAll: !prev.showAll }))}>
                  {showAll ? '收起' : '展开'}
                </span>
              )}
            </div>
          )}
        </Form>
      </div>
    )
  }
}

// 通过 Form.create() 绑定表单
export default Form.create({
  onFieldsChange: (props, changedFields, allFields) => props.onSearchFieldsChange?.(changedFields, allFields)
})(HeaderSearch)
