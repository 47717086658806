import { createSlice } from "redux-starter-kit"

const menuSlice = createSlice({
  slice: 'menuList',
  initialState: [],
  reducers: {
    setMenuList(state, action) {
      return action.payload
    }
  }
})

const { actions, reducer } = menuSlice
export const { setMenuList } = actions
export default reducer
   