//供应商管理模块 => 合同内容确认
const supplierContract = {
  //获取供应商合同申请列表 (支持条件筛选)
  getSupplierContractList: {
    url: '/fortune/SupplierContract/List',
    method: 'get'
  },
  //删除供应商合同申请
  setSupplierContractDelete: {
    url: '/fortune/SupplierContract/Delete',
    method: 'post'
  },
  //保存供应商合同申请申请
  setSupplierContractSave: {
    url: '/fortune/SupplierContract/save',
    method: 'post'
  },
  //获取供应商合同申请详情
  getSupplierContractInfo: {
    url: '/fortune/SupplierContract/Info',
    method: 'get'
  },
  //供应商合同申请审批
  setSupplierContractAudit: {
    url: '/fortune/SupplierContract/Audit',
    method: 'post'
  },
  //供应商合同申请补充资料
  setSupplierContractSaveAttachment: {
    url: '/fortune/SupplierContract/SaveAttachment',
    method: 'post'
  }
}

export default supplierContract