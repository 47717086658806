import { createSlice } from "redux-starter-kit"

const companyListSlice = createSlice({
  slice: 'companyList',
  initialState: [],
  reducers: {
    setCompanyList(state, action) {
      return action.payload
    }
  }
})

const { actions, reducer } = companyListSlice
export const { setCompanyList } = actions
export default reducer