/**
 * 业绩管理/消耗业绩产品配置
 * 
 */

const productSet = {
  //获取业绩产品配置详情
  getPerformanceProductConfigDetail: {
    url: '/fortune/PerformanceProductConfig/GetDetail',
    method: 'get'
  },
  //获取业绩产品配置列表
  getPerformanceProductConfigList: {
    url: '/fortune/PerformanceProductConfig/GetList',
    method: 'get'
  },
  //业绩产品配置添加、修改
  getPerformanceProductConfigHandle: {
    url: '/fortune/PerformanceProductConfig/Handle',
    method: 'post'
  },
  //获取业绩产品配置操作记录
  getPerformanceProductConfigLogList: {
    url: '/fortune/PerformanceProductConfigLog/GetList',
    method: 'get'
  },
  //获取项目、产品列表
  getProjectOrProduct: {
    url: '/fortune/PerformanceProductConfig/GetProjectOrProduct',
    method: 'get'
  },
  //状态变更
  getPerformanceProductConfigChangeStatus: {
    url: '/fortune/PerformanceProductConfig/ChangeStatus',
    method: 'post'
  }
}
export default productSet