/**
 * 待结算列表
 *
 */

 const toBeSettledUrls = {
	//退款订单列表
	getRefundOrderList: {
		url: '/fortune/refundOrder/getList',
		method: 'get'
	},
    //退款订单编辑
    setRefundOrderEdit: {
		url: '/fortune/refundOrder/edit',
		method: 'post'
	},
    //退款订单结算
    setRefundOrderSettlement: {
		url: '/fortune/refundOrder/settlement',
		method: 'post'
	},
    //退款订单付款失败
    setRefundOrderPaymentFailed: {
		url: '/fortune/refundOrder/paymentFailed',
		method: 'post'
	},
    //退款订单类型
    getRefundOrderType: {
		url: '/fortune/refundOrder/getRefundOrderType',
		method: 'get'
	},
    //退款订单状态
    getRefundOrderStatus: {
		url: '/fortune/refundOrder/getRefundOrderStatus',
		method: 'get'
	},
	//费用订单列表
    getOfficeCostManagementForNotPayFinished: {
		url: '/OfficeCostManagement/GetListForNotPayFinished',
		method: 'get'
	},
	//费用订单=>收款方信息修改
    setOfficeCostManagementEditPayee: {
		url: '/OfficeCostManagement/EditPayee',
		method: 'post'
	},
	//费用订单=>结算
    setOfficeCostManagementSettlement: {
		url: '/OfficeCostManagement/Settlement',
		method: 'post'
	},
	//费用订单=>付款失败
    setOfficeCostManagementPayFail: {
		url: '/OfficeCostManagement/payFail',
		method: 'post'
	},
	//内部往来款待结算列表接口
	getInnerFundTransferWaitCloseList: {
		url: '/InnerFundTransfer/waitCloseList',
		method: 'get'
	},
	//待支付财务结算接口
	setInnerFundTransferClose: {
		url: '/InnerFundTransfer/close',
		method: 'post'
	},
	//财务终止付款接口
	setInnerFundTransferFail: {
		url: '/InnerFundTransfer/fail',
		method: 'post'
	},
}

export default toBeSettledUrls