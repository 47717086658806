/**
 * 模块名称: 布局组件
 * @author xuzhongyuan@372163.com
 */

import React, { Component } from 'react'
import { Layout, Icon, Tooltip } from 'antd'
import './assets/style.scss'
import loading from '@/global/images/loading.svg'
import api from '@/api'
import Menu from './Menu'
import { Link } from 'react-router-dom'
import logoImg from '@/global/images/logo_red.png'
import Header from './Header'
import Breadcrumb from './Breadcrumb'
import { connect } from 'react-redux'
import { reloadStart, reloadEnd } from '@/global/reducer/reload'
import { setUserInfo } from '@/global/reducer/userInfo'
import { setMenuList } from '@/global/reducer/menuList'

// 测试数据
// import { menuData } from './menuData'

const { Sider, Content, Footer } = Layout

class LayoutComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      userinfo: {},
      openKeys: [],
      selectedKeys: [props.location.pathname],
      showToTop: false
    }
  }

  componentDidMount() {
    // console.log('Layout props ==>', this.props)
    this.setOpenKeys()
    this.getMenu()
    this.getUserInfo()
    window.onresize = () => {
      if (window.outerWidth <= 1100 && !this.state.collapsed) {
        this.setState({ collapsed: true })
      }
      else if (window.outerWidth > 1100 && this.state.collapsed) {
        this.setState({ collapsed: false })
      }
    }
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 100) {
        this.setState({ showToTop: true })
      }
      if (document.documentElement.scrollTop === 0) {
        this.setState({ showToTop: false })
      }
    }

    if (window.outerWidth <= 1100) {
      this.setState({ collapsed: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const pathname = this.props.location.pathname
    if (prevProps.location.pathname !== pathname) {
      this.setState({ selectedKeys: [pathname] })
      this.setOpenKeys()
    }
    if (this.props.reload && prevProps.reload !== this.props.reload) {
      this.getUserInfo().then(() => this.props.reloadEnd())
    }
  }

  componentWillUnmount() {
    window.onresize = null
    window.onscroll = null
  }

  getUserInfo = () => {
    return api.getUser().then(userinfo => {
      this.setState({ userinfo })
      this.props.setUserInfo(userinfo)
    })
  }

  getMenu = () => {
    return api.getMenu().then(menu => {
      this.props.setMenuList(menu)
    })
  }

  onToggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  // 退出登录
  onLogout = () => {
    api.logout().then(() => {
      localStorage.removeItem('token')
      this.props.history.push('/')
    })
  }

  setOpenKeys = () => {
    const { pathname } = this.props.location
    const pathList = pathname.split('/').filter(item => item)
    const openKeys = pathList.map((item, i) => `/${pathList.slice(0, i + 1).join('/')}`)
    if (openKeys.length === 0) {
      openKeys.push(pathname)
    }
    this.setState({ openKeys })
  }

  onReturnHome = () => {
    this.setState({
      openKeys: [],
      selectedKeys: []
    })
  }

  onOpenMenu = (openKeys) => {
    this.setState({ openKeys })
  }

  onSelectMenu = (d) => {
    this.setState({ selectedKeys: d.selectedKeys })
  }

  returnTop = () => {
    document.documentElement.scrollTop = 0
  }

  render() {
    const {
      collapsed,
      userinfo,
      openKeys,
      selectedKeys,
      showToTop
    } = this.state
    
    const { 
      children, 
      location, 
      config 
    } = this.props

    return (
      <Layout className="layout">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" onClick={this.onReturnHome}>
            <Link to="/index" className="logo-link">
              <img className="sys-logo" src={config.syslogo || logoImg} alt="logo" />
              {collapsed ? null : <span className="sys-name">{config.sysname || '易企信管理系统'}</span>}
            </Link>
          </div>
          <Menu
            data={this.props.menuList}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenMenu={this.onOpenMenu}
            onSelectMenu={this.onSelectMenu}
          />
        </Sider>
        <Layout>
          <Header
            collapsed={collapsed}
            onToggle={this.onToggle}
            onLogout={this.onLogout}
            userinfo={userinfo}
          />
          <Content>
            <Breadcrumb menu={this.props.menuList} location={location} />
            <div className="layout-main">{children}</div>
            <img className="global-loading" src={loading} alt="" />
          </Content>
          <Footer>北京全时天地在线网络信息股份有限公司</Footer>
        </Layout>
        <div className="flot">
          {location.pathname === '/index' && config.qrcode === '1'
          ? <div className="item qrcode-wrap">
            <Icon type="qrcode" />
            <img src={config.qrcodeimg} className="qrcode-image" alt="二维码" />
          </div> : null }
          {location.pathname === '/index' 
          ? <Link to='/helplist'>
              <Tooltip title="帮助中心" placement="left">
                <div className="item">
                  <Icon type="question" style={{color:'#fff'}} />
                </div>
              </Tooltip>
            </Link>
           : null }
          {showToTop ? <div className="item" onClick={this.returnTop}><Icon type="to-top" /></div> : null}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  reload: state.reload,
  config: state.config,
  menuList: state.menuList
})

const mapDispatchToProps = { reloadStart, reloadEnd, setUserInfo, setMenuList }

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComp)