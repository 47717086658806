/**
 * 合同票据以及协议管理
 * 
 */

const protocolUrls = {
  //补充协议列表
  getFortuneSupplyAgreementList: {
    url: '/fortune/SupplyAgreement/GetList',
    method: 'get'
  },
  //补充协议_状态筛选
  getFortuneSupplyAgreementStatus: {
    url: '/fortune/SupplyAgreement/GetStatus',
    method: 'get'
  },
  //补充协议申请
  getFortuneSupplyAgreementApply: {
    url: '/fortune/SupplyAgreement/apply',
    method: 'post'
  },
  //添加执行
  getFortuneSupplyAgreementExecute: {
    url: '/fortune/SupplyAgreement/Execute',
    method: 'post'
  },
  //协议补充
  getFortuneSupplyAgreementSupply: {
    url: '/fortune/SupplyAgreement/Supply',
    method: 'post'
  },
  //删除补充协议
  getFortuneSupplyAgreementDel: {
    url: '/fortune/SupplyAgreement/Del',
    method: 'post'
  },
  //修改执行
  getFortuneSupplyAgreementUpdateExec: {
    url: '/fortune/SupplyAgreement/UpdateExec',
    method: 'post'
  },
  //确认执行
  getFortuneSupplyAgreementConfirmExec: {
    url: '/fortune/SupplyAgreement/ConfirmExec',
    method: 'post'
  },
  //补充协议审批
  setFortuneSupplyAgreementAudit: {
    url: '/fortune/SupplyAgreement/Audit',
    method: 'post'
  },
  //修改结算提醒
  getFortuneSupplyAgreementSettleRemind: {
    url: '/fortune/SupplyAgreement/SettleRemind',
    method: 'post'
  },
  //补充单号
  getFortuneSupplyAgreementAddOrder: {
    url: '/fortune/SupplyAgreement/AddOrder',
    method: 'post'
  },
  //补充协议_根据合同号获取项目信息
  getFortuneSupplyAgreementProjects: {
    url: '/fortune/SupplyAgreement/GetProjects',
    method: 'get'
  },
  //补充协议_获取客户信息
  getFortuneSupplyAgreementCustomerInfo: {
    url: '/fortune/SupplyAgreement/GetCustomerInfo',
    method: 'get'
  },
  //补充协议详情
  getFortuneSupplyAgreementDetail: {
    url: '/fortune/SupplyAgreement/GetDetail',
    method: 'get'
  },
  //执行详情
  getFortuneSupplyAgreementExecuteDetail: {
    url: '/fortune/SupplyAgreement/ExecuteDetail',
    method: 'get'
  },
  //说明
  getFortuneContractExplain: {
    url: '/fortune/Contract/Explain',
    method: 'get'
  },
  //协议补充-反显
  getFortuneSupplyAgreementOrderInfo: {
    url: '/fortune/SupplyAgreement/GetOrderInfo',
    method: 'get'
  },
  //导出接口
  getFortuneSupplyAgreementChangeTplItems: {
    url: '/fortune/SupplyAgreement/GetExportFields',
    method: 'get'
  },
  getFortuneSupplyAgreementChangeExportTpl: {
    url: '/fortune/SupplyAgreement/GetExportTpls',
    method: 'get'
  },
  saveFortuneSupplyAgreementChangeExportTpls: {
    url: '/fortune/SupplyAgreement/SaveExportTpls',
    method: 'post'
  },
  exportFortuneSupplyAgreementChange: {
    url: '/fortune/SupplyAgreement/Export',
    method: 'post'
  }
}

export default protocolUrls