/**
 * 日志管理
 * 
 */

const managementUrls = {
  //邮件日志
  getLogMailList: {
    url: '/Log/MailList',
    method: 'get'
  },
  //邮件详情
  getLogMailDetail: {
    url: '/Log/MailDetail',
    method: 'get'
  },
  //短信日志
  getLogSmsList: {
    url: '/Log/SmsList',
    method: 'get'
  },
  //短信详情
  getLogSmsDetail: {
    url: '/Log/SmsDetail',
    method: 'get'
  },
  //登录日志
  getLogLogList: {
    url: '/Log/LogList',
    method: 'get'
  },
  //操作日志
  getLogOptList: {
    url: '/Log/OptList',
    method: 'get'
  },
  getLogExportTpl: {
    url: '/Log/GetExportTpls',
    method: 'get'
  },
  getLogTplItems: {
    url: '/Log/GetExportFields',
    method: 'get'
  },
  getLogSaveExportTpls: {
    url: '/Log/SaveExportTpls',
    method: 'post'
  },
  getLogExport: {
    url: '/Log/Export',
    method: 'post'
  },
}

export default managementUrls