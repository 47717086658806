/**
 * 模块名称: 筛选组件（员工信息管理）
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  Icon,
  Modal,
  Row,
  Col
} from 'antd'
import BtnGroup from '../BtnGroup'
import moment from 'moment'
import './style.scss'

const FormItem = Form.Item
const { Option } = Select
const { RangePicker } = DatePicker

let staffOnjobData = {
  id: -1,
  name: '全部'
}

const StaffInfoFilter = (props) => {
  const { formFilter, saveFilterTerm, handleOk, handleCancel, visible, locationPathname, onFilterReset, companyId, section, exporfilter, state } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [department, setDepartment] = useState([])
  const [position, setPosition] = useState([])
  const [stateEmployees, setStateEmployees] = useState([])
  const [personnelType, setPersonnelType] = useState([])
  const [coreStaff, setCoreStaff] = useState([])
  const [isMainStaff, setIsMainStaff] = useState([])
  const [businessGroup, setBusinessGroup] = useState([])
  const [expand, setExpand] = useState(false)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [filterContent, setFilterContent] = useState([])
  const [unfoldPack, setUnfoldPack] = useState(true)
  const [textUnfoldPack, setTextUnfoldPack] = useState(true)
  const [statusVal, setStatusVal] = useState('请选择')
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)

  //获取公共接口
  useEffect(() => {
    getSelectDepartmentList() //部门
    api.getCommon({ type: 'staff_job_position, staff_is_onjob, staff_is_formal, staff_is_core, staff_is_main_account' }).then(data => {
      // console.log(data)
      data.staff_is_onjob.unshift(staffOnjobData)
      setPosition(data.staff_job_position) //职级
      setStateEmployees(data.staff_is_onjob) //员工状态
      setPersonnelType(data.staff_is_formal) //人员类型
      setCoreStaff(data.staff_is_core) //核心员工
      setIsMainStaff(data.staff_is_main_account)//是否主账号
    }) 
    api.getSelectGroupList({ limit: global.paramsLimit }).then(data => setBusinessGroup(data.list)) //事业群
    // api.getCommon({ type: 'staff_is_onjob' }).then(data => setStateEmployees(data)) //员工状态
    // api.getCommon({ type: 'staff_is_formal' }).then(data => setPersonnelType(data)) //人员类型
    // api.getCommon({ type: 'staff_is_core' }).then(data => setCoreStaff(data)) //核心员工
    // api.getCommon({ type: 'staff_is_main_account' }).then(data => setIsMainStaff(data)) //是否主账号
    resetFields()
    // console.log(exporfilter, state)
    if (state) {
      setTimeout(() => {
        setFieldsValue({
          addTime: exporfilter.addTimeMin !== '' ? [moment(exporfilter.addTimeMin), moment(exporfilter.addTimeMax)] : undefined,
          attendanceNum: exporfilter.attendanceNum !== '' ? exporfilter.attendanceNum : undefined,
          departmentId: exporfilter.departmentId !== '' ? exporfilter.departmentId : undefined,
          entryDate: exporfilter.entryDateMin !== '' ? [moment(exporfilter.entryDateMin), moment(exporfilter.entryDateMax)] : undefined,
          groupId: exporfilter.groupId !== '' ? exporfilter.groupId : undefined,
          identityCardNoFace: exporfilter.identityCardNoFace !== '' ? exporfilter.identityCardNoFace : undefined,
          isCorel: exporfilter.isCorel !== '' ? exporfilter.isCorel : undefined,
          isFormal: exporfilter.isFormal !== '' ? exporfilter.isFormal : undefined,
          isMainAccount: exporfilter.isMainAccount !== '' ? exporfilter.isMainAccount : undefined,
          isOnJob: exporfilter.isOnJob !== '' ? exporfilter.isOnJob : undefined,
          jobPosition: exporfilter.jobPosition !== '' ? exporfilter.jobPosition : undefined,
          leaderName: exporfilter.leaderName !== '' ? exporfilter.leaderName : undefined,
          leaveApplyDate: exporfilter.leaveApplyDateMin !== '' ? [moment(exporfilter.leaveApplyDateMin), moment(exporfilter.leaveApplyDateMax)] : undefined,
          leaveDate: exporfilter.leaveDateMin !== '' ? [moment(exporfilter.leaveDateMin), moment(exporfilter.leaveDateMax)] : undefined,
          staffName: exporfilter.staffName !== '' ? exporfilter.staffName : undefined,
          staffNo: exporfilter.staffNo !== '' ? exporfilter.staffNo : undefined,
          userName: exporfilter.userName !== '' ? exporfilter.userName : undefined,
          workEmail: exporfilter.workEmail !== '' ? exporfilter.workEmail : undefined,
          workMobile: exporfilter.workMobile !== '' ? exporfilter.workMobile : undefined
        })
      },1000)
    } 
    // else {
    //   setFieldsValue({
    //     isOnJob: 1
    //   })
    // }
  }, [])

  useEffect(() => {
    // console.log(section, corpId)
    getSelectDepartmentList()
    setFieldsValue({'departmentId': undefined})
  }, [section])

  //部门
  const getSelectDepartmentList = () => {
    api.getSelectDepartmentList(companyId.length ? { limit: global.paramsLimit, companyId: companyId } : { limit: global.paramsLimit }).then(data => {
      setDepartment(data.list)
    })
  }

  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        formFilter(vals) //点击筛选提交表单的回调函数
      }
    })
  }

  //重置
  const onReset = (e) => {
    e.preventDefault()
    resetFields()
    // setFieldsValue({
    //   isOnJob: 1
    // })
    onFilterReset()
    setStatusVal('请选择')
    setStatusValName(undefined)
  }

  const toggle = () => {
    setExpand(!expand)
    setUnfoldPack(!unfoldPack)
    setTextUnfoldPack(!textUnfoldPack)

  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        saveFilterTerm(vals) //点击保存筛选条件的回调函数
      }
    })

  }

  const HandleOk = () => {
    handleOk(filterInfoValue) //点击保存筛选弹出框内确定的回调函数
  }

  const HandleCancel = () => {
    handleCancel() //点击保存筛选弹出框内取消的回调函数
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //获取保存筛选弹框中input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: locationPathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    setFieldsValue({
      staffNo: filterCont.staffNo || filterCont.staffNo == 0 ? filterCont.staffNo : undefined,//eslint-disable-line
      staffName: filterCont.staffName || filterCont.staffName == 0 ? filterCont.staffName : undefined,//eslint-disable-line
      workEmail: filterCont.workEmail || filterCont.workEmail == 0 ? filterCont.workEmail : undefined,//eslint-disable-line
      workMobile: filterCont.workMobile || filterCont.workMobile == 0 ? filterCont.workMobile : undefined,//eslint-disable-line
      departmentId: filterCont.departmentId || filterCont.departmentId == 0 ? filterCont.departmentId : undefined,//eslint-disable-line
      jobPosition: filterCont.jobPosition || filterCont.jobPosition == 0 ? filterCont.jobPosition : undefined,//eslint-disable-line
      groupId: filterCont.groupId || filterCont.groupId == 0 ? filterCont.groupId : undefined,//eslint-disable-line
      leaderName: filterCont.leaderName || filterCont.leaderName == 0 ? filterCont.leaderName : undefined,//eslint-disable-line
      isOnJob: filterCont.isOnJob || filterCont.isOnJob == 0 ? filterCont.isOnJob : undefined,//eslint-disable-line
      isFormal: filterCont.isFormal || filterCont.isFormal == 0 ? filterCont.isFormal : undefined,//eslint-disable-line
      attendanceNum: filterCont.attendanceNum || filterCont.attendanceNum == 0 ? filterCont.attendanceNum : undefined,//eslint-disable-line
      identityCardNoFace: filterCont.identityCardNoFace || filterCont.identityCardNoFace == 0 ? filterCont.identityCardNoFace : undefined,//eslint-disable-line
      userName: filterCont.userName || filterCont.userName == 0 ? filterCont.userName : undefined,//eslint-disable-line
      isCorel: filterCont.isCorel || filterCont.isCorel == 0 ? filterCont.isCorel : undefined,//eslint-disable-line
      entryDate: filterCont.entryDate || filterCont.entryDate == 0 ? [moment(filterCont.entryDate[0]), moment(filterCont.entryDate[1])] : undefined, //eslint-disable-line
      leaveDate: filterCont.leaveDate || filterCont.leaveDate == 0 ? [moment(filterCont.leaveDate[0]), moment(filterCont.leaveDate[1])] : undefined,//eslint-disable-line
      addTime: filterCont.addTime || filterCont.addTime == 0 ? [moment(filterCont.addTime[0]), moment(filterCont.addTime[1])] : undefined,//eslint-disable-line
      leaveApplyDate: filterCont.leaveApplyDate || filterCont.leaveApplyDate == 0 ? [moment(filterCont.leaveApplyDate[0]), moment(filterCont.leaveApplyDate[1])] : undefined,//eslint-disable-line
      isMainAccount: filterCont.isMainAccount || filterCont.isMainAccount == 0 ? filterCont.isMainAccount : undefined,//eslint-disable-line
    })//选择保存筛选下拉框中的内容
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: locationPathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <div className="title" >其他选项：</div>
      <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="员工编号">
                  {getFieldDecorator('staffNo')(<Input placeholder="请输入员工编号" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="公司用名">
                  {getFieldDecorator('staffName')(<Input placeholder="请输入员工姓名" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="公司邮箱">
                  {getFieldDecorator('workEmail')(<Input placeholder="请输入公司邮箱" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="手机号">
                  {getFieldDecorator('workMobile')(<Input placeholder="请输入手机号" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="部门">
                  {getFieldDecorator('departmentId')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false} onDropdownVisibleChange={bool => bool && getSelectDepartmentList()}
                    showSearch={true}
                    optionFilterProp="children"
                    >
                      {department.map((v, i) => {
                        return section == 0 ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                      })}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="职级">
                  {getFieldDecorator('jobPosition')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                    >
                      {position.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="员工状态">
                  {getFieldDecorator('isOnJob')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {stateEmployees.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="直接主管">
                  {getFieldDecorator('leaderName')(<Input placeholder="请输入直接主管" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="事业群">
                  {getFieldDecorator('groupId')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}
                    showSearch={true}
                    optionFilterProp="children"
                    >
                      {businessGroup.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="人员类型">
                  {getFieldDecorator('isFormal')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {personnelType.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="考勤号">
                  {getFieldDecorator('attendanceNum')(<Input placeholder="请输入考勤号" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="身份证号">
                  {getFieldDecorator('identityCardNoFace')(<Input placeholder="请输入身份证号" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="登录账号">
                  {getFieldDecorator('userName')(<Input placeholder="请输入登录账号" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="核心员工">
                  {getFieldDecorator('isCorel')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {coreStaff.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="到职日期">
                  {getFieldDecorator('entryDate')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="离职日期">
                  {getFieldDecorator('leaveDate')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
              <FormItem label="新增员工日期">
                {getFieldDecorator('addTime')(<RangePicker allowClear={false} />)}
              </FormItem>
              </Col>
              <Col span={6}>
              <FormItem label="离职报备日期">
                {getFieldDecorator('leaveApplyDate')(<RangePicker allowClear={false} />)}
              </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="是否主账号">
                  {getFieldDecorator('isMainAccount')(
                    <Select placeholder="全部" dropdownMatchSelectWidth={false}>
                      {coreStaff.map((v, i) => <Option value={v.id} key={i}>{v.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={toggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            {textUnfoldPack ? '展开' : '收起'} <Icon type={expand ? 'up' : 'down'} />
          </span>
        </div>
         
          {/* <FormItem label="企业QQ" className={unfoldPack ? "staffInfoFilter-unfold" : ""}>
            {getFieldDecorator('enterpriseQQ')(<Input placeholder="请输入企业QQ" style={{ width: 200 }} />)}
          </FormItem> */}
        <div className="search-btns" style={{ paddingBottom: 10 }}>
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                placeholder="请选择"
                value={statusVal}
                style={{ display: 'inline-block', width: '160px' }}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {filterContent.map((item, i) => 
                  <Option value={i} key={i}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                  
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
          </div>
          <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
        </div>
        <Modal
          title="保存筛选条件"
          visible={visible}
          onOk={HandleOk}
          onCancel={HandleCancel}
        >
          <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
        </Modal>
      </Form>
    </>
  )
}

export default Form.create()(StaffInfoFilter)