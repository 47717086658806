/**
 * 导入下载管理
 * 
 */
const importDownUrls = {
  uploadList: {
    url: '/File/UploadList',
    method: 'get'
  },
  exportList: {
    url: '/File/ExportList',
    method: 'get'
  },
  goToUpload: {
    url: '/File/GoToUpload',
    method: 'get'
  },
  setFileredo: {
    url: '/File/Redo',
    method: 'get'
  },
  setFileDownloads: {
    url: '/File/Downloads',
    method: 'get'
  },
  getHost: {
    url: '/File/gethost',
    method: 'get'
  }
}

export default importDownUrls