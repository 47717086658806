/*
 * @Author      : OBKoro1(James Wang)
 * @Date        : 2020-09-24 09: 19: 55
 * @Lastditors  : OBKoro1(your name)
 * @LastEditTime: 2020-10-21 16: 51: 49
 * @Description : file content
 */
/**
 * 发票管理
 *
 */

const invoiceUrls = {
	getFortuneInvoiceList: {
		url: '/fortune/Invoice/GetList',
		method: 'get'
	},
	//获取发票中筛选项的一些列表
	getFortuneStatusLists: {
		url: '/fortune/Invoice/GetStatus',
		method: 'get'
	},
	//删除发票
	onFortuneInvoiceCtlDel: {
		url: '/fortune/Invoice/Del',
		method: 'post'
	},
	//根据厂商id获取详情
	getFortuneInvoiceVendorInfo: {
		url: '/fortune/Invoice/GetVendorInfo',
		method: 'get'
	},
	//开票账户列表
	getFortuneInvoiceAccount: {
		url: '/fortune/Invoice/GetAccount',
		method: 'get'
	},
	//获取认款列表
	getFortuneInvoiceClaim: {
		url: '/fortune/Invoice/GetClaim',
		method: 'get'
	},
	//申请发票 => 获取认款列表
	getNewInvoiceClaim: {
		url: '/fortune/Invoice/GetInvoiceClaim',
		method: 'get'
	},
	//获取未收款发票列表
	getFortuneUnInvoice: {
		url: '/fortune/Invoice/GetUnInvoice',
		method: 'get'
	},
	//发票申请
	onFortuneInvoiceApply: {
		url: '/fortune/Invoice/Apply',
		method: 'post'
	},
	//发票详情
	getfortuneInvoiceDetail: {
		url: '/fortune/Invoice/GetDetail',
		method: 'get'
	},
	//发票清理
	onfortuneInvoiceClear: {
		url: '/fortune/Invoice/Clear',
		method: 'post'
	},
	//发票冲红
	invoiceRedOld: {
		url: '/fortune/Invoice/Red',
		method: 'post'
	},
	//发票冲红
	invoiceRed: {
		url: '/fortune/Invoice/Modify',
		method: 'post'
	},
	//发票审批
	invoiceAudit: {
		url: '/fortune/Invoice/Audit',
		method: 'post'
	},
	//发票号填写
	invoiceNumber: {
		url: '/fortune/Invoice/InvNumber',
		method: 'post'
	},
	//发票领取人
	invoiceReceive: {
		url: '/fortune/Invoice/GetStaffs',
		method: 'get'
	},
	//获取发票领取人
	getInvoiceReceiverList: {
		url: '/fortune/Invoice/GetReceiverList',
		method: 'get'
	},
	//发票-添加领取人信息
	setInvoiceAddReciverInfo: {
		url: '/fortune/Invoice/AddReciverInfo',
		method: 'post'
	},
	//申请退票
	returnInvoiceApply: {
		url: '/fortune/Invoice/ReturnInvoice',
		method: 'post'
	},
	//核销发票
	returnInvoiceCancellation: {
		url: '/fortune/Invoice/cancellation',
		method: 'post'
	},
	//获取客户最近一次某个发票类型的数据
	getInvoiceGetLastInvoiceData: {
		url: '/fortune/Invoice/GetLastInvoiceData',
		method: 'get'
	}
}

export default invoiceUrls