/**
 * 代理商积分
 *
 */

const agentIntegral = {
	//获取冲退积分列表
	getAgentIntegralList: {
		url: '/fortune/agentIntegral/getList',
		method: 'get'
	},
	//获取冲退积分信息
	getAgentIntegralInfo: {
		url: '/fortune/agentIntegral/getInfo',
		method: 'get'
	},
	//删除冲退积分申请
	delAgentIntegral: {
		url: '/fortune/agentIntegral/updateIsDel',
		method: 'post'
	},
	//保存冲退积分
	saveAgentIntegral: {
		url: '/fortune/agentIntegral/save',
		method: 'post'
	},
	//审批冲退积分
	auditAgentIntegral: {
		url: '/fortune/agentIntegral/audit',
		method: 'post'
	},
	//获取当前登录员工信息
	getStaffDetailInfo: {
		url: '/staff/getStaffInfo',
		method: 'get'
	},
	//查询代理商可用积分接口
	getIntegralInfo: {
		url: '/fortune/agentIntegral/getIntegralInfo',
		method: 'get'
	},
}

export default agentIntegral