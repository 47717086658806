/*
 * @Description: 
 * @Author: 杨志航<yangzhihang@372163.com>
 * @Date: 2021-11-05 14:07:34
 */

const achievement = {
  // 绩效结果管理
  getAchResultList: {
    url: '/fortune/achievements/getAchResultList',
    method: 'get'
  },
  getSearchData: {
    url: '/fortune/achievements/getSearchData',
    method: 'get'
  },
  updateAchResult: {
    url: '/fortune/achievements/updateAchResult',
    method: 'post'
  },
  delAchResult: {
    url: '/fortune/achievements/delAchResult',
    method: 'post'
  },
  signAchResult: {
    url: '/fortune/achievements/signAchResult',
    method: 'post'
  },
  uploadAchResultFile: {
    url: '/fortune/achievements/upload',
    method: 'post'
  },
  getMonthList: {
    url: '/fortune/achievements/getMonthList',
    method: 'get'
  },
  // 年度目标管理
  getYearList: {
    url: '/fortune/achievements/getYearList',
    method: 'get'
  },
  saveAchYear: {
    url: '/fortune/achievements/saveAchYear',
    method: 'post'
  },
  getStfList: {
    url: '/fortune/achievements/getStfList',
    method: 'get'
  },
  delYear: {
    url: '/fortune/achievements/delYear',
    method: 'post'
  },
}

export default achievement
