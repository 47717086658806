/**
 * 待办事项
 * 
 */

const schedule = {
  getScheduleList: {
    url: '/sysTodo/getList',
    method: 'get'
  },
  getScheduleTypes: {
    url: '/sysTodo/getTypes',
    method: 'get'
  },
  setScheduleConfig: {
    url: '/sysTodo/setConfig',
    method: 'post'
  },
  getScheduleConfig: {
    url: '/sysTodo/GetConfigForWeb',
    method: 'get'
  }
}

export default schedule