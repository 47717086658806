/**
 * 模块名称: 面包屑
 * @author xuzhongyuan@372163.com
 */

import React, { useEffect, useState } from 'react'
import { Breadcrumb, Icon } from 'antd'
import { Link } from 'react-router-dom'
import local from './local'

const BreadcrumbItem = Breadcrumb.Item

const BreadcrumbComp = ({ menu, location }) => {
  const pathname = location.pathname
  const [breadcrumbName, setBreadcrumbName] = useState('')

  useEffect(() => {
    setBreadcrumbName('')
    getBreadcrumbName([...local, ...menu])
  }, [pathname, menu.length])

  function getBreadcrumbName(menu) {
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].url === pathname) {
        setBreadcrumbName(menu[i].fullTitle)
        return
      } else if(menu[i].children.length) {
        getBreadcrumbName(menu[i].children)
      }
    }
  }

  function renderBreadcrumb() {
    const pathName = breadcrumbName.split(' => ')
    //const pathUrl = pathname.split('/').filter(item => item)
    const breadcrumbItems = pathName.map((item, i) => {
      return <BreadcrumbItem key={item}>{item}</BreadcrumbItem>
      
      // if (i === 0) {
      //   return <BreadcrumbItem key={item}>{item}</BreadcrumbItem>
      // }
      // const url = pathUrl.slice(0, i + 1).join('/')
      // return <BreadcrumbItem key={item}><Link to={`/${url}`}>{item}</Link></BreadcrumbItem>
    })
    return [
      <BreadcrumbItem key="home"><Link to="/index"><Icon type="home"/></Link></BreadcrumbItem>,
      ...breadcrumbItems
    ]
  }

  return (
    <Breadcrumb>
      {pathname === '/index'
        ? <BreadcrumbItem><Icon type="home" /></BreadcrumbItem>
        : renderBreadcrumb()
      }
    </Breadcrumb>
  )
}

export default BreadcrumbComp