/**
 * 获取列表
 * 
 */

const message = {
  getMessageList: {
    url: '/sysMessage/getList',
    method: 'get'
  },
  setAllMessageReaded: {
    url: '/sysMessage/setAllReaded',
    method: 'post'
  },
  getMessageTypes: {
    url: '/sysMessage/getTypes',
    method: 'get'
  },
  setMessageReaded: {
    url: '/sysMessage/setReaded',
    method: 'post'
  }
}

export default message