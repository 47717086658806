/**
 * 代理商转移
 *
 */

const agentTransfer = {
	//代理商转移审批列表
	getAgentTransferList: {
		url: '/fortune/AgentTransfer/GetList',
		method: 'get'
	},
	//代理商待转列表
	getTransferAgentList: {
		url: '/fortune/AgentTransfer/GetTransferAgentList',
		method: 'get'
	},
	//未收款发票列表
	getInvoiceList: {
		url: '/fortune/AgentTransfer/GetTransferInvoiceList',
		method: 'get'
	},
	//账户余额列表
	getAccountList: {
		url: '/fortune/AgentTransfer/GetTransferAccountRemainList',
		method: 'get'
	},
	//信用账户欠款列表
	getCreditList: {
		url: '/fortune/AgentTransfer/GetTransferCreditRemainList',
		method: 'get'
	},
	//获取转入人列表
	getTransferInUserList: {
		url: '/fortune/AgentTransfer/GetTransferInUserData',
		method: 'get'
	},
	//代理商转移申请
	handleAgentTransfer: {
		url: '/fortune/AgentTransfer/Handle',
		method: 'post',
		headers: {
			route: '/pages/addNewAgentTransfer'
		}
	},
	//代理商转移详情
	getAgentTransferDetail: {
		url: '/fortune/AgentTransfer/GetDetail',
		method: 'get'
	},
	//获取代理商合同列表
	getContractList: {
		url: '/fortune/Agent/GetContractList',
		method: 'get'
	},
	//代理商转移审批
	agentTransferAudit: {
		url: '/fortune/AgentTransfer/Audit',
		method: 'post'
	},
	//代理商转移删除
	agentTransferDel: {
		url: '/fortune/AgentTransfer/Del',
		method: 'post'
	},
}

export default agentTransfer