/**
 * // 公用数据接口
 * 
 */

const common = {
  upload: {
    url: '/Common/upload',
    method: 'post'
  },
  fileUpload: {
    url: '/File/upload',
    method: 'post'
  },
  fileDelFile: {
    url: '/File/DelFile',
    method: 'get'
  },
  getCommon: {
    url: '/common/getMeta',
    method: 'get'
  },
  getArea: {
    url: '/common/getArea',
    method: 'get'
  },
  // 图形验证码
  getCaptcha: {
    url: '/Login/GetCaptcha',
    method: 'get'
  },
  // 获取直接主管
  getLeaderList: {
    url: '/staff/getLeaderList',
    method: 'get'
  },
  getCorpFilterList: {
    url: '/company/getCompanyMenuList',
    method: 'get'
  },
  getPageAuth: {
    url: '/Role/GetPageButtonAuthOfMenu',
    method: 'get'
  },
  getPeopleInfoWithIdNo: {
    url: '/common/getPeopleInfoWithIdNo',
    method: 'get'
  },
  checkImgCode: {
    url: '/Login/CheckImageCode',
    method: 'get'
  },
  //下拉所属公司
  getSelectCompanyList: {
    url: '/company/getSelectCompanyList',
    method: 'get'
  },
  //公司列表（包含已删除、失效）
  getAllCompanyList: {
    url: '/company/getAllCompanyList',
    method: 'get'
  },
  //下拉岗位级别
  getSelectJobStationLevelList: {
    url: '/jobStationLevel/getSelectJobStationLevelList',
    method: 'get'
  },
  //下拉所属部门
  getSelectDepartmentList: {
    url: '/department/getSelectDepartmentList',
    method: 'get'
  },
  //客户类型
  getSelectRelationTypeList: {
    url: '/common/getMeta',
    method: 'get'
  },
  //退款原因
  getSelectRefundReasonList: {
    url: '/common/getMeta',
    method: 'get'
  },
  //退款状态
  getSelectRefundStatusList: {
    url: '/common/getMeta',
    method: 'get'
  },
  //所属事业群
  getSelectGroupList: {
    url: '/group/getSelectGroupList',
    method: 'get'
  },
  delExportTpl: {
    url: '/File/DelTemp',
    method: 'get'
  },
  useTokenLogin: {
    url: '/Login/OaLogin',
    method: 'post'
  },
  // 获取oaURL地址
  getLoginOaUrl: {
    url: '/Login/GetOaUrl',
    method: 'get'
  },
  // 厂商列表的客户详情
  getfortuneSelfFund: {
    url: '/fortune/Client/GetSelfFund',
    method: 'get'
  },
  // 筛选项删除
  searchStorageDelete: {
    url: '/searchStorage/delete',
    method: 'post'
  },
  // 根据审批流类型获取审批流状态
  getAuditStatusList: {
    url: '/Common/GetAuditStatusList',
    method: 'get'
  },
  // 权签 => 公共所属公司
  getHeadCompanyList: {
    url: '/company/HeadCompanyList',
    method: 'get'
  },
  // 根据角色获取员工
  getStaffListWithRoleName: {
    url: '/Role/GetStaffListWithRoleName',
    method: 'get'
  },
  // 获取签名
  getFileAlisign: {
    url: '/File/Alisign',
    method: 'get'
  },
  // 请假详情催一催
  urgeLeaveout: {
    url: '/LeaveOut/procedureUrge',
    method: 'post'
  }, 
  // 认款详情催一催
  urgeFundMoneyClaim: {
    url: '/fortune/fundMoneyClaim/procedureUrge',
    method: 'post'
  },
  // 退款详情催一催
  urgeFundMoneyRefund: {
    url: '/fortune/fundMoneyRefund/procedureUrge',
    method: 'post'
  },
  // 发票详情催一催
  urgeInvoice: {
    url: '/fortune/Invoice/procedureUrge',
    method: 'post'
  },
  // 直销提单详情催一催
  urgeOrderDirect: {
    url: '/fortune/OrderDirect/procedureUrge',
    method: 'post'
  },
  // 代理商提单详情催一催
  urgeOrderAgent: {
    url: '/fortune/OrderAgent/procedureUrge',
    method: 'post'
  },
  // 退单详情催一催
  urgeOrder: {
    url: '/fortune/Order/procedureUrge',
    method: 'post'
  },
}

export default common