/*
 * @Author      : OBKoro1(James Wang)
 * @Date        : 2020-09-24 09: 19: 55
 * @Lastditors  : OBKoro1(your name)
 * @LastEditTime: 2020-11-30 10: 53: 46
 * @Description : file content
 */
/**
 * 资金账户相关接口
 *
 */

const account = {
	//资金账户列表接口
	getFundAccountList: {
		url: '/fortune/Fund/FundAccountList',
		method: 'get'
	},
	//资金明细接口
	getFundDetailList: {
		url: '/fortune/Fund/FundDetailList',
		method: 'get'
	},
	//信用账户列表接口
	getCreditAccountList: {
		url: '/fortune/CreditAccount/list',
		method: 'get'
	},
	//欠款明细接口
	getCreditarrearsList: {
		url: '/fortune/creditAccount/arrearsList',
		method: 'get'
	},
	//申请信用额度列表接口
	getCreditApplyList: {
		url: '/fortune/CreditAccount/applyList',
		method: 'get'
	},
	//申请信用额度删除接口
	getCreaditApplyDelete: {
		url: '/fortune/CreditAccount/del',
		method: 'post'
	},
	//申请信用额度删除接口
	getCreditApplyDetail: {
		url: '/fortune/CreditAccount/ApplyDetail',
		method: 'get'
	},
	//信用额度审批接口
	creditApplyAudit: {
		url: '/fortune/CreditAccount/audit',
		method: 'post'
	},
	//调整信用帐户接口
	creditAccountAdjust: {
		url: '/fortune/CreditAccount/adjust',
		method: 'post'
	},
	//获取客户信息接口
	getCusTomInfo: {
		url: '/fortune/fundMoneyRefund/getCusTomInfo',
		method: 'get'
	},
	//获取客户认证详情接口
	getGetCusTomDetail: {
		url: '/fortune/ClientVerify/GetDetail',
		method: 'get'
	},
	//额度申请接口
	creditAccountApply: {
		url: '/fortune/CreditAccount/apply',
		method: 'post'
	},
	//调整信用帐户回写页接口
	creditAdjustDetail: {
		url: '/fortune/CreditAccount/AdjustDetail',
		method: 'get'
	},
	//获取额度旧信息
	getOldData: {
		url: '/fortune/CreditAccount/GetOldCreditDate',
		method: 'get'
	},
	//导出接口
	getFortuneCreditAccountChangeTplItems: {
		url: '/fortune/CreditAccount/GetExportFields',
		method: 'get'
	},
	getFortuneCreditAccountChangeExportTpl: {
		url: '/fortune/CreditAccount/GetExportTpls',
		method: 'get'
	},
	saveFortuneCreditAccountChangeExportTpls: {
		url: '/fortune/CreditAccount/SaveExportTpls',
		method: 'post'
	},
	exportFortuneCreditAccountExport: {
		url: '/fortune/CreditAccount/Export',
		method: 'post'
	},
	//获取调整后额度
	getCreditAccountGetAddAfterAmount: {
		url: '/fortune/CreditAccount/GetAddAfterAmount',
		method: 'get'
	},
	//检测信用账户是否存在
	getCreditAccountCheckAccount: {
		url: '/fortune/CreditAccount/CheckAccount',
		method: 'get'
	},
	//信用账户列表=》履历
	creditAcountGetResumeList: {
		url: '/fortune/creditAccount/GetResumeList',
		method: 'get'
	},
	//信用账户列表=》信用账户流水
	creditAcountgetDetailed: {
		url: '/fortune/creditAccount/GetDetailedList',
		method: 'get'
	}
}

export default account