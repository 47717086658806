/**
 * 结算管理
 *
 */

const settlement = {
  // 凭证预览
  getRefundOrderPreview: {
    url: '/fortune/refundOrder/preview',
    method: 'get',
  },
  // 凭证下载
  getRefundOrderMultiDownload: {
    url: '/fortune/refundOrder/multiDownload',
    method: 'post',
  },
  // 导出接口
  getRefundOrderChangeTplItems: {
    url: '/fortune/refundOrder/GetExportFields',
    method: 'get',
  },
  getRefundOrderChangeExportTpl: {
    url: '/fortune/refundOrder/GetExportTpls',
    method: 'get',
  },
  saveRefundOrderChangeExportTpls: {
    url: '/fortune/refundOrder/SaveExportTpls',
    method: 'post',
  },
  exportRefundOrderChange: {
    url: '/fortune/refundOrder/Export',
    method: 'post',
  },
  // 采购订单列表
  getAssetPurchaseWaitCloseList: {
    url: '/assetPurchase/waitCloseList',
    method: 'get',
  },
	// 采购订单筛选项
  getAssetPurchaseFiltersList: {
    url: '/assetPurchase/getVariousSelectionList',
    method: 'get',
  },
  // 采购订单修改
  setAssetPurchaseModify: {
    url: '/assetPurchase/modify',
    method: 'post',
  },
  // 采购订单结算
  setAssetPurchaseSettlement: {
    url: '/assetPurchase/settlement',
    method: 'post',
  },
  // 采购订单付款失败
  setAssetPurchasePaymentFailed: {
    url: '/assetPurchase/paymentFailed',
    method: 'post',
  },
  // 已支付采购订单
  getAssetPurchaseList: {
    url: '/assetPurchase/hasCloseList',
    method: 'get',
  },
  // 已支付-采购列表导出接口
  getAssetPurchaseTplItems: {
    url: '/AssetPurchase/GetExportFields',
    method: 'get',
  },
  getAssetPurchaseExportTpl: {
    url: '/AssetPurchase/GetExportTpls',
    method: 'get',
  },
  saveAssetPurchaseExportTpls: {
    url: '/AssetPurchase/SaveExportTpls',
    method: 'post',
  },
  exportAssetPurchaseChange: {
    url: '/AssetPurchase/Export',
    method: 'post',
  },
  // 已支付-采购列表明细导出接口
  getAssetDetailTplItems: {
    url: '/AssetDetail/GetExportFields',
    method: 'get',
  },
  getAssetDetailExportTpl: {
    url: '/AssetDetail/GetExportTpls',
    method: 'get',
  },
  saveAssetDetailExportTpls: {
    url: '/AssetDetail/SaveExportTpls',
    method: 'post',
  },
  exportAssetDetailChange: {
    url: '/AssetDetail/Export',
    method: 'post',
  },
  // 已支付-内部往来款
  getInnerFundTransferHasCloseList: {
    url: '/InnerFundTransfer/hasCloseList',
    method: 'get',
  },
  // 转款下拉账户列表
  getInnerFundTransferAccount: {
    url: '/InnerFundTransfer/accountList',
    method: 'get',
  },
  // 已支付-内部往来款导出
  getTransferChangeExportTpl: {
    url: '/InnerFundTransfer/GetExportTpls',
    method: 'get',
  },
  saveTransferChangeExportTpls: {
    url: '/InnerFundTransfer/SaveExportTpls',
    method: 'post',
  },
  exportTransferChange: {
    url: '/InnerFundTransfer/Export',
    method: 'post',
  },
  getTransferChangeTplItems: {
    url: '/InnerFundTransfer/GetExportFields',
    method: 'get'
  }
};

export default settlement;
