/**
 * 合同管理模块
 * 
 */

const contractUrls = {
	//合同列表
	getFortuneContractList: {
		url: '/fortune/Contract/GetList',
		method: 'get'
	},
	//合同列表-筛选状态
	getFortuneContractApplyStatus: {
		url: '/fortune/Contract/GetApplyStatus',
		method: 'get'
	},
	//合同申请
	getFortuneContractApply: {
		url: '/fortune/Contract/apply',
		method: 'post'
	},
	//合同预览接口
	getFortuneContractPreview: {
		url: '/fortune/Contract/Preview',
		method: 'get'
	},
	//合同详情
	getFortuneContractDetail: {
		url: '/fortune/Contract/GetDetail',
		method: 'get'
	},
	//删除合同
	getFortuneContractDel: {
		url: '/fortune/Contract/Del',
		method: 'post'
	},
	//合同审批
	getFortuneContractAudit: {
		url: '/fortune/Contract/Audit',
		method: 'post'
	},
	//合同-添加领取人信息
	getFortuneContractAddReciverInfo: {
		url: '/fortune/Contract/AddReciverInfo',
		method: 'post'
	},
	//合同-操作(已回、已退回、已核销、已归档)
	getFortuneContractOperate: {
		url: '/fortune/Contract/Operate',
		method: 'post'
	},
	//获取当前登录人提单范围（公司范围）
	getFortuneContractAccountList: {
		url: '/fortune/Contract/GetAccountList',
		method: 'get'
	},
	//获取当前登录人所在部门中所有在职员工
	getFortuneContractDepartList: {
		url: '/fortune/Contract/GetDepartList',
		method: 'get'
	},
	// 领取人
	getContractStaffData: {
		url: '/fortune/Contract/GetContractStaff',
		method: 'get'
	},
	//合同模板状态
	getFortuneContractTempStatus: {
		url: '/fortune/Contract/GetTempStatus',
		method: 'get'
	},
	//获取审批状态
	getFortuneContractApprovalStatusList: {
		url: '/fortune/Contract/GetApprovalStatusList',
		method: 'get'
	},
	//检测合同号是否存在
	getFortuneContractCheckContractNo: {
		url: '/fortune/Contract/CheckContractNo',
		method: 'get'
	},
	//导出接口
	getFortuneContractChangeTplItems: {
		url: '/fortune/Contract/GetExportFields',
		method: 'get'
	},
	getFortuneContractChangeExportTpl: {
		url: '/fortune/Contract/GetExportTpls',
		method: 'get'
	},
	saveFortuneContractChangeExportTpls: {
		url: '/fortune/Contract/SaveExportTpls',
		method: 'post'
	},
	exportFortuneContractChange: {
		url: '/fortune/Contract/Export',
		method: 'post'
	},
	getContractStaffList: {
		url: '/fortune/Contract/GetContractStaffList',
		method: 'get'
	}
}

export default contractUrls