// 数据分析中心

const analysis = {
    // 行业数据消耗列表
    getindustryConsumList: {
        url: '/fortune/industryConsumptionSummary/list',
        method: 'get'
    },
    getindustryCountTotal: {
        url: '/fortune/IndustryConsumptionSummary/getCountTotal',
        method: 'get'
    },
    // 行业数据消耗列表导出接口
    getIndustrytionChangeTplItems: {
        url: '/fortune/industryConsumptionSummary/GetExportFields',
        method: 'get',
    },
    getIndustrytionChangeExportTpl: {
        url: '/fortune/industryConsumptionSummary/GetExportTpls',
        method: 'get',
    },
    saveIndustrytionChangeExportTpls: {
        url: '/fortune/industryConsumptionSummary/SaveExportTpls',
        method: 'post',
    },
    exportIndustrytionChange: {
        url: '/fortune/industryConsumptionSummary/Export',
        method: 'post',
    },

    // 客户数据消耗列表
    getclientConsumptionList: {
        url: '/fortune/clientConsumptionSummary/list',
        method: 'get'
    },
    getclientConsumptionTotal: {
        url: '/fortune/clientConsumptionSummary/getCountTotal',
        method: 'get'
    },
    // 客户数据消耗列表导出接口
    getClienttionChangeTplItems: {
        url: '/fortune/clientConsumptionSummary/GetExportFields',
        method: 'get',
    },
    getClienttionChangeExportTpl: {
        url: '/fortune/clientConsumptionSummary/GetExportTpls',
        method: 'get',
    },
    saveClienttionChangeExportTpls: {
        url: '/fortune/clientConsumptionSummary/SaveExportTpls',
        method: 'post',
    },
    exportClienttionChange: {
        url: '/fortune/clientConsumptionSummary/Export',
        method: 'post',
    },
    // 2022新业务关联-列表
    getNewBusinessAssoclist: {
        url: '/fortune/newBusinessAssoc/list',
        method: 'get',
    },
    // 2022新业务关联-导入
    getNewBusinessAssocImport: {
        url: '/fortune/newBusinessAssoc/import',
        method: 'post',
    },

    // 2022新业务关联导出接口
    getNewBusinessAssocTplItems: {
        url: '/fortune/newBusinessAssoc/GetExportFields',
        method: 'get',
    },
    getNewBusinessAssocExportTpl: {
        url: '/fortune/newBusinessAssoc/GetExportTpls',
        method: 'get',
    },
    saveNewBusinessAssocExportTpls: {
        url: '/fortune/newBusinessAssoc/SaveExportTpls',
        method: 'post',
    },
    exportNewBusinessAssoc: {
        url: '/fortune/newBusinessAssoc/Export',
        method: 'post',
    },
    // 筛选项
    getNewBusinessSearchList: {
        url: '/fortune/newBusinessAssoc/getSearchList',
        method: 'get',
    },
    // 2022新业务关联-新业务联动
    getNewBusinessSearchLeveList: {
        url: '/fortune/newBusinessAssoc/getLevelList',
        method: 'get',
    },
    // 2022新业务关联-详情
    getNewBusinessSearchInfo: {
        url: '/fortune/newBusinessAssoc/info',
        method: 'get',
    },
    // 2022新业务关联-编辑
    setNewBusinessAssocEdit: {
        url: '/fortune/newBusinessAssoc/edit ',
        method: 'post',
    },
    // 客户新业务消耗汇总
    getnewBusinessConsumptionList: {
        url: '/fortune/newBusinessConsumption/clientConsumptionList',
        method: 'get',
    },
    // 客户新业务消耗汇总统计
    getnewBusinessClientTotal: {
        url: '/fortune/newBusinessConsumption/clientTotal',
        method: 'get',
    },
    // 行业新业务消耗汇总
    getnewIndustryConsumptionList: {
        url: '/fortune/newBusinessConsumption/industryConsumptionList',
        method: 'get',
    },
    // 行业新业务消耗汇总统计
    getnewBusinessIndustryTotal: {
        url: '/fortune/newBusinessConsumption/industryTotal',
        method: 'get',
    },
}

export default analysis