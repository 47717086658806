/**
 * 
 * 
 * 方法接受两个参数 
 *  查询对象
 *  是否开启loading
 */

import urls from './urls'
import { message } from 'antd'

// const msgKey = 'message'
const API = {}
let fetchTimer = null

for (let k in urls) {//eslint-disable-line
  API[k] = (query, loading) => {
    // 有查询参数
    if (typeof query === 'object') {
      return fetcher(urls[k], query, loading)
    }
    // 无查询参数 有loading
    if (typeof query === 'boolean') {
      return fetcher(urls[k], null, query)
    }
    // 无查询参数 无loading
    if (typeof query === 'undefined') {
      return fetcher(urls[k])
    }
    message.error('参数错误：查询参数必须是objec类型')
    console.error('参数错误：查询参数必须是objec类型')
  }
}

const fetcher = (urlObj, query, isLoading) => {
  let params = global.$deviceId ? {...query, deviceId: global.$deviceId} : {...query}
  const headers = new Headers()
  const config = {
    method: urlObj.method || 'GET',
    credentials: 'include',
    headers: headers
  }

  let url = urlObj.url

  const customHeaders = urlObj.headers || {}
  for(let hName in customHeaders){
    headers.append(hName, customHeaders[hName])
  }


  if (urlObj.method.toUpperCase() === 'POST') {
    headers.append('Content-Type', 'application/json')
    const { getQuery } = params
    if (getQuery) {
      url += url.indexOf('?') === -1 ? '?' : '&'
      for (let k in getQuery) {
        const val = getQuery[k] === undefined ? '' : getQuery[k]
        url += `${encodeURIComponent(k)}=${encodeURIComponent(val)}&`
      }
      url = url.slice(0, -1)
    }
    delete params.getQuery
    config.body = JSON.stringify(params)
  }
  else if (params) {
    url += url.indexOf('?') === -1 ? '?' : '&'
    for (let k in params) {//eslint-disable-line
      const val = params[k] === undefined ? '' : params[k]
      url += `${encodeURIComponent(k)}=${encodeURIComponent(val)}&`
    }
    url = url.slice(0, -1)
  }
  // 添加token
  const token = localStorage.getItem('token') 
  if (token) {
    headers.append('token', token)
  }

  const globalLoading = document.querySelector('.global-loading')
  const canUseLoading = isLoading && globalLoading

  canUseLoading && globalLoading.classList.add('show')

  return fetch(url, config)
    .then(response => response.json())
    .then(result => {
      // console.log('result=>', result)
      canUseLoading && globalLoading.classList.remove('show')
      if (+result.code === 0) {
        return result.data || result.msg
      }
      if (+result.code === 372163) {
        setTimeout(() => window.location = '/', 1500)
      }
      if (+result.code === 1002) {
        setTimeout(() => window.location = '/index', 1500)
      }
      if (+result.code === 1001) {
        localStorage.removeItem('token')
        setTimeout(() => window.location = '/', 1500)
      }
      return Promise.reject(result)
    })
    .catch(err => {
      canUseLoading && globalLoading.classList.remove('show')
      // console.error('fetch error: ', err)
      //message.error({ content: err.msg || '服务器发生错误，请联系管理员', key: msgKey })
      if(err.code === 1001 || err.code === 1002 || !err.code){//1001表示登录失效1002表示没有访问权限 防止弹多次窗////500的时候接口异常也在这里处理,500的时候err中没有code
        if(!fetchTimer)message.error( err.msg || '服务器发生错误，请联系管理员')
        clearTimeout(fetchTimer)
        fetchTimer = setTimeout(() => {
          fetchTimer = null
        }, 1000)
      }else{
        message.error( err.msg || '服务器发生错误，请联系管理员')
      }
      return Promise.reject(err || { msg: '服务器发生错误，请联系管理员' })
    })
}

export default API