// 供应商管理模块 => 系统充值
const supplie = {
  getTopUpList: {
    url: '/fortune/TopUp/list',
    method: 'get'
  },
  getTopUpWaitCloseList: {
    url: '/fortune/TopUp/WaitCloseList',
    method: 'get'
  },
  // 充值申请
  getTopUpApply: {
    url: '/fortune/TopUp/apply',
    method: 'post'
  },
   // 充值详情
   getTopUpDetail: {
    url: '/fortune/TopUp/detail',
    method: 'get'
  }, 
  // 充值删除
  getTopUpDelete: {
   url: '/fortune/TopUp/delete',
   method: 'get'
  },
  // 审批
  getTopUpAudit: {
    url: '/fortune/TopUp/audit',
    method: 'post'
  },
  // 付款失败
  setTopUpFail: {
    url: '/fortune/TopUp/fail',
    method: 'post'
  },
  // 结算
  setTopUpClose: {
    url: '/fortune/TopUp/close',
    method: 'post'
  },
  // 修改
  setTopUpFinanceEdit: {
    url: '/fortune/TopUp/financeEdit',
    method: 'post'
  },
  // 已结算-充值列表
  getTopUpHasCloseList: {
    url: '/fortune/TopUp/HasCloseList',
    method: 'get'
   },
   // 已结算-充值列表导出接口
  getTopUpChangeTplItems: {
    url: '/fortune/TopUp/GetExportFields',
    method: 'get',
  },
  getTopUpChangeExportTpl: {
    url: '/fortune/TopUp/GetExportTpls',
    method: 'get',
  },
  saveTopUpChangeExportTpls: {
    url: '/fortune/TopUp/SaveExportTpls',
    method: 'post',
  },
  exportTopUpChange: {
    url: '/fortune/TopUp/Export',
    method: 'post',
  },
  editTopUpProofList: {
    url: '/fortune/TopUp/EditProof',
    method: 'post',
  },
}

export default supplie