//供应商管理模块 => 合同盖章申请
const supplierContractSeal = {
    //合同盖章列表
    getSupplierContractSealList: {
      url: '/fortune/supplierContractSeal/getList',
      method: 'get'
    },
    //合同盖章删除
    getSupplierContractSealDel: {
      url: '/fortune/supplierContractSeal/del',
      method: 'get'
    },
    //供应商合同校验
    getSupplierContractSealCheckContract: {
			url: '/fortune/supplierContractSeal/checkContract',
			method: 'get'
    },
		//合同盖章申请
		setSupplierContractSealApply: {
			url: '/fortune/supplierContractSeal/apply',
			method: 'post'
    },
		//合同盖章详情
		getSupplierContractSealInfo: {
			url: '/fortune/supplierContractSeal/getInfo',
			method: 'get'
    },
    //合同盖章审批
    setSupplierContractSealAudit: {
			url: '/fortune/supplierContractSeal/audit',
			method: 'post'
    },
    //合同盖章状态
    getSupplierContractSealStatus: {
			url: '/fortune/supplierContractSeal/getStatus',
			method: 'get'
    },
    //合同盖章编辑
    setSupplierContractSealEdit: {
			url: '/fortune/supplierContractSeal/edit',
			method: 'post'
    },
  }
  
  export default supplierContractSeal