import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import {
  Form,
  Icon,
  Input,
  Radio,
  Switch,
  Button,
  Select,
  Modal,
  message,
  DatePicker,
} from 'antd';
import api from '@/api';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FormItemRender = React.forwardRef((props, ref) => {
  const components = {
    Select: Select,
    RangePicker: DatePicker.RangePicker,
    DatePicker: DatePicker,
    Input: Input,
    Switch: Switch,
    Radio: Radio,
    RadioGroup: Radio.Group,
    TextArea: Input.TextArea,
  };
  const {
    render,
    dataIndex,
    title,
    layout,
    valueType,
    formItemProps,
    fieldProps,
    renderFormItem,
    ...others
  } = props;

  let initFieldProps = {};
  if (['Switch', 'InputNumber'].indexOf(valueType) < 0) {
    initFieldProps['allowClear'] = true;
  }
  // if (valueType === 'Input') {
  //   initFieldProps['onPressEnter'] = () => form.submit();
  // }
  if (valueType === 'custom') {
    if (renderFormItem) {
      return renderFormItem();
    }
    return null;
  }
  let Specific = components[valueType];
  if (!Specific) {
    return null;
  }
  if (valueType === 'Select') {
    return (
      <Specific ref={ref} {...others} {...initFieldProps} {...fieldProps}>
        {fieldProps.options.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Specific>
    );
  }
  return <Specific ref={ref} {...others} {...initFieldProps} {...fieldProps} />;
});

const FiltersForm = (props) => {
  const {
    columns = [],
    className,
    title,
    bordered,
    bodyStyle,
    type,
    isNo,
    form,
    layout = 'horizontal',
    style,
    span,
    labelCol = { xl: 8, xxl: 6 },
    wrapperCol = { xl: 16, xxl: 18 },
    initialValues,
    onSubmit = () => {},
    onReset = () => {},
    ...others
  } = props;

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } =
    form;

  const [showAll, setShowAll] = useState(false); // 展示所有筛选条件
  const [selected, setSelected] = useState(undefined); // 选中筛选方案
  const [programme, setProgramme] = useState({
    name: '',
    content: null,
  }); // 保存筛选方案：存放方案筛选项及名称
  const [programmes, setProgrammes] = useState([]); // 筛选方案列表
  const [flag, setFlag] = useState(false);

  const [fields, transforms, dateFields] = useMemo(() => {
    let filds = [],
      transforms = {},
      dateFields = [];
    filds = columns
      .filter((item) => {
        const { valueType, search, dataIndex } = item;
        if (!valueType) {
          return false;
        }
        if (['RangePicker', 'DatePicker'].includes(valueType)) {
          dateFields.push(dataIndex);
        }
        if ((item.hideInSearch || item.search === false) && type !== 'form') {
          return false;
        }
        if (type === 'form' && item.hideInForm) {
          return false;
        }
        if (search && search.transform) {
          if (Array.isArray(dataIndex)) {
            transforms[dataIndex[0]] = {};
            transforms[dataIndex[0]][dataIndex[1]] = search.transform;
          } else {
            transforms[dataIndex] = search.transform;
          }
        }
        if (
          valueType !== 'index' &&
          valueType !== 'indexBorder' &&
          valueType !== 'option' &&
          (item.key || item.dataIndex)
        ) {
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        if (b.order || a.order) {
          return (b.order || 0) - (a.order || 0);
        }
        return (b.index || 0) - (a.index || 0);
      });
    return [filds, transforms, dateFields];
  }, [columns, type]);

  useEffect(() => {
    if (initialValues) {
      let values = {};
      fields.map(item=>{
        if(initialValues[item.dataIndex]){
          values[item.dataIndex] = initialValues[item.dataIndex]
        }
      })
      // console.log(initialValues);
      // console.log(values);
      setFieldsValue(values);
    }
  }, []);

  // 表单：提交
  const onFormSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      // console.log(values);
      let result = transformData(values);
      onSubmit(result);
    });
  };

  // 表单：重置
  const onFormReset = () => {
    resetFields();
    setSelected(undefined);
    validateFields((err, values) => {
      // console.log(values);
      let result = transformData(values);
      onReset(result);
      if (initialValues) {
        let values = {};
        fields.map(item=>{
          if(initialValues[item.dataIndex]){
            values[item.dataIndex] = initialValues[item.dataIndex]
          }
        })
        setFieldsValue(values);
      }
    });
  };

  // 获取保存的筛选方案列表
  const onDropdownVisibleChange = (open) => {
    setFlag(open);
    if (open) {
      api.getSearchStorage({ key: window.location.pathname }).then((res) => {
        setProgrammes(res.list);
      });
    }
  };

  // 选择筛选方案
  const onSelectProgamme = (item) => {
    setSelected(item);
    resetFields();
    const values = { ...programmes[item].content };
    dateFields.map((item) => {
      if (values[item]) {
        values[item] = [moment(values[item][0]), moment(values[item][1])];
      }
    });
    // console.log(values);
    setFieldsValue(values);
  };

  // 数据处理：非空（null\undefined\''）
  const transformData = (values, transformed = true) => {
    let result = {};
    for (let key in values) {
      if (
        values[key] === undefined ||
        values[key] === '' ||
        values[key] === null ||
        values[key].length <= 0
      ) {
        continue;
      }
      if (transforms.hasOwnProperty(key) && transformed) {
        if (Object.prototype.toString.call(values[key]) === '[object Object]') {
          let subKeys = Object.keys(values[key]);
          if (
            values[key][subKeys[0]] === undefined ||
            values[key][subKeys[0]] === '' ||
            values[key][subKeys[0]] === null
          ) {
            continue;
          }
          result = {
            ...result,
            ...transforms[key][subKeys[0]](values[key][subKeys[0]]),
          };
        } else {
          result = { ...result, ...transforms[key](values[key]) };
        }
      } else {
        result[key] = values[key];
      }
    }
    return result;
  };

  // 删除筛选方案
  const onDeleteProgamme = (id) => {
    api
      .searchStorageDelete({
        id: id,
      })
      .then(() => {
        api.getSearchStorage({ key: window.location.pathname }).then((data) => {
          setProgrammes(data.list);
        });
      });
  };

  // 保存筛选方案条件
  const onSaveProgamme = () => {
    validateFields((err, values) => {
      let result = transformData(values, false);
      if (Object.values(result).length > 0) {
        setProgramme((current) => ({
          ...current,
          content: result,
        }));
      } else {
        message.error('请选择筛选内容');
      }
    });
  };

  // 保存筛选方案点击确定
  const onSaveProgammeOk = () => {
    validateFields((err, vals) => {
      api
        .saveSearchStorage({
          name: programme.name,
          key: window.location.pathname,
          content: programme.content,
        })
        .then(() => {
          onSaveProgammeCancel();
          message.success('添加成功');
        });
    });
  };

  const onSaveProgammeCancel = () => {
    setProgramme((current) => ({
      ...current,
      content: null,
    }));
  };

  // 修改筛选方案名称
  const onProgrammeNameChange = (event) => {
    event.persist();
    setProgramme((current) => ({
      ...current,
      name: event.target.value,
    }));
  };

  if (fields.length <= 0) {
    return null;
  }

  return (
    <div className='search-askforleave-wrap'>
      <h4 className='title'>其它选项：</h4>
      {!showAll ? (
        <span
          className='askforleave-filter-control'
          onClick={() => setShowAll(true)}
        >
          展开
          <Icon type='down' />
        </span>
      ) : (
        <span
          className='askforleave-filter-control'
          onClick={() => setShowAll(false)}
        >
          收起
          <Icon type='up' />
        </span>
      )}
      <Form onSubmit={onFormSubmit} {...formItemLayout}>
        <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
          {fields.map((item) => {
            return (
              <FormItem label={item.title} key={item.dataIndex}>
                {getFieldDecorator(item.dataIndex)(
                  <FormItemRender {...item} />
                )}
              </FormItem>
            );
          })}
        </div>
        <FormItem className='btns' label=' ' colon={false}>
          <div className='staff-filter-btns'>
            {showAll && !isNo && (
              <>
                <h4 className='filter-title'>已保存筛选方案：</h4>
                <div className='filter-select'>
                  <Select
                    style={{ display: 'inline-block', width: '160px' }}
                    placeholder='请选择'
                    onDropdownVisibleChange={onDropdownVisibleChange}
                    onSelect={onSelectProgamme}
                    value={selected}
                  >
                    {programmes.map((item, index) => (
                      <Select.Option value={index} key={index}>
                        <div className='closeStyBtn'>
                          {item.name}
                          {flag && selected !== index ? (
                            <span className='closeSpan'>
                              <Icon
                                style={{ fontSize: '10px', color: '#1890ff' }}
                                type='close'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onDeleteProgamme(item.id);
                                }}
                              />
                            </span>
                          ) : null}
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <Button className='filter-savebtn' onClick={onSaveProgamme}>
                  保存筛选条件
                </Button>
              </>
            )}
            <Button onClick={onFormReset}>重置</Button>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Modal
              title='保存筛选条件'
              visible={programme.content != null}
              onOk={onSaveProgammeOk}
              onCancel={onSaveProgammeCancel}
            >
              <Input
                placeholder='请输入筛选名称'
                onChange={onProgrammeNameChange}
              />
            </Modal>
          </div>
        </FormItem>
      </Form>
    </div>
  );
};

export default Form.create()(FiltersForm);
