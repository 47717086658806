/**
 * 模块名称: 详情页
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import { Button, Icon } from 'antd'

import './style.scss'

const Details = ({ data, onClose, height = 400, url}) => {
  const [showAll, setShowAll] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  const [contentHeight, setContentHeight] = useState(height)
  const contentRef = useRef(null)
  const [aData, setAData] = useState({})

  useEffect(() => {
    if (data.content) {
      let nodes = data.content
      var reg = new RegExp('<table border="0"', 'g')
      nodes = nodes.replace(reg, '<table style="border:1px solid #666" ')
      var reg2 = new RegExp('<td', 'g')
      nodes = nodes.replace(reg2, '<td style="border:1px solid #666" ')
      var reg3 = new RegExp('<th', 'g')
      nodes = nodes.replace(reg3, '<th style="border:1px solid #666" ')
      data.content = nodes
    }
    setAData(data)
    if (contentRef.current.scrollHeight > height && !showAll) {
      setShowBtn(true)
    }
  })

  const onShowAll = () => {
    setShowBtn(false)
    setShowAll(true)
    setContentHeight('auto')
  }

  return (
    <div className="details-wrap">
      <div className="details-header">
        <h2>{aData.title}</h2>
        <div className="details-info"> 
          { aData.number || aData.number === 0 ? <Icon type="eye" className="setIcon" /> : null }
          <span>{aData.number}</span>
          {' '}
          { aData.crateTime ?  <Icon type="clock-circle" className="setIcon" /> : null }
          <span>{aData.crateTime}</span>
          {' '}
          { aData.createBy ?  <Icon type="user" className="setIcon" /> : null }
          <span>{aData.createBy}</span>
        </div>
      </div>
      <div
        className={`details-content ${showAll ? 'show-all' : ''}`}
        style={{ height: contentHeight }}
        ref={contentRef}>
          <div style={{whiteSpace: 'nowrap'}} dangerouslySetInnerHTML={{__html: aData.content}} />
      </div>
      <div className="details-footer">
        {showBtn && <div className="read-more"><Icon onClick={onShowAll} type="down" /></div>}
        <div className="download-btn-wrap">
          <Button onClick={onClose}>关闭</Button>
          {url && <a target="_blank" href={url} className="ant-btn ant-btn-primary" rel="noopener noreferrer">下载附件</a>}
        </div>
      </div>
    </div>
  )
}

export default Details