/*
 * @Author      : OBKoro1(James Wang)
 * @Date        : 2020-09-24 10: 07: 21
 * @Lastditors  : OBKoro1(your name)
 * @LastEditTime: 2020-10-26 16: 53: 24
 * @Description : file content
 */
// 转户模块
const transferUrls = {
	// 转户列表
	getTransferList: {
		url: '/fortune/TransferAccount/list',
		method: 'get'
	},
	//转户详情
	getTransferDetail: {
		url: '/fortune/TransferAccount/detail',
		method: 'get'
	},
	//客户信息
	getClientInfo: {
		url: '/fortune/TransferAccount/ClientInfo',
		method: 'post'
	},
	//未收款发票
	getInvoiceInfo: {
		url: '/fortune/TransferAccount/invoiceInfo',
		method: 'post'
	},
	//帐户余额
	getAccountInfo: {
		url: '/fortune/TransferAccount/AccountBalance',
		method: 'post'
	},
	//信用帐户欠款
	getAcArrearsInfo: {
		url: '/fortune/TransferAccount/CreditArrears',
		method: 'post'
	},
	//转户时搜索员工姓名
	getTransferStaffInfo: {
		url: '/fortune/TransferAccount/PersonnelList',
		method: 'get'
	},
	//转户申请页的员工信息
	TransferApply: {
		url: '/fortune/TransferAccount/SelfInfo',
		method: 'get'
	},
	//获取员工下的所有客户列表
	getStaffForClientList: {
		url: '/fortune/TransferAccount/GetStaffIdForClientInfo',
		method: 'get'
	},
	//提交申请
	submitApply: {
		url: '/fortune/TransferAccount/add',
		method: 'post'
	},
	//提交审批
	operationApproval: {
		url: '/fortune/TransferAccount/audit',
		method: 'post'
	},
}

export default transferUrls
