/**
 * 系统配置管理
 * 
 */
const setSystem = {
  getThirdPartyMailTpl: {
    url: '/ThirdParty/GetMailTpl',
    method: 'get'
  },
  setThirdPartyMailTpl: {
    url: '/ThirdParty/SetMailTpl',
    method: 'post'
  },
  getThirdPartyMailConfig: {
    url: '/ThirdParty/GetMailConfig',
    method: 'get'
  },
  setThirdPartyMailConfig: {
    url: '/ThirdParty/SetMailConfig',
    method: 'post'
  },
  getThirdPartySmsConfig: {
    url: '/ThirdParty/GetSmsConfig',
    method: 'get'
  },
  setThirdPartySms: {
    url: '/ThirdParty/SetSms',
    method: 'post'
  },

  testThirdPartySms: {
    url: '/ThirdParty/TestSms',
    method: 'post'
  },
  testThirdPartyMail: {
    url: '/ThirdParty/TestMail',
    method: 'post'
  },
  getSysNoticeIndex: {
    url: '/SysNotice/index',
    method: 'get'
  },
  delSysNoticeDel: {
    url: '/SysNotice/del',
    method: 'get'
  },

  //获取访问权限
  setSysConfigAccess: {
    url: '/SysConfig/Access',
    method: 'get'
  },
  // 设置访问权限
  setSysConfigPostAccess: {
    url: '/SysConfig/Access',
    method: 'post'
  },
  getSysConfigInfo: {
    url: '/SysConfig/Info',
    method: 'get'
  },
  saveSysConfigInfo: {
    url: '/SysConfig/info',
    method: 'post'
  },
  getSysNoticeDetail: {
    url: '/SysNotice/detail',
    method: 'get'
  },
  setSysNoticeRelease: {
    url: '/SysNotice/release',
    method: 'post'
  },
  //获取短信配置
  getThirdPartySmsTpl: {
    url: '/ThirdParty/GetSmsTpl',
    method: 'get'
  },
  setThirdPartySmsTpl: {
    url: '/ThirdParty/SetSmsTpl',
    method: 'post'
  },
  //回收站
  getSysRecycleList: {
    url: '/SysRecycle/getlist',
    method: 'get'
  },
  //获取类型
  getSysRecycleType: {
    url: '/SysRecycle/GetType',
    method: 'get'
  },
  //恢复数据
  getSysRecyclerecover: {
    url: '/SysRecycle/recover',
    method: 'get'
  },
  // 帮助中心管理
  getSupportquestionList: {
    url: '/supportQuestion/getList',
    method: 'get'
  },
  getQuestionClassList: {
    url: '/SupportQuestion/supportQuestionClassList',
    method: 'get'
  },
  saveQuestionClassUpdate: {
    url: '/SupportQuestion/supportQuestionClassUpdate',
    method: 'post'
  },
  saveQuestionClassDetail: {
    url: '/supportQuestion/classDetail',
    method: 'get'
  },
  delQuestionClassdel: {
    url: '/SupportQuestion/supportQuestionClassdel',
    method: 'post'
  },
  getSupportquestionClass: {
    url: '/supportQuestion/getclass',
    method: 'get'
  },
  saveSupportQuestionUpdate: {
    url: '/supportQuestion/update',
    method: 'post'
  },
  delSupportQuestion: {
    url: '/supportQuestion/del',
    method: 'post'
  },
  getSupportQuestionDetail: {
    url: '/supportQuestion/detail',
    method: 'get'
  },
  downloadQuestionAttachment: {
    url: '/supportQuestion/DownloadAttachment',
    method: 'get'
  },
  supportQuestionClassSort: {
    url: '/SupportQuestion/supportQuestionClassSort',
    method: 'post'
  },
 
  // 反馈列表
  downloadFeedbackAttachment: {
    url: '/SupportFeedback/DownloadAttachment',
    method: 'get'
  },
  getSupportFeedbackList: {
    url: '/SupportFeedback/getList',
    method: 'get'
  },
  getSupportFeedbackDetail: {
    url: '/SupportFeedback/Detail',
    method: 'get'
  },
  getFeedbackReplyDetail: {
    url: '/SupportFeedback/replyDetail',
    method: 'get'
  },
  saveSupportFeedbackFeedback: {
    url: '/SupportFeedback/Feedback',
    method: 'post'
  },

  // 有无帮助
  setSupportQuestionUseful: {
    url: '/supportQuestion/useful',
    method: 'post'
  },
  setSupportFeedbackUseful: {
    url: '/SupportFeedback/useful',
    method: 'post'
  }
}

export default setSystem