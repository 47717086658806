import { combineReducers } from 'redux'
import userInfo from './userInfo'
import posts from './posts'
import loading from './loading'
import reload from './reload'
import config from './config'
import menuList from './menuList'
import companyList from './companyList'

const rootReducer = combineReducers({
  userInfo,
  posts,
  loading,
  reload,
  config,
  menuList,
  companyList
})

export default rootReducer