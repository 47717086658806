/**
 * 退款管理
 * 
 */

const fundMoneyClaim = {
	getAuthorizationList: {
		url: '/fortune/authorization/list',
		method: 'get'
	},
	fundMoneyClaimGetTypes: {
		url: '/fortune/fundMoneyClaim/getTypes',
		method: 'get'
	},
	fundMoneyClaimList: {
		url: '/fortune/fundMoneyClaim/list',
		method: 'get'
	},
	fundMoneyClaimDetail: {
		url: '/fortune/fundMoneyClaim/detail',
		method: 'get'
	},
	fundMoneyClaimDelete: {
		url: '/fortune/fundMoneyClaim/delete',
		method: 'post'
	},
	fundMoneyClaimApply: {
		url: '/fortune/fundMoneyClaim/apply',
		method: 'post'
	},
	fundMoneyClaimReApply: {
		url: '/fortune/fundMoneyClaim/reApply',
		method: 'post'
	},
	fundMoneyClaimAudit: {
		url: '/fortune/fundMoneyClaim/Audit',
		method: 'post'
	},
	fundMoneyClaimRelateAuthorizationToClaim: {
		url: '/fortune/fundMoneyClaim/relateAuthorizationToClaim',
		method: 'post'
	},
	onFortuneAuthorizationAdd: {
		url: '/fortune/authorization/add',
		method: 'post'
	},
	fundMoneyClaimGetInvoiceDetailByDetailId: {
		url: '/fortune/fundMoneyClaim/getInvoiceDetailByDetailId',
		method: 'get'
	},
	fundMoneyClaimExport: {
		url: '/fortune/fundMoneyClaim/Export',
		method: 'post'
	},
	getFundMoneyClaimTplItems: {
		url: '/fortune/fundMoneyClaim/GetExportFields',
		method: 'get'
	},
	getFundMoneyClaimExportTpl: {
		url: '/fortune/fundMoneyClaim/GetExportTpls',
		method: 'get'
	},
	saveFundMoneyClaimExportTpls: {
		url: '/fortune/fundMoneyClaim/SaveExportTpls',
		method: 'post'
	},
	fundMoneyClaimGetAllClaimStatus: {
		url: '/fortune/fundMoneyClaim/getAllClaimStatus',
		method: 'get'
	},
	fundMoneyClaimGetRelationShip: {
		url: '/fortune/fundMoneyClaim/getRelationShip',
		method: 'get'
	},
	fundMoneyClaimGetClaimType: {
		url: '/fortune/fundMoneyClaim/getClaimType',
		method: 'get'
	}

}

export default fundMoneyClaim