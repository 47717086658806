/**
 * 首页
 * 
 */

const index = {
  dragDort: {
    url: '/Home/dragDort',
    method: 'post'
  },
  updatePerDetail: {
    url: '/Login/updatePerDetail',
    method: 'post'
  },
  updatePhone: {
    url: '/Login/updatePhone',
    method: 'post'
  },
  getHomeModules: {
    url: '/home/modules',
    method: 'get'
  },
  saveHomeModules: {
    url: '/home/SaveSettings',
    method: 'post'
  },
  getIndexDocList: {
    url: '/docManage/IndexDocList',
    method: 'get'
  },
  getDocTypeList: {
    url: '/docType/TypeList',
    method: 'get'
  },
  getIndexDocDetails: {
    url: '/docManage/IndexDocRow',
    method: 'get'
  },
  saveDoc: {
    url: '/docManage/DocHandle',
    method: 'post'
  },
  getIndexCorpRule: {
    url: '/corpRule/IndexRuleList',
    method: 'get'
  },
  downloadIndexdoc: {
    url: '/docManage/AffixUpload',
    method: 'get'
  },
  downloadIndexCorpRule: {
    url: '/corpRule/AffixUpload',
    method: 'get'
  },
  downloadNoticeAffix: {
    url: '/sysNotice/DownloadAttachment',
    method: 'get'
  },
  getIndexCorpRuleDetails: {
    url: '/CorpRule/GetIndexRow',
    method: 'get'
  },
  getSysNoticeList: {
    url: '/sysNotice/getSelfList',
    method: 'get'
  },
  getSysNoticeDetails: {
    url: '/SysNotice/detail',
    method: 'get'
  },
  setNoticeReaded: {
    url: '/SysNotice/MarkRead',
    method: 'get'
  },
  addSupportFeedback: {
    url: '/SupportFeedback/add',
    method: 'post'
  }
}

export default index