import { createSlice } from "redux-starter-kit"

const configSlice = createSlice({
  initialState: {
    syslogo: '',
    sysname: '',
    qrcodeimg: ''
  },
  reducers: {
    updateConfig(state, action) {
      return action.payload
    }
  }
})

const { actions, reducer } = configSlice
export const { updateConfig } = actions
export default reducer