import { createSlice } from "redux-starter-kit"

const reloadSlice = createSlice({
  slice: 'reload',
  initialState: false,
  reducers: {
    reloadStart() {
      return true
    },
    reloadEnd() {
      return false
    }
  }
})

const { actions, reducer } = reloadSlice
export const { reloadStart, reloadEnd } = actions
export default reducer