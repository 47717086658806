/**
 * 转岗管理
 * 
 */

const JobTransfer = {
  getStaffTransferList: {
    url: '/staffTransfer/getStaffTransferList',
    method: 'get'
  },
  saveStaffTransferInfo: {
    url: '/staffTransfer/saveStaffTransferInfo',
    method: 'post'
  },
  delstaffTransferUpdateIsDel: {
    url: '/staffTransfer/updateIsDel',
    method: 'post'
  },
  getStaffTransferInfo: {
    url: '/staffTransfer/getStaffTransferInfo',
    method: 'get'
  },
  setStaffTransferPass: {
    url: '/staffTransfer/pass',
    method: 'post'
  },
  setStaffTransferReject: {
    url: '/staffTransfer/reject',
    method: 'post'
  },
  setStaffTransferTurn: {
    url: '/staffTransfer/turnUp',
    method: 'post'
  },
  setStaffTransferReplenish: {
    url: '/staffTransfer/replenish',
    method: 'post'
  }
}

export default JobTransfer