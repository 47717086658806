/**
 * 业务配置管理
 * 
 */
const configMsg = {
  // 获取系统项目、产品、业务列表接口
  getSysProjectList: {
    url: '/fortune/sysProject/getSysProjectList',
    method: 'get'
  },
  // 获取行业列表接口
  getSysProjectIndustryList: {
    url: '/fortune/sysProject/getSysProjectIndustryList',
    method: 'get'
  },
  // 保存行业列表接口
  saveSysProjectIndustry: {
    url: '/fortune/sysProject/saveSysProjectIndustry',
    method: 'post'
  },
  // 删除行业接口
  updateSysProjectIndustryIsDel: {
    url: '/fortune/sysProject/updateSysProjectIndustryIsDel',
    method: 'post'
  },
  // 获取项目各分公司负责人列表
  getSysProjectPrincipalList: {
    url: '/fortune/sysProject/getSysProjectPrincipalList',
    method: 'get'
  },
  // 保存项目负责人接口
  saveSysProjectPrincipal: {
    url: '/fortune/sysProject/saveSysProjectPrincipal',
    method: 'post'
  },
  // 获取业务对应的订单字段
  getBizOrderFields: {
    url: '/fortune/sysProject/getBizOrderFields',
    method: 'get'
  },
  // 设置系统业务涉及到的订单字段
  updateSysBizFields: {
    url: '/fortune/sysProject/updateSysBizFields',
    method: 'post'
  },
  // 保存系统项目、产品、业务接口
  saveSysProject: {
    url: '/fortune/sysProject/saveSysProject',
    method: 'post'
  },
  // 获取系统项目、产品、业务信息接口
  getSysProjectinfo: {
    url: '/fortune/sysProject/getSysProjectinfo',
    method: 'get'
  },
  // 获取系统订单字段列表
  getSysOrderFieldsList: {
    url: '/fortune/sysProject/getSysOrderFieldsList',
    method: 'get'
  },
  // 项目行业导入
  setsysProjectImportExcel: {
    url: '/fortune/SysProject/ImportExcel',
    method: 'get'
  },
  // 供应商行业导入
  setVendorImportExcel: {
    url: '/fortune/vendor/importVendorIndustry',
    method: 'post'
  },
  // 保存系统订单字段
  saveSysOrderFields: {
    url: '/fortune/sysProject/saveSysOrderFields',
    method: 'post'
  },
  // 排序
  updateSysProjectSort: {
    url: '/fortune/sysProject/updateSysProjectSort',
    method: 'post'
  },
  // 删除系统订单
  updateSysOrderFieldsIsDel: {
    url: '/fortune/sysProject/updateSysOrderFieldsIsDel',
    method: 'post'
  },
  // 开关系统项目、产品、业务接口
  updateSysProjectIsOpen: {
    url: '/fortune/sysProject/updateSysProjectIsOpen',
    method: 'post'
  },
  // 获取供应商列表接口
  getSysSupplierList: {
    url: '/fortune/sysSupplier/getSysSupplierList',
    method: 'get'
  },
  // 保存供应商接口
  saveSysSupplier: {
    url: '/fortune/sysSupplier/saveSysSupplier',
    method: 'post'
  },
  // 获取供应商关联项目
  getSysSupplierProjectList: {
    url: '/fortune/sysSupplier/getSysSupplierProjectList',
    method: 'get'
  },
  // 设置供应商关联项目
  updateSysSupplierProjects: {
    url: '/fortune/sysSupplier/updateSysSupplierProjects',
    method: 'post'
  },
  // 设置供应商有效性
  updateSysSupplierIsValidate: {
    url: '/fortune/sysSupplier/updateSysSupplierIsValidate',
    method: 'post'
  },
  // 获取关联项目行业列表
  getSysSupplierProjectIndustryList: {
    url: '/fortune/sysSupplier/getSysSupplierProjectIndustryList',
    method: 'get'
  },
  // 设置供应商关联项目行业
  updateSysSupplierProjectIndustrys: {
    url: '/fortune/sysSupplier/updateSysSupplierProjectIndustrys',
    method: 'post'
  },
  // 获取分公司项目设置列表
  getSysProjectSettingList: {
    url: '/fortune/companyProjectSetting/getSysProjectSettingList',
    method: 'get'
  },
  // 保存分公司项目设置
  saveSysProjectSetting: {
    url: '/fortune/companyProjectSetting/saveSysProjectSetting',
    method: 'post'
  },
  // 根据项目支持情况查询供应商列表
  getSupplierListWithProject: {
    url: '/fortune/companyProjectSetting/getSupplierListWithProject',
    method: 'get'
  },
  // 获取项目设置顶部分公司列表
  getProjectCompanyList: {
    url: '/fortune/companyProjectSetting/getCompanyList',
    method: 'get'
  },
  // 项目导出
  getCompanyProjectSettingExportTpl: {
    url: '/fortune/companyProjectSetting/GetExportTpls',
    method: 'get'
  },
  getCompanyProjectSettingTplItems: {
    url: '/fortune/companyProjectSetting/GetExportFields',
    method: 'get'
  },
  getCompanyProjectSettingSaveExportTpls: {
    url: '/fortune/companyProjectSetting/SaveExportTpls',
    method: 'post'
  },
  exportCompanyProjectSetting: {
    url: '/fortune/companyProjectSetting/Export',
    method: 'post'
  },
  // 折扣列表接口
  getDiscountBusinessList: {
    url: '/fortune/Discount/businessList',
    method: 'get'
  },
  // 公司折扣列表
  getDiscountCompanyList: {
    url: '/fortune/Discount/companyList',
    method: 'get'
  },
  // 获取项目产品
  getsysProjectChilds: {
    url: '/fortune/sysProject/getChilds',
    method: 'get'
  },
  // 获取项目供应商列表
  getProjectSupplier: {
    url: '/fortune/companyProjectSetting/realatedSupplier',
    method: 'get'
  },
  // 公司折扣详情
  getDiscountCompanyDetail: {
    url: '/fortune/Discount/companyDetail',
    method: 'get'
  },
  // 内控员工列表
  getDiscountInternal: {
    url: '/fortune/discount/getInternal',
    method: 'get'
  },
  // 公司折扣删除
  getDiscountCompanyDel: {
    url: '/fortune/Discount/companyDel',
    method: 'get'
  },
  // 业务部门折扣删除
  getDiscountBusniessDel: {
    url: '/fortune/Discount/busniessDel',
    method: 'get'
  },
  // 公司折扣导入
  setDiscountCompanyImport: {
    url: '/fortune/Discount/companyImport',
    method: 'post'
  },
  // 公司折扣导出
  getDiscountTwoExportTpl: {
    url: '/fortune/Discount/GetExportTpls',
    method: 'get'
  },
  exportDiscountTwoExport: {
    url: '/fortune/Discount/Export',
    method: 'post'
  },
  getDiscountTwoTplItems: {
    url: '/fortune/Discount/GetExportFields',
    method: 'get'
  },
  saveDiscountTwoTpl: {
    url: '/fortune/Discount/SaveExportTpls',
    method: 'post'
  },

  // 业务部门折扣导入
  setDiscountBusinessImport: {
    url: '/fortune/Discount/businessImport',
    method: 'post'
  },
  // 业务部门折扣导出
  getDiscountExportTpl: {
    url: '/fortune/Discount/GetExportTpls',
    method: 'get'
  },
  exportDiscountExport: {
    url: '/fortune/Discount/Export',
    method: 'post'
  },
  getDiscountTplItems: {
    url: '/fortune/Discount/GetExportFields',
    method: 'get'
  },
  saveDiscountTpl: {
    url: '/fortune/Discount/SaveExportTpls',
    method: 'post'
  },
  // 获取项目负责人
  getDiscountProjectLeader: {
    url: '/fortune/Discount/GetProjectLeader',
    method: 'get'
  },
  // 业务部门新增/修改
  setFortuneDiscountBusiness: {
    url: '/fortune/Discount/businessHand',
    method: 'post'
  },
  // 修改折扣判断
  getDiscountIsHasApproval: {
    url: '/fortune/Discount/IsHasApproval',
    method: 'get'
  },
  // 确认修改折扣驳回
  getDiscountUpdateTrue: {
    url: '/fortune/Discount/UpdateTrue',
    method: 'get'
  },
  // 公司新增/修改折扣
  setDiscountCompanyHand: {
    url: '/fortune/Discount/companyHand',
    method: 'post'
  },
  // 获取行业分组列表
  getDiscountIndustryGroups: {
    url: '/fortune/Discount/GetIndustryGroups',
    method: 'get'
  },
  // 行业分组列表
  getProIndGroupsList: {
    url: '/fortune/ProjectIndustryGroups/GetList',
    method: 'get'
  },
  // 行业分组列表状态变更
  onProIndGroupsChangeStatus: {
    url: '/fortune/ProjectIndustryGroups/ChangeStatus',
    method: 'post'
  },
  // 行业分组添加、修改
  onProIndGroupsHandle: {
    url: '/fortune/ProjectIndustryGroups/Handle',
    method: 'post'
  },
  // 获取全部行业列表
  onProIndGroupsGetAllIndustry: {
    url: '/fortune/ProjectIndustryGroups/GetAllIndustry',
    method: 'get'
  },
  // 行业分组详情
  getProIndGroupsDetail: {
    url: '/fortune/ProjectIndustryGroups/GetDetail',
    method: 'get'
  },
  // 获取筛选行业
  getProIndGroupsFilterIndustry: {
    url: '/fortune/ProjectIndustryGroups/GetFilterIndustry',
    method: 'get'
  },
  // 获取某个项目关联事业群，部门树形结构接口
  getProjectDepartment: {
    url: '/fortune/sysProject/getProjectDepartment',
    method: 'get'
  },
  // 保存关联部门接口
  onProjectSaveDepartment: {
    url: '/fortune/sysProject/saveDepartment',
    method: 'post'
  }
}

export default configMsg