/**
 * 配置菜单接口里没有的路由
 * 基本数据结构: 
 *  url 和访问路由一致
 *  fullTitle 面包屑，使用 => 分割，前后空格
 *  children 有子路由就加，没有就给空数组，不能省略
 */

const breadcrumbList = [{
  url: '/document',
  fullTitle: '文档列表',
  children: [{
    url: '/document/details',
    fullTitle: '文档列表 => 详情',
    children: []
  }]
}, {
  url: '/notice',
  fullTitle: '系统公告',
  children: [{
    url: '/notice/details',
    fullTitle: '系统公告 => 详情',
    children: []
  }]
}, {
  url: '/regulation',
  fullTitle: '公司制度',
  children: [{
    url: '/regulation/details',
    fullTitle: '公司制度 => 详情',
    children: []
  }]
}, {
  url: '/schedule',
  fullTitle: '待办事项',
  children: []
}, {
  url: '/message',
  fullTitle: '系统消息',
  children: []
}, {
  url: '/hr/employee/details',
  fullTitle: '人力资源管理 => 员工信息管理 => 员工详情',
  children: []
}, {
  url: '/hr/employee/staffAdd',
  fullTitle: '人力资源管理 => 员工信息管理 => 新增员工',
  children: []
}, {
  url: '/hr/employee/staffEdit',
  fullTitle: '人力资源管理 => 员工信息管理 => 编辑员工',
  children: []
}, {
  url: '/hr/premployee/edit',
  fullTitle: '人力资源管理 => 待入职管理 => 待入职员工详情',
  children: []
}, {
  url: '/hr/employee/staffAlteration',
  fullTitle: '人力资源管理 => 员工信息管理 => 员工异动表',
  children: []
}, {
  url: '/office/document/details',
  fullTitle: '日常办公 => 文档管理 => 文档详情',
  children: []
}, {
  url: '/office/costManagement/apply',
  fullTitle: '日常办公 => 费用管理 => 费用申请',
  children: []
}, {
  url: '/office/costManagement/details',
  fullTitle: '日常办公 => 费用管理 => 费用申请详情',
  children: []
}, {
  url: '/office/procedure/officeworkdetail',
  fullTitle: '日常办公 => 日常办公审批 => 审批详情',
  children: []
}, {
  url: '/office/procedure/addffficeworkitem',
  fullTitle: '日常办公 => 日常办公审批 => 发起审批',
  children: []
}, {
  url: '/authManagement/roleManage/addRole',
  fullTitle: '权限管理 => 角色管理 => 添加角色',
  children: []
}, {
  url: '/authManagement/roleManage/editRole',
  fullTitle: '权限管理 => 角色管理 => 编辑角色',
  children: []
}, {
  url: '/setSystem/systemBulletin/detail',
  fullTitle: '系统配置管理 => 系统公告管理 => 公告详情',
  children: []
}, {
  url: '/hr/promotion/detail',
  fullTitle: '人力资源管理 => 晋升管理 => 晋升详情',
  children: []
}, {
  url: '/hr/jobTransfer/detail',
  fullTitle: '人力资源管理 => 转岗管理 => 转岗详情',
  children: []
}, {
  url: '/attendance/askforleave/addaskdetail',
  fullTitle: '考勤管理 => 请假外出 => 请假外出申请详情',
  children: []
}, {
  url: '/attendance/askforleave/addaskdetails',
  fullTitle: '考勤管理 => 请假外出 => 育儿假资格申请单',
  children: []
}, {
  url: '/attendance/appeal/details',
  fullTitle: '考勤管理 => 考勤申诉 => 考勤申诉详情',
  children: []
}, {
  url: '/hr/dimission/detail',
  fullTitle: '人力资源管理 => 离职管理 => 离职详情',
  children: []
}, {
  url: '/hr/recruit/detail',
  fullTitle: '人力资源管理 => 招聘管理 => 招聘详情',
  children: []
}, {
  url: '/journal/mailLog/details',
  fullTitle: '日志管理 => 邮件日志 => 邮件详情页',
  children: []
}, {
  url: '/setSystem/helpCenterMgt/replydetail',
  fullTitle: '帮助中心管理 => 反馈列表 => 回复',
  children: []
}, {
  url: '/setSystem/helpCenterMgt/helpdetail',
  fullTitle: '帮助中心管理 => 帮助中心 => 发布',
  children: []
}, {
  url: '/setSystem/helpCenterMgt/detail',
  fullTitle: '帮助中心管理 => 帮助中心 => 详情',
  children: []
}, {
  url: '/helplist',
  fullTitle: '首页 => 帮助中心列表',
  children: []
}, {
  url: '/helplist/detail',
  fullTitle: '首页 => 帮助中心列表 => 帮助中心详情页',
  children: []
}, {
  url: '/helplist/details',
  fullTitle: '首页 => 帮助中心列表 => 反馈列表详情页',
  children: []
}, {
  url: '/protocol/invoice/detail',
  fullTitle: '合同票据及协议管理 => 发票管理 => 发票详情',
  children: []
}, {
  url: '/protocol/invoice/apply',
  fullTitle: '合同票据及协议管理 => 发票管理 => 申请发票',
  children: []
}, {
  url: '/protocol/invoice/clearDetail',
  fullTitle: '合同票据及协议管理 => 发票管理 => 申请发票清理',
  children: []
}, {
  url: '/protocol/invoice/unInvoiceList',
  fullTitle: '合同票据及协议管理 => 发票管理 => 未收款发票列表',
  children: []
}, {
  url: '/protocol/replenish/details',
  fullTitle: '合同票据及协议管理 => 补充协议管理 => 协议审批',
  children: []
}, {
  url: '/protocol/replenish/examine',
  fullTitle: '合同票据及协议管理 => 补充协议管理 => 查看协议',
  children: []
}, {
  url: '/protocol/replenish/apply',
  fullTitle: '合同票据及协议管理 => 补充协议管理 => 协议申请',
  children: []
}, {
  url: '/protocol/replenish/execute',
  fullTitle: '合同票据及协议管理 => 补充协议管理 => 协议执行、修改',
  children: []
}, {
  url: '/protocol/contract/details',
  fullTitle: '合同票据及协议管理 => 合同管理 => 审批详情',
  children: []
}, {
  url: '/protocol/contract/apply',
  fullTitle: '合同票据及协议管理 => 合同管理 => 合同申请',
  children: []
}, {
  url: '/protocol/contract/templates',
  fullTitle: '合同票据及协议管理 => 合同管理 => 模板管理',
  children: []
}, {
  url: '/protocol/contract/templates/redact',
  fullTitle: '合同票据及协议管理 => 合同管理 => 模板管理 => 模板编辑',
  children: []
}, {
  url: '/protocol/quittance/details',
  fullTitle: '合同票据及协议管理 => 收据管理 => 详情',
  children: []
}, {
  url: '/protocol/quittance/apply',
  fullTitle: '合同票据及协议管理 => 收据管理 => 申请收据',
  children: []
}, {
  url: '/config/projectMgt/setTheField',
  fullTitle: '系统项目管理 => 订单资料字段设置',
  children: []
}, {
  url: '/config/projectMgt/setFieldMag',
  fullTitle: '系统项目管理 => 订单字段管理',
  children: []
}, {
  url: '/fund/authorizationManage/details',
  fullTitle: '首页 => 委托书管理 => 委托书详情',
  children: []
}, {
  url: '/fund/refundManage/details',
  fullTitle: '首页 => 退款管理 => 退款详情',
  children: []
}, {
  url: '/fund/refundManage/edit',
  fullTitle: '首页 => 退款管理 => 退款驳回重提',
  children: []
}, {
  url: '/fund/refundManage/add',
  fullTitle: '首页 => 退款管理 => 退款申请',
  children: []
}, {
  url: '/fund/refundManage/addNot',
  fullTitle: '首页 => 退款管理 => 退款申请',
  children: []
}, {
  url: '/fund/moneyTransmit/add',
  fullTitle: '首页 => 转款管理 => 转款申请页',
  children: []
}, {
  url: '/fund/moneyTransmit/specialAdd',
  fullTitle: '首页 => 转款管理 => 特殊转款申请页',
  children: []
}, {
  url: '/config/discountTable/detail',
  fullTitle: '首页 => 业务配置管理 => 折扣配置表',
  children: []
}, {
  url: '/account',
  fullTitle: '首页 => 客户管理 => 资金账户列表',
  children: []
}, {
  url: '/account/credit',
  fullTitle: '首页 => 客户管理 => 信用账户列表',
  children: []
}, {
  url: '/account/record',
  fullTitle: '首页 => 客户管理 => 信用账户列表 => 履历',
  children: []
},{
  url: '/csr/accountNumber/detiall',
  fullTitle: '首页 => 信用账户统计 => 详情',
  children: []
}, {
  url: '/csr/customer/detail',
  fullTitle: '首页 => 客户管理 => 客户详情',
  children: []
}, {
  url: '/csr/customer/vendorTransferAdd',
  fullTitle: '客户管理 => 客户详情 => 厂商ID转移',
  children: []
}, {
  url: '/csr/customer/vendorBatchAdd',
  fullTitle: '客户管理 => 客户详情 => 厂商ID批量转移',
  children: []
}, {
  url: '/csr/customer/newVendor',
  fullTitle: '客户管理 => 厂商ID转移',
  children: []
}, {
  url: '/account/creditapply',
  fullTitle: '首页 => 客户管理 => 信用额度审批',
  children: []
}, {
  url: '/account/creditapply/details',
  fullTitle: '首页 => 信用额度审批 => 信用额度审批详情',
  children: []
}, {
  url: '/account/creditapply/add',
  fullTitle: '首页 => 信用额度审批 => 申请信用额度',
  children: []
}, {
  url: '/account/creditapply/edit',
  fullTitle: '首页 => 信用额度审批 => 信用额度调整',
  children: []
}, {
  url: '/csr/customer/addCustomer',
  fullTitle: '首页 => 客户管理 => 新增客户',
  children: []
}, {
  url: '/csr/customer/applyTo',
  fullTitle: '首页 => 客户管理 => 客户详情 => 申请认证',
  children: []
}, {
  url: '/csr/customer/editCustomer',
  fullTitle: '首页 => 客户管理 => 编辑客户',
  children: []
}, {
  url: '/csr/customer/customerChange',
  fullTitle: '首页 => 客户管理 => 客户信息变更',
  children: []
}, {
  url: '/csr/turnAudit/apply',
  fullTitle: '首页 => 客户管理 => 转户',
  children: []
}, {
  url: '/csr/nameChangeAudit/detail',
  fullTitle: '首页 => 客户名称变更审批 => 详情',
  children: []
}, {
  url: '/csr/addAudit/detail',
  fullTitle: '客户管理 => 客户新增审批 => 详情',
  children: []
}, {
  url: '/csr/vendorIdAudit/detail',
  fullTitle: '客户管理 => 厂商ID转移审批 => 详情',
  children: []
}, {
  url: '/nameChange/detail',
  fullTitle: '客户名称变更审批 => 详情',
  children: []
}, {
  url: '/csr/customer/headSetup',
  fullTitle: '客户管理 => 分配负责人',
  children: []
}, {
  url: '/fund/moneyClaim/detail',
  fullTitle: '首页 => 资金管理 => 认款详情',
  children: []
}, {
  url: '/fund/moneyBack/detail',
  fullTitle: '首页 => 资金管理 => 到款详情',
  children: []
}, {
  url: '/orderDirect/directDetail',
  fullTitle: '直销订单 => 订单详情',
  children: []
}, {
  url: '/orderDirect/paybackApply',
  fullTitle: '直销订单 => 还款申请',
  children: []
}, {
  url: '/orderDirect/paybackApplys',
  fullTitle: '直销订单 => 批量还款申请',
  children: []
}, {
  url: '/orderDirect/paybackDetail',
  fullTitle: '直销订单 => 还款详情',
  children: []
}, {
  url: '/orderDirect/refundApply',
  fullTitle: '直销订单 => 退单申请',
  children: []
}, {
  url: '/orderDirect/refundDetail',
  fullTitle: '直销订单 => 退单详情',
  children: []
}, {
  url: '/orderDirect/add',
  fullTitle: '直销订单 => 订单申请',
  children: []
}, {
  url: '/orderDirect/edit',
  fullTitle: '直销订单 => 订单编辑',
  children: []
}, {
  url: '/orderDirect/renew',
  fullTitle: '直销订单 => 订单续费',
  children: []
}, {
  url: '/orderDirect/agentDetail',
  fullTitle: '直销订单 => 订单详情',
  children: []
}, {
  url: '/orderAgent/directDetail',
  fullTitle: '代理商订单 => 订单详情',
  children: []
}, {
  url: '/orderAgent/paybackApply',
  fullTitle: '代理商订单 => 还款申请',
  children: []
}, {
  url: '/orderAgent/paybackApplys',
  fullTitle: '代理商订单 => 批量还款申请',
  children: []
}, {
  url: '/orderAgent/paybackDetail',
  fullTitle: '代理商订单 => 还款详情',
  children: []
}, {
  url: '/orderAgent/refundApply',
  fullTitle: '代理商订单 => 退单申请',
  children: []
}, {
  url: '/orderAgent/refundDetail',
  fullTitle: '代理商订单 => 退单详情',
  children: []
}, {
  url: '/orderAgent/add',
  fullTitle: '代理商订单 => 订单申请',
  children: []
}, {
  url: '/orderAgent/edit',
  fullTitle: '代理商订单 => 订单编辑',
  children: []
}, {
  url: '/orderAgent/renew',
  fullTitle: '代理商订单 => 订单续费',
  children: []
}, {
  url: '/orderAgent/agentDetail',
  fullTitle: '代理商订单 => 订单详情',
  children: []
}, {
  url: '/agent/integralManage/details',
  fullTitle: '首页 => 积分管理 => 积分详情',
  children: []
},
// {
//   url: '/agent/integralManage/details/1',
//   fullTitle: '首页 => 积分管理 => 退积分详情',
//   children: []
// }, 
{
  url: '/agent/integralManage/add/0',
  fullTitle: '首页 => 积分管理 => 申请充积分',
  children: []
}, {
  url: '/agent/integralManage/edit/0',
  fullTitle: '首页 => 积分管理 => 充积分驳回重提',
  children: []
}, {
  url: '/agent/integralManage/add/1',
  fullTitle: '首页 => 积分管理 => 申请退积分',
  children: []
}, {
  url: '/agent/integralManage/edit/1',
  fullTitle: '首页 => 积分管理 => 退积分驳回重提',
  children: []
}, {
  url: '/agent/agentNew/agentNewDetail',
  fullTitle: '首页 => 代理商新增管理 => 代理商新增详情',
  children: []
}, {
  url: '/agent/manage/agentNewAdd',
  fullTitle: '首页 => 代理商管理 => 新增代理商',
  children: []
}, {
  url: '/agent/manage/detail',
  fullTitle: '首页 => 代理商管理 => 代理商详情',
  children: []
}, {
  url: '/agent/manage/client',
  fullTitle: '首页 => 代理商管理 => 代理商客户详情',
  children: []
}, {
  url: '/agent/nameManage/details',
  fullTitle: '首页 => 代理商信息变更 => 信息变更详情',
  children: []
}, {
  url: '/agent/nameManage/add',
  fullTitle: '首页 => 代理商信息变更 => 信息变更申请',
  children: []
}, {
  url: '/agent/nameManage/edit',
  fullTitle: '首页 => 代理商信息变更 => 信息变更驳回重提',
  children: []
}, {
  url: '/agent/agentTransfer/detail',
  fullTitle: '首页 => 代理商转移管理 => 转移详情',
  children: []
}, {
  url: '/agent/agentTransfer/add',
  fullTitle: '首页 => 代理商转移管理 => 转移申请',
  children: []
}, {
  url: '/agent/manufacturer/details',
  fullTitle: '首页 => 厂商ID转移管理 => 厂商ID转移详情',
  children: []
}, {
  url: '/agent/manufacturer/edit',
  fullTitle: '首页 => 厂商ID转移管理 => 厂商ID转移驳回重提',
  children: []
}, {
  url: '/agent/manufacturer/add',
  fullTitle: '首页 => 厂商ID转移管理 => 厂商ID转移申请',
  children: []
}, {
  url: '/agent/manufacturer/apply',
  fullTitle: '首页 => 厂商ID转移管理 => 厂商ID转移申请',
  children: []
}, {
  url: '/protocol/invoice/modify',
  fullTitle: '首页 => 发票管理 => 发票冲红',
  children: []
}, {
  url: '/csr/firmList',
  fullTitle: '首页 => 客户管理 => 我的厂商ID列表',
  children: []
}, {
  url: '/csr/turnAudit/detail',
  fullTitle: '首页 => 客户管理 => 转户详情',
  children: []
}, {
  url: '/csr/detail',
  fullTitle: '客户管理 => 信用帐户统计详情',
  children: []
}
  , {
  url: '/performance/adjustment/apply',
  fullTitle: '首页 => 业绩调整 => 业绩申请',
  children: []
}
  , {
  url: '/performance/adjustment/detail',
  fullTitle: '首页 => 业绩调整 => 业绩详情',
  children: []
}, {
  url: '/fund/moneyTransmit/details',
  fullTitle: '首页 => 转款管理 => 转款详情',
  children: []
}, {
  url: '/asset/purchase/details',
  fullTitle: '资产采购管理 => 资产采购管理 => 采购申请详情',
  children: []
}, {
  url: '/asset/purchase/apply',
  fullTitle: '资产采购管理 => 资产采购管理 => 采购申请',
  children: []
}, {
  url: '/supplie/topUp/addTopUp',
  fullTitle: '供应商管理 => 系统充值管理 => 充值申请',
  children: []
}, {
  url: '/performance/importDetail/detailList',
  fullTitle: '业绩管理 => 消耗导入详情 => 消耗',
  children: []
}, {
  url: '/corpAssets/software/detail',
  fullTitle: '公司资产 => 软件账号管理 => 详情',
  children: []
}, {
  url: '/supplie/topUp/detail',
  fullTitle: '供应商管理 => 系统充值管理 => 充值申请详情',
  children: []
}, {
  url: '/corpAssets/mobile/details',
  fullTitle: '公司资产 => 手机号管理 => 详情',
  children: []
}, {
  url: '/corpAssets/hardware/details',
  fullTitle: '公司资产 => 硬件设备管理 => 详情',
  children: []
}, {
  url: '/corpAccount/InternalAccount/add',
  fullTitle: '公司账户管理 => 公司内部往来款管理 => 转款申请',
  children: []
}, {
  url: '/corpAccount/InternalAccount/details',
  fullTitle: '公司账户管理 => 公司内部往来款管理 => 转款申请详情',
  children: []
}, {
  url: '/supplie/supplierContract/apply',
  fullTitle: '供应商管理 => 合同内容确认 => 新合同申请',
  children: []
}, {
  url: '/supplie/supplierContract/details',
  fullTitle: '供应商管理 => 合同内容确认 => 合同申请详情',
  children: []
}, {
  url: '/supplie/supplierContractSeal/apply',
  fullTitle: '供应商管理 => 合同盖章申请 => 盖章申请',
  children: []
}, {
  url: '/supplie/supplierContractSeal/details',
  fullTitle: '供应商管理 => 合同盖章申请 => 盖章申请详情',
  children: []
}, {
  url: '/vr/stock/detail',
  fullTitle: 'VR设备管理 => 库存管理 => 历程',
  children: []
}
]
export default breadcrumbList